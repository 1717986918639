import axios from '@/api/axios'

const getReviewsApp = (data) => {
    return axios
        .post('/admin/reviews-app', data)
        .then(response => response.data)
}

const getReviewsForTrack = (data) => {
    return axios
        .post('/api/reviews?page_size=1000', data)
        .then(response => response.data)
}

// удаление оценок привязанных к треку
const trackReviewsDelete = (data) => {
    return axios
        .delete('/admin/track/track-reviews-delete', {data})
        .then(response => response.data)
}

const reviewsDelete = (data) => {
    return axios
        .delete('/admin/reviews-app/delete', {data})
        .then(response => response.data)
}

export default {
    getReviewsApp,
    getReviewsForTrack,
    trackReviewsDelete,
    reviewsDelete
}