import axios from '@/api/axios'

const getListAdmin = (data) => {
    return axios
        .post('/api/user', data)
        .then(response => response.data)
}

const createAdmin = (data) => {
    return axios
        .post('/api/user/create',  data )
        .then(response => response.data)
}

const updateAdmin = (data) => {
    return axios
        .post('/api/user/update',  data)
        .then(response => response.data)
}

const deleteAdmin = (data) => {
    return axios
        .post('/api/user/delete', data)
        .then(response => response.data)
}


export default {
    getListAdmin,
    createAdmin,
    updateAdmin,
    deleteAdmin
}