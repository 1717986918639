import ranksApi from '@/api/ranks';

const state = {
    listRanks: null,
    rankInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getRanksStart: '[ranks] Get ranks start',
    getRanksSuccess: '[ranks] Get ranks success',
    getRanksFailure: '[ranks] Get ranks failure',

    rankInfoStart: '[rank] Get rank info start',
    rankInfoSuccess: '[rank] Get rank info success',
    rankInfoFailure: '[rank] Get rank info failure',

    rankCreateStart: '[rank] Create rank start',
    rankCreateSuccess: '[rank] Create rank success',
    rankCreateFailure: '[rank] Create rank failure',

    rankUpdateStart: '[rank] Update rank start',
    rankUpdateSuccess: '[rank] Update rank success',
    rankUpdateFailure: '[rank] Update rank failure',

    rankRemoveStart: '[rank] Remove rank start',
    rankRemoveSuccess: '[rank] Remove rank success',
    rankRemoveFailure: '[rank] Remove rank failure'
}

export const ranksActionTypes = {
    getRanks: '[ranks] Get ranks',
    rankInfo: '[rank] Get rank info',
    rankCreate: '[rank] Create rank',
    rankUpdate: '[rank] Update rank',
    rankRemove: '[rank] Remove rank',
}

const mutations = {
    [mutationTypes.getRanksStart](state) {
        state.isLoading = true
        state.listRanks = null
    },
    [mutationTypes.getRanksSuccess](state, payload) {
        state.isLoading = false
        state.listRanks = payload
        state.validationErrors = null
    },
    [mutationTypes.getRanksFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.rankInfoStart](state) {
        state.isLoading = true
        state.rankInfo = null
    },
    [mutationTypes.rankInfoSuccess](state, payload) {
        state.isLoading = false
        state.rankInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.rankInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.rankCreateStart](state) {
        state.isLoading = true
        state.rankInfo = null
    },
    [mutationTypes.rankCreateSuccess](state, payload) {
        state.isLoading = false
        state.rankInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.rankCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.rankUpdateStart](state) {
        state.isLoading = true
        state.rankInfo = null
    },
    [mutationTypes.rankUpdateSuccess](state, payload) {
        state.isLoading = false
        state.rankInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.rankUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.rankRemoveStart](state) {
        state.isLoading = true
        state.rankInfo = null
    },
    [mutationTypes.rankRemoveSuccess](state, payload) {
        state.isLoading = false
        state.rankInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.rankRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [ranksActionTypes.getRanks](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getRanksStart)
            ranksApi
                .getRanks(data)
                .then(response => {
                    context.commit(mutationTypes.getRanksSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getRanksFailure, response)
                })
        })
    },
    [ranksActionTypes.rankInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.rankInfoStart)
            ranksApi
                .getRankInfo(data)
                .then(response => {
                    context.commit(mutationTypes.rankInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.rankInfoFailure, response)
                })
        })
    },
    [ranksActionTypes.rankCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.rankCreateStart)
            ranksApi
                .createRank(data)
                .then(response => {
                    context.commit(mutationTypes.rankCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.rankCreateFailure, response)
                })
        })
    },
    [ranksActionTypes.rankUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.rankUpdateStart)
            ranksApi
                .updateRank(data)
                .then(response => {
                    context.commit(mutationTypes.rankUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.rankUpdateFailure, response)
                })
        })
    },
    [ranksActionTypes.rankRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.rankRemoveStart)
            ranksApi
                .deleteRank(data)
                .then(response => {
                    context.commit(mutationTypes.rankRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.rankRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}
