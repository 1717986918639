import axios from '@/api/axios'

const getLocations = () => {
    return axios
        .get('/admin/locations')
        .then(response => response.data)
}

const locationCreate = (data) => {
    return axios
        .post('/admin/locations/create', data)
        .then(response => response.data)
}

const locationInfo = (data) => {
    return axios
        .post('/admin/locations', data)
        .then(response => response.data)
}

const locationUpdate = (data) => {
    return axios
        .post('/admin/locations/update', { location: data })
        .then(response => response.data)
}

const deleteLocation = (data) => {
    return axios
        .post('/admin/locations/delete', data)
        .then(response => response.data)
}

export default {
    getLocations,
    locationCreate,
    locationUpdate,
    deleteLocation,
    locationInfo
}
