import privacyApi from '@/api/privacy';

const state = {
    listPrivacy: null,
    privacyInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getPrivacyStart: '[privacy] Get privacy start',
    getPrivacySuccess: '[privacy] Get privacy success',
    getPrivacyFailure: '[privacy] Get privacy failure',

    privacyInfoStart: '[privacy] Get privacy info start',
    privacyInfoSuccess: '[privacy] Get privacy info success',
    privacyInfoFailure: '[privacy] Get privacy info failure',

    privacyCreateStart: '[privacy] Create privacy start',
    privacyCreateSuccess: '[privacy] Create privacy success',
    privacCreateFailure: '[privacy] Create privacy failure',

    privacyUpdateStart: '[privacy] Update privacy start',
    privacyUpdateSuccess: '[privacy] Update privacy success',
    privacyUpdateFailure: '[privacy] Update privacy failure',

    privacyRemoveStart: '[privacy] Remove privacy start',
    privacyRemoveSuccess: '[privacy] Remove privacy success',
    privacyRemoveFailure: '[privacy] Remove privacy failure'
}

export const privacyActionTypes = {
    getPrivacy: '[privacy] Get privacy',
    privacyInfo: '[privacy] Get privacy info',
    privacyCreate: '[privacy] Create privacy',
    privacyUpdate: '[privacy] Update privacy',
    privacyRemove: '[privacy] Remove privacy',
}

const mutations = {
    [mutationTypes.getPrivacyStart](state) {
        state.isLoading = true
        state.listPrivacy = null
    },
    [mutationTypes.getPrivacySuccess](state, payload) {
        state.isLoading = false
        state.listPrivacy = payload
        state.validationErrors = null
    },
    [mutationTypes.getPrivacyFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.privacyInfoStart](state) {
        state.isLoading = true
        state.privacyInfo = null
    },
    [mutationTypes.privacyInfoSuccess](state, payload) {
        state.isLoading = false
        state.privacyInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.privacyInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.privacyCreateStart](state) {
        state.isLoading = true
        state.privacyInfo = null
    },
    [mutationTypes.privacyCreateSuccess](state, payload) {
        state.isLoading = false
        state.privacyInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.privacyCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.privacyUpdateStart](state) {
        state.isLoading = true
        state.privacyInfo = null
    },
    [mutationTypes.privacyUpdateSuccess](state, payload) {
        state.isLoading = false
        state.privacyInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.privacyUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.privacyRemoveStart](state) {
        state.isLoading = true
        state.privacyInfo = null
    },
    [mutationTypes.privacyRemoveSuccess](state, payload) {
        state.isLoading = false
        state.privacyInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.privacyRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [privacyActionTypes.getPrivacy](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getPrivacyStart)
            privacyApi
                .getPrivacyPolicy(data)
                .then(response => {
                    context.commit(mutationTypes.getPrivacySuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getPrivacyFailure, response)
                })
        })
    },
    [privacyActionTypes.privacyInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.privacyInfoStart)
            privacyApi
                .getPrivacyPolicyInfo(data)
                .then(response => {
                    context.commit(mutationTypes.privacyInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.privacyInfoFailure, response)
                })
        })
    },
    [privacyActionTypes.privacyCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.privacyCreateStart)
            privacyApi
                .createPrivacyPolicy(data)
                .then(response => {
                    context.commit(mutationTypes.privacyCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.privacyCreateFailure, response)
                })
        })
    },
    [privacyActionTypes.privacyUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.privacyUpdateStart)
            privacyApi
                .updatePrivacyPolicy(data)
                .then(response => {
                    context.commit(mutationTypes.privacyUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.privacyUpdateFailure, response)
                })
        })
    },
    [privacyActionTypes.privacyRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.privacyRemoveStart)
            privacyApi
                .deletePrivacyPolicy(data)
                .then(response => {
                    context.commit(mutationTypes.privacyRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.privacyRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}
