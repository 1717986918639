import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from './plugins/vue-cookies'
import DesignElements from './plugins/import-design-elements.js'
import Lodash from './plugins/lodash'
import DefaultLayout from "./layouts/default"
import LoginLayout from "./layouts/login"
import moment from 'moment';
import 'normalize.css/normalize.css'
import { VueMaskDirective } from 'v-mask'
import DatetimePicker from 'vuetify-datetime-picker'
import { translate } from '@/filters/translate.filter.js'
import {statusModeration} from '@/filters/status-moderation.filter'
import { formatDate } from '@/filters/format-date.filters.js'
import { statusComplexietyFilter } from './filters/status-complexity.js'


Vue.use(DatetimePicker);
Vue.directive('mask', VueMaskDirective);

Vue.filter('translate', translate)
Vue.filter('formatDate', formatDate)
Vue.filter('statusModeration', statusModeration)
Vue.filter('statusComplexietyFilter', statusComplexietyFilter)

Vue.prototype.$moment = moment;
//import {mapActions} from 'vuex'

Vue.config.productionTip = false

Vue.component("default-layout", DefaultLayout)
Vue.component("login-layout", LoginLayout)

let defData = {};
defData.state = {};
defData.state.userInterface = true;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (localStorage.getItem('type')) {
      store.commit('setUserType', localStorage.getItem('type'));
    }

    if (localStorage.getItem('username')) {
      store.commit('setUserName', localStorage.getItem('username'));
    }

    if (localStorage.getItem('accessToken')) {
      store.commit('setUserInterface', defData);
      next()
    } else {
      next({ path: '/' })
    }
  } else if (to.matched.some(record => record.meta.logout)) {
    // Remove bearer token from localStorage
    localStorage.clear();
    store.commit('unSetUserInterface')
    next({
      path: '/'
    })
  } else {
    next()
  }

})

new Vue({
  router,
  store,
  vuetify,
  VueCookies,
  DesignElements,
  Lodash,
  render: h => h(App)
}).$mount('#app')
