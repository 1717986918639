<template>
  <div class="orders-page">
    <Heading level="2" text="Треки "/>
    <Grid :column="1" :gap="10">
      <ContentBlock>
        <div class="content">
          <v-btn color="#F32719" class="btn-create" @click="goToCreate">
              + Новый трек
          </v-btn>
          <div class="filters-wrapper">
            <div class="filters-select"> 
              <v-select 
                v-model="selectedTypeTrack.value"
                item-text="text" 
                item-value="value"
                :items="typesTrack" 
                label="Выберите тип трека"
                @change="makeSearch()"
              />
            </div>
            <div>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event_date"
                        label="Выберите дату"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="event_date"
                      range
                      :first-day-of-week="1"
                      locale="ru-RU"
                    >
                    <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu2 = false, event_date = '', makeSearch"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="makeSearch(), menu2 = false"
                      >
                      Ок
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            <div class="search-wrapper">
              <input 
                type="text" 
                v-model="search" 
                @keyup="makeSearch()" 
                placeholder="Поиск..." 
                class="search-input" 
              />
              <div @click="makeSearch()" class="search">
                <SearchIcon/>
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
      <div v-if="isLoading" class="loader-wrapper">
        <Loader />
      </div>
      <ContentBlock v-else>
        <Table class="track-table">
          <!-- Таблица заголовков -->
          <template v-slot:head>
            <tr>
              <th><input type="checkbox" class="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></th>
              <th>
                <div class="column-title" @click="makeSort('id')">
                    <SortDefaultIcon v-if="sortedState.id === 'default'"/>
                    <SortAscIcon v-if="sortedState.id === 'asc'"/>
                    <SortDescIcon v-if="sortedState.id === 'desc'"/>
                    <span>ID</span> 
                </div>
              </th>
              <th class="name">
                <div class="column-title" @click="makeSort('name')">
                  <SortDefaultIcon v-if="sortedState.name === 'default'"/>
                  <SortAscIcon v-if="sortedState.name === 'asc'"/>
                  <SortDescIcon v-if="sortedState.name === 'desc'"/>
                  <span>Название</span> 
                </div>
              </th>
              <th class="city">
                <div class="column-title" @click="makeSort('city')">
                  <SortDefaultIcon v-if="sortedState.city === 'default'"/>
                  <SortAscIcon v-if="sortedState.city === 'asc'"/>
                  <SortDescIcon v-if="sortedState.city === 'desc'"/>
                  <span>Город</span> 
                </div>
              </th>
              <th class="author">
                <div class="column-title" @click="makeSort('author')">
                  <SortDefaultIcon v-if="sortedState.author === 'default'"/>
                  <SortAscIcon v-if="sortedState.author === 'asc'"/>
                  <SortDescIcon v-if="sortedState.author === 'desc'"/>
                  <span>Автор</span> 
                </div>
              </th>
              <th>
                <div class="column-title" @click="makeSort('track_distance')">
                  <SortDefaultIcon v-if="sortedState.track_distance === 'default'"/>
                  <SortAscIcon v-if="sortedState.track_distance === 'asc'"/>
                  <SortDescIcon v-if="sortedState.track_distance === 'desc'"/>
                  <span>км</span> 
                </div>
              </th>
              <th>
                <div class="column-title" @click="makeSort('average_rating')">
                  <SortDefaultIcon v-if="sortedState.average_rating === 'default'"/>
                  <SortAscIcon v-if="sortedState.average_rating === 'asc'"/>
                  <SortDescIcon v-if="sortedState.average_rating === 'desc'"/>
                  <span>Оценка</span> 
                </div>
              </th>
              <th>Тип</th>
              <th>Дата</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </template>
          <!-- Таблица данных -->
          <template v-slot:body>
            <!-- Изменено: Используем отдельный computed свойство для пагинации -->
            <tr v-for="(order, ind) of pagedTracks" :key="`order-${ind}`">
              <td><input type="checkbox" v-model="order.selected" @change="toggleSelection(order)"></td>
              <td>{{ order.id }}</td>
              <td>{{ order.name }}</td>
              <td>{{ order?.city }}</td>
              <td>{{ order.author}}</td>
              <td>{{ order.track_distance }}</td>
              <td align="center">
                <router-link class="average" :to="`/main/track-reviews/${order.id}`">
                  {{ !order.average_rating ? order.average_rating : order.average_rating.toFixed(1) }}
                </router-link>
              </td>
              <td>{{ order.track_type | translate}}</td>
              <td>{{ order.created_at | formatDate }}</td>
              <td align="right">
                <ClipIcon v-if="order.files" />
              </td>
              <td align="right">
                <router-link :to="`/main/track/update/${order.id}`">
                  <Icon iconName="edit" width="24" height="24" stroke="#a6e71d"/>
                </router-link>
              </td>
             <td align="right">
                <button @click="removeOne(order.id)">
                  <Icon iconName="delete" width="24" height="24" stroke="#a6e71d"/>
                </button>
              </td>
            </tr>
          </template>
        </Table>
        <div class="buttons-group mt-5">
          <v-btn  :disabled="selectedTracks.length === 0" large color="#F35821" class="color-white" @click="remove">
            Удалить
          </v-btn>
        </div>
        <v-pagination
            v-model="filter.page"
            :length="totalPages"
            total-visible="7"
        ></v-pagination>
      </ContentBlock>
    </Grid>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import {trackActionTypes} from '@/store/modules/track';
import {toLowerCaseTrim} from '@/helpers/utils'
import {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon } from '@/components/icons/index'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Track-page',
  components: {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon, Loader},
  data() {
    return {
      selectedAll: false,
      isLoading: false,
      selectedTracks: [],
      totalPageCount: 10,
      filter: {
        count: 10,
        page: 1,
      },
      menu2: false,  
      search: '',
      waiteLoad: false,
      event_date: '',
      listTracks: [],
      listTracksDef: [],
      sortedState: {
        id: 'desc',
        name: 'default',
        city: 'default',
        author: 'default',
        track_distance: 'default',
        average_rating: 'default',
      },
      selectedTypeTrack: {text: 'Все', value: 'all'},
      typesTrack: [
        { text: 'Все', value: 'all' },
        { text: 'Бег', value: 'run' },
        { text: 'Ходьба', value: 'walk' },
        { text: 'Велосипед', value: 'bike' },
      ],
    }
  },
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.track.isSubmitting,
      validationErrors: state => state.track.validationErrors,
      // listTracks: state => state.track.listTracks
    }),
    pagedTracks() {
      const startIdx = (this.filter.page - 1) * this.filter.count;
      const endIdx = startIdx + this.filter.count;
      return this.listTracks.slice(startIdx, endIdx);
    },
    totalPages() {
      return Math.ceil(this.listTracks.length / this.filter.count);
    },
  },
  mounted() {
    this.loadLoans()
  },
  methods: {
    goToCreate() {
      sessionStorage.clear()
      this.$router.push(`/main/track/create`)
    },
    isMatchesDateFilter(track) {
        const dates = this.event_date && this.event_date.sort((a, b) => +new Date(a) - +new Date(b))
        if (dates.length === 2) {
            const crated_date = +new Date(track.created_at.split(' ')[0])
            const startDate = +new Date(dates[0])
            const endDate = +new Date(dates[1])
            return crated_date >= startDate && crated_date <= endDate
        } else if (dates.length === 1) {
            const crated_date = +new Date(track.created_at.split(' ')[0])
            const day = +new Date(dates[0])
            return crated_date === day
        }
        return true
      },
      makeSort(key) {
        const sortedAsNumberKey = ['id', 'track_distance', 'average_rating']
        const sortedAsStringKey = ['name', 'city', 'author']
        Object.keys(this.sortedState).forEach(k => {
          if (key !== k) this.sortedState[k] = 'default'
        })
        if (sortedAsNumberKey.includes(key)) {
          switch(this.sortedState[key]) {
            case 'desc':
              this.listTracks.sort((a, b) => +a[key] - +b[key])
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listTracks.sort((a, b) => b.id - a.id)
              if (['track_distance', 'average_rating'].includes(key)) this.sortedState[key] = 'default'
              this.sortedState.id = 'desc'
              break
            default:
              this.listTracks.sort((a, b) => +b[key] - +a[key])
              this.sortedState[key] = 'desc'
          }
        } else if (sortedAsStringKey.includes(key)) {
          const sortFn = (a, b, type) => {
              const valA = a[key]
              const valB = b[key]
              if(valA === "" || valA === null) return 1
              if(valB === "" || valB === null) return -1
              if(valA === valB) return 0
              if(type === 'desc') return valA > valB ? 1 : -1
              if(type === 'asc') return valA > valB ? -1 : 1
          }
          switch(this.sortedState[key]) {
            case 'desc':
              this.listTracks.sort((a, b) => sortFn(a, b, 'desc'))
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listTracks.sort((a, b) => b.id - a.id)
              this.sortedState.id = 'desc'
              this.sortedState[key] = 'default'
              break
            default:
              this.listTracks.sort((a, b) => sortFn(a, b, 'asc'))
              this.sortedState[key] = 'desc'
          }
        }
      },
      makeSearch() {
        this.listTracks = this.listTracksDef.filter((item) => {
        const keys = [
          'author', 
          'name',
          'city',
        ]
        if (keys.every(key => item[key] != 'null')) {
          const isMatchesTypeTrackFilter = (
            this.selectedTypeTrack.value === item.track_type ||
            this.selectedTypeTrack.value === 'all'
          )
          const isMatchesSearchFilter = keys.some(key => 
            toLowerCaseTrim(item[key])?.includes(toLowerCaseTrim(this.search))
          )
          return isMatchesSearchFilter && isMatchesTypeTrackFilter && this.isMatchesDateFilter(item)
        }
      })
    },
    toggleSelection(track) {
      if (track.selected) {
        this.selectedTracks.push(track);
      } else {
        const index = this.selectedTracks.findIndex(selectedTrack => selectedTrack.id === track.id);
        if (index !== -1) {
          this.selectedTracks.splice(index, 1);
        }
      }
    },
    loadLoans() {
      this.$set(this, "waiteLoad", true);
      this.isLoading = true
      this.$store.dispatch(trackActionTypes.getTracks).then(res => {
        this.listTracks = res.track
        this.listTracksDef = res.track
        this.checkSearchParams()
        this.makeSearch()
      }).finally(() => {
        this.isLoading = false
      })
    },
    toggleSelectionAll() {
        if (this.selectedAll) {
          this.pagedTracks.forEach(track => {
              this.selectedTracks.push(track)
              track.selected = true
          })
        } else {
          this.pagedTracks.forEach(track => {
              this.selectedTracks = []
              track.selected = false
          })
        }
    },
    remove() { 
      if (confirm('Вы уверены, что хотите удалить выбранные треки?')) {
        const selectedIds = this.selectedTracks.map(track => track.id);
        this.$store.dispatch(trackActionTypes.trackRemove, { id: selectedIds }).then(() => {
          this.selectedTracks = []; // Очистить выбранные записи
          this.loadLoans();
        });
      }
    },
    removeOne(id) {
      if (confirm('Вы уверены, что хотите удалить выбранные трек?')) {
        this.$store.dispatch(trackActionTypes.trackRemove, {id}).then(this.loadLoans)
      }
    },
    checkSearchParams() {
      const searchQuery = this.$route.query
      const paramsKeys = ['search', 'date', 'type']
      if (paramsKeys.some(key => searchQuery[key])) {
        const findedType = this.typesTrack.find(typeTrack => typeTrack.value === searchQuery.type)
        this.search = decodeURIComponent(searchQuery.search)
        searchQuery.date && (this.event_date = searchQuery.date.split(','))    
        findedType && (this.selectedTypeTrack = {...this.selectedTypeTrack, ...findedType}) 
      }
    },
    setRouteParams() {
      const search = `search=${this.search}`
      const date = `date=${this.event_date}`
      const type = `type=${this.selectedTypeTrack.value}`
      this.$router.push({path: `?${search}&${date}&${type}`})
    },
  },
  watch: {
    event_date () {
      this.setRouteParams()
    },
    search() {
      this.setRouteParams()
    },
    selectedTypeTrack: {
      handler () {
        this.setRouteParams()
      },
      deep: true,
    },
  }
}
</script>

<style scoped lang="scss">
.track-table .author {
  width: 240px;
}
.track-table .city {
  width: 180px;
}
.track-table .name {
  width: 310px;
}
.btn-create {
  margin-top: 10px;
  align-self: baseline;
  color: #ffffff;
}
  .average {
    text-decoration: none;
  }
  .loader-wrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filters-wrapper {
    display: flex; 
    align-items: center; 
    justify-self: end; 
    gap: 20px;
  }
  .filters-select {
    width: 200px;
  }
  .head-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
  }
  .head {
    width: 400px!important;
  }

  .search-wrapper {
    display:flex; 
    justify-self: end;
  }

  .search-input {
    border-radius: 2px;
    height: 32px; 
    width: 200px; 
    padding: 10px;
    border: 1px solid #dfdfdf;
  }

  .search {
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 32px; 
    width: 37px; 
    background-color: #F32719;
  }

  .column-title {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;

    & svg {
      cursor: pointer;
    }
  }
</style>
