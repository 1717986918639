import noticeApi from '@/api/notice';

const state = {
    listNotices: null,
    createdNotice: null,
    updatedNotice: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getNoticesStart: '[notices] Get notices start',
    getNoticesSuccess: '[notices] Get notices success',
    getNoticesFailure: '[notices] Get notices failure',
    createNoticeStart: '[notice] Create notice start',
    createNoticeSuccess: '[notice] Create notice success',
    createNoticeFailure: '[notice] Create notice failure',
    updateNoticeStart: '[notice] Update notice start',
    updateNoticeSuccess: '[notice] Update notice success',
    updateNoticeFailure: '[notice] Update notice failure',
}

export const noticesActionTypes = {
    getNotices: '[notices] Get notices',
    createNotice: '[notice] Create notice',
    updateNotice: '[notice] Update notice',
}

const mutations = {
    [mutationTypes.getNoticesStart](state) {
        state.isLoading = true
        state.listNotices = null
    },
    [mutationTypes.getNoticesSuccess](state, payload) {
        state.isLoading = false
        state.listNotices = payload
        state.validationErrors = null
    },
    [mutationTypes.getNoticesFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.createNoticeStart](state) {
        state.isLoading = true
        state.createdNotice = null
    },
    [mutationTypes.createNoticeSuccess](state, payload) {
        state.isLoading = false
        state.createdNotice = payload
        state.validationErrors = null
    },
    [mutationTypes.createNoticeFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.updateNoticeStart](state) {
        state.isLoading = true
        state.updatedNotice = null
    },
    [mutationTypes.updateNoticeSuccess](state, payload) {
        state.isLoading = false
        state.updatedNotice = payload
        state.validationErrors = null
    },
    [mutationTypes.updateNoticeFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [noticesActionTypes.getNotices](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getNoticesStart)
            noticeApi
                .getNotices(data)
                .then(response => {
                    context.commit(mutationTypes.getNoticesSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getNoticesFailure,response)
                })
        })
    },
    [noticesActionTypes.createNotice](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.createNoticeStart)
            noticeApi
                .createNotice(data)
                .then(response => {
                    context.commit(mutationTypes.createNoticeSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.createNoticeFailure,response)
                })
        })
    },
    [noticesActionTypes.updateNotice](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.updateNoticeStart)
            noticeApi
                .updateNotice(data)
                .then(response => {
                    context.commit(mutationTypes.updateNoticeSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.updateNoticeFailure,response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}