import axios from '@/api/axios'

const getTags = () => {
    return axios
        .get('/admin/tags')
        .then(response => response.data)
}

const tagInfo = (data) => {
    return axios
        .post('/admin/tags', { tag: data })
        .then(response => response.data)
}

const tagCreate = (data) => {
    return axios
        .post('/admin/tags/create', { tag: data })
        .then(response => response.data)
}

const tagUpdate = (data) => {
    return axios
        .post('/admin/tags/update', { tag: data })
        .then(response => response.data)
}

const deleteTag = (data) => {
    return axios
        .delete('/admin/tags/delete', {data})
        .then(response => response.data)
}

export default {
    getTags,
    tagInfo,
    tagCreate,
    tagUpdate,
    deleteTag,
}
