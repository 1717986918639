<template>
  <div class="header">
    <div class="img">
      <router-link to="/main">
        <v-img :src="require('@/assets/logo.svg')" class="logo" alt="logo" contain></v-img>
      </router-link>
    </div>
    <div class="user-data">
            <span class="user-data__user">
                {{ userName }}
            </span>
      <div class="delimiter"></div>
      <button class="ghost" @click="logOut()">выход</button>
    </div>
  </div>
</template>
<script>
import {globalMethods} from "@/mixins/globalMethods";
import {mapState} from 'vuex'

export default {
  mixins: [globalMethods],
  computed: {
    ...mapState({
      userType: state => state.userType,
      userName: state => state.userName,
      email: state => state.email
    }),
    typeName: (vm) => {
      if (Number(vm.userType) === 1) {
        return 'Суперадмин'
      } else if (Number(vm.userType) === 2) {
        return 'Админ'
      } else if (Number(vm.userType) === 3) {
        return 'Оператор'
      }
    }
  },
  created() {
    console.log('adsd')
    console.log(this.userType)
    console.log(this.userName)
    console.log(this.email)
  },
  methods: {
    logOut() {
      this.$cookies.remove("token");
      this.$router.push({name: 'login'});
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.header {
  background: $color-orange;
  color: $color-white;
  height: 50px;
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
  grid-column: 1 / 3;
  z-index: 3;

  .img {
    width: 50px;
    height: 100%;
    position: relative;

    img {
      max-height: 100%;
    }
  }

  .user-data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .delimiter {
      display: block;
      margin: 0 10px;
      height: 30px;
      width: 1px;
      min-width: 1px;
      max-width: 1px;
      background: $color-white;
    }

    &__user {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: $color-white;
    }
  }
}
</style>