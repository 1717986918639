import axios from '@/api/axios'

const getRanks = () => {
    return axios
        .get('/admin/ranks')
        .then(response => response.data)
}

const getRankInfo = (data) => {
    return axios
        .post('/admin/ranks', { rank: data })
        .then(response => response.data)
}

const createRank = (formData) => {
    return axios({
        method: "post",
        url: "/admin/ranks/create",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
    }).then(response => response.data)
}

const updateRank = (formData) => {
    return axios({
        method: "post",
        url: "/admin/ranks/update",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
    }).then(response => response.data)
}

const deleteRank = (data) => {
    return axios
        .post('/admin/ranks/delete',  data )
        .then(response => response.data)
}

export default {
    getRanks,
    updateRank,
    deleteRank,
    getRankInfo,
    createRank
}
