<template>
  <div class="admin-page">
    <Heading level="2" text="Управление пользователями"/>
    <div class="filters-wrapper">
      <div class="search-wrapper">
          <input 
            type="text" 
            v-model="search" 
            @keyup="makeSearch()" 
            placeholder="Поиск..." 
            class="search-input" 
          />
          <div @click="makeSearch()" class="search">
            <SearchIcon/>
          </div>
        </div>
    </div>
    <Grid :column="1" :gap="10">
      <ContentBlock>
        <Table class="table">
          <template v-slot:head>
            <tr>
              <th>
                Email
              </th>
              <th>
                Имя
              </th>
              <th>
                Фамилия
              </th>
              <th>
                Статус
              </th>
              <th></th>
            </tr>
          </template>
          <template v-slot:body v-if="pagedTracks.length">
            <tr v-for="(admin, ind) of pagedTracks" :key="`admin-${ind}`">
              <td>{{ admin.email }}</td>
              <td>{{ admin.first_name }}</td>
              <td>{{ admin.last_name }}</td>
              <td>{{ statusType[admin.type] }}</td>
              <td align="right">
                <button class="ghost" @click="edit(ind)">
                  <Icon iconName="edit" width="20" height="20" stroke="#a6e71d"/>
                </button>
                <button class="ghost" @click="deleteAdmin(admin.id, ind)">
                  <Icon iconName="delete" width="25" height="20" :viewBox="'0 0 20 20'"
                        :stroke="'unset'" :fill="'#d30909'"/>
                </button>
              </td>
            </tr>
          </template>
        </Table>
        <v-pagination
            v-model="filter.page"
            :length="totalPages"
            total-visible="7"
        ></v-pagination>
      </ContentBlock>
    
    </Grid>
    <Modal ref="confirm">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        {{ confirmData.body }}
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.confirm._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="$refs.confirm._confirm()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
  </div>

</template>

<script>
import {globalMethods} from "@/mixins/globalMethods";
import {adminsActionTypes} from '@/store/modules/admin';
import {mapState} from "vuex";
import {SearchIcon} from '@/components/icons/index'
import {toLowerCaseTrim} from '@/helpers/utils'

export default {
  name: 'Clients',
  mixins: [globalMethods],
  components: {SearchIcon},
  data() {
    return {
      admins: [],
      adminForm: {
        password: '',
        type: '',
        email: '',
        id: null
      },
      selectedTracks: [],
      statusType: {
        '0': 'Не задан',
        '1': 'Админ',
        '2': 'Модератор',
      },
      search: '',
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Удалить',
      },
      filter: {
        count: 10,
        page: 1,
      },
      listAdminsDef: []
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.admin.isSubmitting,
      validationErrors: state => state.admin.validationErrors,
      listAdmins: state => state.admin.listAdmins
    }),
    totalPages() {
      return Math.ceil(this.listAdmins?.data?.length / this.filter.count);
    },
    pagedTracks() {
      const startIdx = (this.filter.page - 1) * this.filter.count;
      const endIdx = startIdx + this.filter.count;
      return this.listAdmins?.data.slice(startIdx, endIdx);
    },
    disabledBtn() {
      if (!this.adminForm?.id) {
        return !this.adminForm.password || !/^[1|2|3]$/.test(this.adminForm.type) && !this.adminForm.email
      } else {
        let index = this.listAdmins.data.findIndex(admin => admin.id === this.adminForm.id)
        if ( this.adminForm.email === this.listAdmins.data[index].email)
          return false
        return true
      }
    }
  },
  mounted() {
    this.loadAdmins()
  },
  methods: {
    makeSearch() {
        this.listAdmins.data = this.listAdminsDef.data.filter((item) => {
          const keys = [
            'last_name',
            'first_name',
            'email',
          ]
          if (keys.every(key => item[key] != 'null')) {
            const isMatchesSearchFilter = keys.some(key => 
              toLowerCaseTrim(item[key])?.includes(toLowerCaseTrim(this.search))
            )
            return isMatchesSearchFilter
          }
      })
    },
    cancel() {
      this.$set(this, "adminForm", {
        password: '',
        type: '',
        email: '',
        id: null
      });
    },
    edit(ind) {
      this.$set(this, "adminForm", {...this.listAdmins.data[ind]});
    },
    deleteAdmin(id, ind) {
      const findedAdmin = this.listAdmins.data.find(admin => admin.id === id)
      this.$set(this.confirmData, 'header', `Удалить ${findedAdmin?.email}`);
      this.$set(this.confirmData, 'body', `Вы уверены что хотите удалить ${findedAdmin?.email}? После удаления вернуть данные будет не возможно!`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.$store.dispatch(adminsActionTypes.deleteAdmin, {
            id
          })
          .then(() => {
            this.loadAdmins()
          })
        }
      })
    },
    onSubmit() {
      this.checkPassword(this.adminForm)
          .then(this.checkStatus)
          .then(data => {
            if (data?.id) {
              this.updateAdmin(data)
            } else {
              this.createdAdmin(data)
            }
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    createdAdmin(data) {
      this.$store.dispatch(adminsActionTypes.createAdmin, {
        password: data.password,
        email: data.email,
        type: data.type,
      })
          .then(() => {
            this.$set(this, "adminForm", {
              password: '',
              type: '',
              email: '',
              id: null
            });
            this.loadAdmins()
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    updateAdmin(data) {
      this.$store.dispatch(adminsActionTypes.updateAdmin, {
        id: data.id,
        password: data.password,
        email: data.email,
        type: data.type,
      })
          .then(() => {
            this.$set(this, "adminForm", {
              password: '',
              type: '',
              email: '',
              id: null
            });
            this.loadAdmins()
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    loadAdmins() {
      this.$store.dispatch(adminsActionTypes.getListAdmin, {type: 0}).then((res) => this.listAdminsDef = {...res})
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";
.filters-wrapper {
    display: flex; 
    flex-direction: row-reverse;
    align-items: center; 
    justify-self: end; 
    gap: 20px;
    margin-bottom: 5px;
}
.search-wrapper {
  display:flex; 
  justify-self: end;
}

.search-input {
  border-radius: 2px;
  height: 32px; 
  width: 200px; 
  padding: 10px;
  border: 1px solid #dfdfdf;
}

.search {
  cursor: pointer; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  height: 32px; 
  width: 37px; 
  background-color: #F32719;
}
.table th:first-child {
  width: 30%;
}

.admin-page {
  form {
    button {
      color: $color-white;

      &.btn-cancel {
        color: $color-black !important;
      }
    }
  }

  .color-white {
    color: $color-white;
  }

}


 .v-input--selection-controls__input input[role=checkbox], .v-input--selection-controls__input input[role=radio], .v-input--selection-controls__input input[role=switch] {
opacity: 1!important;

}
</style>