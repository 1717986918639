<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" :aria-labelledby="iconName"
    role="presentation" :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"
    :fill="fill" enable-background="new 0 0 551.13 551.13" @click="click()">
    <path v-if="iconName == 'filter'" fill-rule="evenodd" clip-rule="evenodd"
      d="M6 8.9a.9.9 0 00.23-.03 2.9 2.9 0 005.54 0 .9.9 0 00.23.03h8a.9.9 0 100-1.8h-8a.9.9 0 00-.23.03 2.9 2.9 0 00-5.54 0A.9.9 0 006 7.1H4a.9.9 0 100 1.8h2zm3-2a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2zM3.1 16a.9.9 0 01.9-.9h8a.9.9 0 01.23.03 2.9 2.9 0 015.54 0 .9.9 0 01.23-.03h2a.9.9 0 110 1.8h-2a.9.9 0 01-.23-.03 2.9 2.9 0 01-5.54 0 .9.9 0 01-.23.03H4a.9.9 0 01-.9-.9zm10.8 0a1.1 1.1 0 112.2 0 1.1 1.1 0 01-2.2 0z"
      :fill="fill"></path>

    <polygon v-else-if="iconName == 'star'"
      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />

    <path v-else-if="iconName == 'edit'" d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
    <template v-else-if="iconName == 'female'">
      <circle cx="256" cy="184" r="152" :fill="fill" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"
        :stroke-width="strockeWidth" />
      <path :fill="fill" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" :stroke-width="strockeWidth"
        d="M256 336v144M314 416H198" />
    </template>
    <template v-else-if="iconName == 'male'">
      <circle cx="216" cy="296" r="152" :fill="fill" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"
        :stroke-width="strockeWidth" />
      <path :fill="fill" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" :stroke-width="strockeWidth"
        d="M448 160V64h-96M324 188L448 64" />
    </template>
    <template v-else-if="iconName == 'tickets'">
      <!-- viewBox="0 0 512.005 512.005" -->
      <g>
        <g>
          <g>
            <path d="M511.513,223.904L452.508,42.326c-1.708-5.251-7.348-8.125-12.602-6.42L6.912,176.612
				c-5.252,1.707-8.126,7.349-6.42,12.602l27.93,85.949c-0.008,0.168-0.025,0.333-0.025,0.503v190.925c0,5.522,4.478,10,10,10
				H493.68c5.522,0,10-4.478,10-10V275.666c0-5.522-4.478-10-10-10h-78.32l89.734-29.16
				C510.345,234.799,513.219,229.157,511.513,223.904z M483.679,285.666v170.925H48.396V285.666h55.392v111.408
				c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10V285.666h228.441H483.679z M350.645,265.666H46.365l-23.762-73.123l52.711-17.129
				l20.162,61.276c1.385,4.208,5.296,6.877,9.497,6.877c1.036,0,2.09-0.162,3.128-0.504c5.246-1.727,8.1-7.378,6.373-12.625
				l-20.139-61.206L436.577,58.017l52.825,162.558L350.645,265.666z" />
            <path d="M421.405,101.849c-1.708-5.251-7.349-8.124-12.602-6.42l-260.728,84.727c-5.252,1.707-8.126,7.349-6.42,12.602
				c1.374,4.226,5.293,6.912,9.509,6.912c1.024,0,2.066-0.159,3.093-0.492l260.728-84.727
				C420.237,112.744,423.112,107.102,421.405,101.849z" />
            <path d="M377.434,166.804l49.352-16.037c5.252-1.707,8.126-7.349,6.42-12.602c-1.708-5.252-7.349-8.125-12.602-6.42
				l-49.352,16.037c-5.252,1.707-8.126,7.349-6.42,12.602c1.374,4.226,5.293,6.912,9.509,6.912
				C375.365,167.296,376.408,167.137,377.434,166.804z" />
            <path d="M419.143,212.741c1.374,4.226,5.293,6.912,9.509,6.912c1.023,0,2.066-0.159,3.093-0.492l15.617-5.075
				c5.252-1.707,8.127-7.349,6.42-12.602c-1.708-5.252-7.348-8.126-12.602-6.42l-15.617,5.075
				C420.311,201.846,417.436,207.488,419.143,212.741z" />
            <path d="M390.685,211.473l-15.618,5.075c-5.252,1.707-8.127,7.349-6.42,12.602c1.373,4.226,5.293,6.912,9.509,6.912
				c1.023,0,2.065-0.159,3.093-0.492l15.618-5.075c5.252-1.707,8.126-7.349,6.42-12.602
				C401.581,212.641,395.944,209.768,390.685,211.473z" />
            <path d="M251.132,186.817l-91.255,29.654c-5.252,1.707-8.127,7.349-6.42,12.602c1.374,4.226,5.293,6.912,9.509,6.912
				c1.023,0,2.066-0.159,3.093-0.492l91.255-29.654c5.252-1.707,8.126-7.349,6.42-12.602
				C262.025,187.985,256.384,185.112,251.132,186.817z" />
            <path d="M113.788,420.364c-5.522,0-10,4.478-10,10v3.916c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-3.916
				C123.788,424.842,119.31,420.364,113.788,420.364z" />
            <path d="M161.554,322.663c0,5.522,4.478,10,10,10h274.148c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H171.554
				C166.032,312.663,161.554,317.14,161.554,322.663z" />
            <path d="M445.703,350.847H393.81c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h51.893c5.522,0,10-4.478,10-10
				C455.703,355.325,451.225,350.847,445.703,350.847z" />
            <path d="M445.703,417.427h-16.422c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h16.422c5.522,0,10-4.478,10-10
				C455.703,421.905,451.225,417.427,445.703,417.427z" />
            <path d="M392.608,417.427h-16.421c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h16.421c5.522,0,10-4.478,10-10
				C402.608,421.905,398.131,417.427,392.608,417.427z" />
            <path d="M267.507,350.847h-95.952c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h95.952c5.522,0,10-4.478,10-10
				C277.507,355.325,273.029,350.847,267.507,350.847z" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </template>
    <template v-else-if="iconName == 'menu'">
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </template>
    <template v-else-if="iconName == 'vk'">
      <g>
        <g>
          <path :fill="fill"
            d="M17.724 4.677c.001 1.027-.008 2.055.007 3.083.004.304-.084.703.278.843.335.13.586-.15.79-.382 1.098-1.259 2.127-2.576 2.983-4.032.08-.136.154-.277.236-.412C24.45-.261 23.044.676 27.596.565c1.008-.025 1.323.489.928 1.48-.51 1.279-1.202 2.458-1.922 3.616-.636 1.023-1.26 2.058-1.964 3.029-.542.746-.433 1.318.16 1.93 1.311 1.354 2.643 2.691 3.751 4.24.448.625 1.173 1.27.756 2.122-.381.781-1.257.452-1.938.507-.183.015-.37-.016-.553.005-1.775.21-3.213-.289-4.352-1.852-.795-1.09-1.83-1.995-2.973-2.712-.414-.26-.883-.587-1.377-.323-.543.29-.368.897-.381 1.391a22.65 22.65 0 0 0 .022 1.926c.05.867-.31 1.304-1.137 1.42-2.378.334-4.592-.154-6.614-1.475-3.197-2.087-5.357-5.148-7.147-8.527C2.022 5.77 1.232 4.17.645 2.477.149 1.05.485.565 1.92.559 2.874.554 3.83.6 4.78.544c.81-.048 1.242.32 1.53 1.074.784 2.055 1.552 4.121 2.773 5.957.51.767 1.066 1.492 1.726 2.123.203.194.437.407.738.257.287-.143.317-.449.317-.747 0-1.798-.002-3.596-.001-5.394 0-.733-.388-1.257-.832-1.767-.266-.306-.572-.66-.414-1.107.179-.507.657-.377 1.042-.379 1.722-.008 3.445.021 5.166-.015.686-.015.935.242.908.952-.039 1.058-.01 2.119-.009 3.179" />
        </g>
      </g>
    </template>
    <template v-else-if="iconName == 'ok'">
      <g>
        <g>
          <path :fill="fill"
            d="M4.467 6.526C4.46 8.209 5.79 9.536 7.463 9.519a2.946 2.946 0 0 0 2.93-2.962A2.954 2.954 0 0 0 7.397 3.56a2.948 2.948 0 0 0-2.93 2.966M1.43 6.515c.006-3.299 2.73-6 6.027-5.975a6.01 6.01 0 0 1 5.973 6.03c-.007 3.304-2.716 5.985-6.029 5.97A5.995 5.995 0 0 1 1.43 6.515m13.177 18.286c-.09.746-.425 1.302-1.09 1.59-.721.313-1.362.122-1.859-.475a152.021 152.021 0 0 1-3.198-3.949c-.393-.506-.635-.56-1.052-.018-1.035 1.343-2.115 2.648-3.195 3.952-.438.528-.995.76-1.676.561-.665-.193-1.065-.645-1.227-1.334-.16-.688.216-1.193.572-1.678a162.068 162.068 0 0 1 3.112-4.148c.373-.476.285-.652-.222-.872A51.131 51.131 0 0 1 1.54 16.9C.506 16.363.179 15.42.624 14.494c.453-.942 1.337-1.238 2.383-.65 3.282 1.845 6.532 1.857 9.818.01 1.052-.59 1.934-.315 2.398.613.473.946.127 1.904-.962 2.468a41.515 41.515 0 0 1-3.151 1.482c-.603.25-.585.457-.207.94 1.046 1.333 2.04 2.712 3.06 4.067.313.416.54.869.644 1.378" />
        </g>
      </g>
    </template>
    <template v-else-if="iconName == 'fb'">
      <g>
        <g>
          <path :fill="fill"
            d="M8.824 20.76c0 1.64-.03 3.282.015 4.921.02.695-.21.885-.842.852-.95-.05-1.908-.058-2.857.001-.713.045-.948-.167-.937-.953.044-3.184-.006-6.369.034-9.553.011-.8-.119-1.145-.987-1.092-.868.051-2.006.433-2.556-.182-.502-.56-.133-1.72-.141-2.615-.019-1.977-.006-1.977 1.857-1.977 1.81 0 1.81 0 1.811-1.929 0-.9-.008-1.795.15-2.69C4.901 2.536 6.837.71 9.748.563 10.76.51 11.778.568 12.791.54c.476-.013.65.16.638.666a75.71 75.71 0 0 0 0 3.473c.012.493-.13.689-.623.677a11.56 11.56 0 0 0-1.838.098c-1.3.177-1.87.802-2.068 2.18-.363 2.527-.363 2.527 1.988 2.527h1.106c1.46 0 1.46 0 1.178 1.447-.177.913-.373 1.823-.522 2.74-.077.472-.28.646-.738.624-.705-.035-1.418.041-2.12-.023-.743-.068-1.02.152-.987.986.066 1.606.02 3.216.02 4.824" />
        </g>
      </g>
    </template>
    <template v-else-if="iconName == 'tw'">
      <g>
        <g>
          <path :fill="fill"
            d="M.44 18.32l.01.01H.43zm5.337-1.34c.335-.212.649-.458 1.114-.79-.93-.197-1.701-.413-2.35-.916-.266-.207-.535-.423-.76-.676-.39-.443-.855-.93-.898-1.506-.08-1.06 1 .009 1.24-.68-.366-.244-.782-.481-1.156-.777-1.124-.892-1.69-2.114-1.9-3.537-.073-.5.059-.633.535-.416.347.159.709.376 1.193.188-.934-1.027-1.573-2.16-1.683-3.579-.063-.805.064-1.56.274-2.316.17-.606.352-.69.795-.143 2.027 2.5 4.59 4.092 7.714 4.688 1.178.225 1.149.229 1.2-1.007.084-2.007.907-3.61 2.694-4.494 1.734-.858 3.403-.535 4.855.736.416.363.784.432 1.24.258.676-.258 1.343-.537 2.088-.836-.23.978-.85 1.625-1.49 2.366.756.157 1.285-.265 1.948-.315-.275.748-.762 1.277-1.299 1.682-.656.496-.759 1.093-.766 1.86-.055 5.568-3.552 10.917-8.522 12.87C8.03 21.136 4.308 20.744.77 18.627c-.122-.073-.215-.196-.32-.298 1.871-.046 3.69-.318 5.326-1.35zM20.422 3.564l.033-.045-.06-.005z" />
        </g>
      </g>
    </template>
    <template v-else-if="iconName == 'mic-off'">
      <line x1="1" y1="1" x2="23" y2="23"></line>
      <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path>
      <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path>
      <line x1="12" y1="19" x2="12" y2="23"></line>
      <line x1="8" y1="23" x2="16" y2="23"></line>
    </template>

    <template v-else-if="iconName == 'mic'">
      <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
      <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
      <line x1="12" y1="19" x2="12" y2="23"></line>
      <line x1="8" y1="23" x2="16" y2="23"></line>
    </template>

    <template v-else-if="iconName == 'copy'">
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
    </template>
    <template v-else-if="iconName == 'novigation-double'">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M1.375 0C0.75368 0 0.25 0.50368 0.25 1.125C0.25 1.74632 0.75368 2.25 1.375 2.25C1.99632 2.25 2.5 1.74632 2.5 1.125C2.5 0.50368 1.99632 0 1.375 0ZM1.375 4.875C0.75368 4.875 0.25 5.37868 0.25 6C0.25 6.62132 0.75368 7.125 1.375 7.125C1.99632 7.125 2.5 6.62132 2.5 6C2.5 5.37868 1.99632 4.875 1.375 4.875ZM5.5 1.125C5.5 0.50368 6.00368 0 6.625 0C7.24632 0 7.75 0.50368 7.75 1.125C7.75 1.74632 7.24632 2.25 6.625 2.25C6.00368 2.25 5.5 1.74632 5.5 1.125ZM6.625 4.875C6.00368 4.875 5.5 5.37868 5.5 6C5.5 6.62132 6.00368 7.125 6.625 7.125C7.24632 7.125 7.75 6.62132 7.75 6C7.75 5.37868 7.24632 4.875 6.625 4.875ZM5.5 10.875C5.5 10.2537 6.00368 9.75 6.625 9.75C7.24632 9.75 7.75 10.2537 7.75 10.875C7.75 11.4963 7.24632 12 6.625 12C6.00368 12 5.5 11.4963 5.5 10.875ZM1.375 9.75C0.75368 9.75 0.25 10.2537 0.25 10.875C0.25 11.4963 0.75368 12 1.375 12C1.99632 12 2.5 11.4963 2.5 10.875C2.5 10.2537 1.99632 9.75 1.375 9.75Z"
        :fill="fill" />
    </template>
    <template v-else-if="iconName == 'toggle-right'">
      <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />
      <circle cx="16" cy="12" r="3" />
    </template>

    <template v-else-if="iconName == 'toggle-left'">
      <rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect>
      <circle cx="8" cy="12" r="3"></circle>
    </template>
    <template v-else-if="iconName == 'left'">
      <polyline points="15 18 9 12 15 6"></polyline>
    </template>
    <template v-else-if="iconName == 'right'">
      <polyline points="9 18 15 12 9 6"></polyline>
    </template>

    <polyline v-else-if="iconName == 'check'" points="20 6 9 17 4 12" />

    <template v-else-if="iconName == 'move'">
      <polyline points="5 9 2 12 5 15"></polyline>
      <polyline points="9 5 12 2 15 5"></polyline>
      <polyline points="15 19 12 22 9 19"></polyline>
      <polyline points="19 9 22 12 19 15"></polyline>
      <line x1="2" y1="12" x2="22" y2="12"></line>
      <line x1="12" y1="2" x2="12" y2="22"></line>
    </template>
    <template v-else-if="iconName == 'clock'">
      <circle cx="12" cy="12" r="10"></circle>
      <polyline points="12 6 12 12 16 14"></polyline>
    </template>
    <template v-else-if="iconName == 'close'">
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </template>
    <path v-else-if="iconName == 'notification'" fillRule="evenodd" clipRule="evenodd"
      d="M7.64329 0.381282C7.47254 0.217187 7.24095 0.125 6.99947 0.125C6.75799 0.125 6.5264 0.217187 6.35565 0.381282C6.18489 0.545376 6.08897 0.767936 6.08897 1L5.99727 2.425C5.99725 2.43726 5.99756 2.44951 5.99818 2.46175L6 2.49675C4.7264 2.70335 3.6618 3.33679 2.82793 4.28471C1.99406 5.23263 1.53651 6.43374 1.53645 7.675C1.53645 10.3814 0.967391 11.448 0.0805613 13.1088L0.0496043 13.1683C0.0143473 13.2344 -0.00262069 13.3082 0.000328103 13.3825C0.00327689 13.4568 0.0260435 13.5292 0.0664441 13.5926C0.106845 13.656 0.163524 13.7084 0.231046 13.7447C0.298568 13.781 0.374668 13.8001 0.452046 13.8H13.5469C13.8856 13.8 14.1041 13.457 13.9493 13.1683L13.9184 13.1088C13.0315 11.448 12.4625 10.3814 12.4625 7.675C12.4622 6.43389 12.0046 5.23298 11.1707 4.28525C10.3369 3.33751 9.27345 2.7042 8 2.49763L8.00182 2.47137L8.00273 2.453L7.90997 1C7.90997 0.767936 7.81404 0.545376 7.64329 0.381282ZM8.19144 16.3728C8.52901 16.0352 8.71865 15.5774 8.71865 15.1H5.11865C5.11865 15.5774 5.30829 16.0352 5.64586 16.3728C5.98343 16.7104 6.44126 16.9 6.91865 16.9C7.39604 16.9 7.85388 16.7104 8.19144 16.3728Z"
      :fill="fill" />
    <template v-else-if="iconName == 'list'">
      <line x1="8" y1="6" x2="21" y2="6"></line>
      <line x1="8" y1="12" x2="21" y2="12"></line>
      <line x1="8" y1="18" x2="21" y2="18"></line>
      <line x1="3" y1="6" x2="3.01" y2="6"></line>
      <line x1="3" y1="12" x2="3.01" y2="12"></line>
      <line x1="3" y1="18" x2="3.01" y2="18"></line>
    </template>
    <template v-else-if="iconName == 'minimize'">
      <polyline points="4 14 10 14 10 20"></polyline>
      <polyline points="20 10 14 10 14 4"></polyline>
      <line x1="14" y1="10" x2="21" y2="3"></line>
      <line x1="3" y1="21" x2="10" y2="14"></line>
    </template>
    <template v-else-if="iconName == 'upload'">
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="17 8 12 3 7 8" />
      <line x1="12" y1="3" x2="12" y2="15" />
    </template>
    <template v-else-if="iconName == 'chevrons-up'">
      <polyline points="17 11 12 6 7 11"></polyline>
      <polyline points="17 18 12 13 7 18"></polyline>
    </template>
    <polyline v-else-if="iconName == 'chevron-down'" points="6 9 12 15 18 9"></polyline>
    <path v-else-if="iconName == 'sort'" fillRule="evenodd" clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1ZM3 5C3 4.44772 3.44772 4 4 4H12C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6H4C3.44772 6 3 5.55228 3 5ZM6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10H10C10.5523 10 11 9.55229 11 9C11 8.44771 10.5523 8 10 8H6Z"
      :fill="fill" />
    <polyline points="18 15 12 9 6 15" v-else-if="iconName == 'chevron-up'"></polyline>
    <path v-else-if="iconName == 'dash'" fillRule="evenodd" clipRule="evenodd"
      d="M0.5 0H9.5C9.77614 0 10 0.223858 10 0.5V1.5C10 1.77614 9.77614 2 9.5 2H0.5C0.223858 2 0 1.77614 0 1.5V0.5C0 0.223858 0.223858 0 0.5 0Z"
      :fill="fill" />
    <path v-else-if="iconName == 'phone'"
      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    <template v-else-if="iconName == 'search'">
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </template>
    <template v-else-if="iconName == 'circle'">
      <circle cx="12" cy="12" r="10"></circle>
    </template>
    <template v-else-if="iconName == 'edit'">
      <path d="M12 20h9"></path>
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
    </template>
    <template v-else-if="iconName == 'share'">
      <circle cx="18" cy="5" r="3"></circle>
      <circle cx="6" cy="12" r="3"></circle>
      <circle cx="18" cy="19" r="3"></circle>
      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
    </template>
    <template v-else-if="iconName == 'key'">
      <path
        d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4">
      </path>
    </template>
    <template v-else-if="iconName == 'eye'">
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
      <circle cx="12" cy="12" r="3"></circle>
    </template>
    <template v-else-if="iconName == 'slash'">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
    </template>
    <template v-else-if="iconName == 'x'">
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </template>
    <template v-else-if="iconName == 'more-vertical'">
      <circle cx="12" cy="12" r="1"></circle>
      <circle cx="12" cy="5" r="1"></circle>
      <circle cx="12" cy="19" r="1"></circle>
    </template>
    <template v-else-if="iconName == 'info'">
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="16" x2="12" y2="12" />
      <line x1="12" y1="8" x2="12.01" y2="8" />
    </template>
    <template v-else-if="iconName == 'voicemail'">
      <circle cx="5.5" cy="11.5" r="4.5" />
      <circle cx="18.5" cy="11.5" r="4.5" />
      <line x1="5.5" y1="16" x2="18.5" y2="16" />
    </template>
    <template v-else-if="iconName == 'favorite'">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V15.191C12 15.5627 11.6088 15.8044 11.2764 15.6382L6.22361 13.1118C6.08284 13.0414 5.91716 13.0414 5.77639 13.1118L0.723607 15.6382C0.391156 15.8044 0 15.5627 0 15.191V1Z"
        :fill="fill" />
    </template>
    <template v-else-if="iconName == 'history'">
      <path
        d="m275.531 172.228-.05 120.493c0 4.575 1.816 8.948 5.046 12.177l86.198 86.181 24.354-24.354-81.153-81.136.05-113.361z" />
      <path
        d="m310.011 34.445c-121.23 0-221.563 90.033-238.367 206.674h-71.644l86.114 86.114 86.114-86.114h-65.78c16.477-97.589 101.355-172.228 203.563-172.228 113.966 0 206.674 92.707 206.674 206.674s-92.707 206.674-206.674 206.674c-64.064 0-123.469-28.996-162.978-79.555l-27.146 21.192c46.084 58.968 115.379 92.808 190.124 92.808 132.955 0 241.119-108.181 241.119-241.119s-108.164-241.119-241.119-241.12z" />
    </template>
    <template v-else-if="iconName == 'fire'">
      <path
        d="M16.543 8.02773C16.52 9.53073 16.02 11.5657 13.676 12.3547C14.41 10.6087 14.522 8.93773 14.002 7.37573C13.307 5.27873 10.988 3.64073 9.445 2.74873C8.918 2.44273 8.242 2.82273 8.252 3.43173C8.272 4.54373 7.934 6.16873 6.293 7.80973C4.107 9.99373 3 12.2507 3 14.5167C3 17.3617 5 20.9997 9 20.9997C4.959 16.9587 8 13.5167 8 13.5167C8.846 19.4307 12.988 20.9997 15 20.9997C16.711 20.9997 20 19.7497 20 14.5517C20 11.4187 18.668 9.04073 17.615 7.65273C17.268 7.19473 16.551 7.45473 16.543 8.02773Z"
        :fill="fill" />
    </template>
    <template v-else-if="iconName == 'send'">
      <line x1="22" y1="2" x2="11" y2="13" />
      <polygon points="22 2 15 22 11 13 2 9 22 2" />
    </template>
    <template v-else-if="iconName == 'delete'">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.33325 4.16667C8.33325 3.93655 8.5198 3.75 8.74992 3.75H11.2499C11.48 3.75 11.6666 3.93655 11.6666 4.16667V5H8.33325V4.16667ZM7.08325 5V4.16667C7.08325 3.24619 7.82944 2.5 8.74992 2.5H11.2499C12.1704 2.5 12.9166 3.24619 12.9166 4.16667V5H15.6666C16.2189 5 16.6666 5.44772 16.6666 6V6.5C16.6666 7.02848 16.2566 7.46121 15.7374 7.49753L14.6854 16.6146C14.6272 17.1192 14.1999 17.5 13.692 17.5H6.30784C5.7999 17.5 5.37265 17.1192 5.31443 16.6146L4.26245 7.49753C3.74321 7.46121 3.33325 7.02848 3.33325 6.5V6C3.33325 5.44772 3.78097 5 4.33325 5H7.08325ZM5.50199 7.5H14.4978L13.6931 15.3519C13.6409 15.8621 13.2112 16.25 12.6984 16.25H7.30148C6.78867 16.25 6.35897 15.8621 6.30669 15.3519L5.50199 7.5ZM8.54159 9.16667C8.19641 9.16667 7.91659 9.44649 7.91659 9.79167V13.5417C7.91659 13.8868 8.19641 14.1667 8.54159 14.1667C8.88676 14.1667 9.16659 13.8868 9.16659 13.5417V9.79167C9.16659 9.44649 8.88676 9.16667 8.54159 9.16667ZM10.8333 9.79167C10.8333 9.44649 11.1131 9.16667 11.4583 9.16667C11.8034 9.16667 12.0833 9.44649 12.0833 9.79167V13.5417C12.0833 13.8868 11.8034 14.1667 11.4583 14.1667C11.1131 14.1667 10.8333 13.8868 10.8333 13.5417V9.79167Z"
        :fill="fill" />
    </template>
    <line v-else-if="iconName == 'minus'" x1="5" y1="12" x2="19" y2="12" />
    <template v-else-if="iconName == 'heart'">
      <path
        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
      </path>
    </template>
    <template v-else-if="iconName == 'open-link'">
      <path
        d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72"
        fill="none" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" :stroke-width="strockeWidth" />
    </template>
    <template v-else-if="iconName == 'users-group'">
      <path clip-rule="evenodd"
        d="M10 7.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM7.25 6.5a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zm-.5 7.25c0-.42.23-.83.8-1.17A4.81 4.81 0 0110 12c1.03 0 1.88.23 2.45.58.57.34.8.75.8 1.17 0 .3-.1.44-.22.54-.14.11-.4.21-.78.21h-4.5c-.39 0-.64-.1-.78-.21-.12-.1-.22-.25-.22-.54zM10 10.5c-1.22 0-2.37.27-3.23.8-.88.53-1.52 1.37-1.52 2.45 0 .7.28 1.3.78 1.71.48.39 1.1.54 1.72.54h4.5c.61 0 1.24-.15 1.72-.54.5-.4.78-1 .78-1.71 0-1.08-.64-1.92-1.52-2.45-.86-.53-2-.8-3.23-.8zm4-5.59c.06-.4.44-.7.85-.64a2.5 2.5 0 01-.35 4.98.75.75 0 010-1.5 1 1 0 00.14-1.99.75.75 0 01-.63-.85zM15.76 10a.75.75 0 000 1.5c1.16 0 1.75.67 1.75 1.25 0 .22-.07.41-.19.55-.1.12-.24.2-.46.2a.75.75 0 000 1.5c1.43 0 2.15-1.21 2.15-2.25 0-1.71-1.6-2.75-3.25-2.75zM5 10.75a.75.75 0 00-.75-.75C2.61 10 1 11.04 1 12.75 1 13.79 1.72 15 3.15 15a.75.75 0 000-1.5.57.57 0 01-.47-.2.86.86 0 01-.18-.55c0-.58.6-1.25 1.75-1.25.41 0 .75-.34.75-.75zm.14-6.47a.75.75 0 01.22 1.48 1 1 0 00.14 1.99.75.75 0 110 1.5 2.5 2.5 0 01-.36-4.97z"
        :fill="fill" fill-rule="evenodd"></path>

    </template>
    <template v-else-if="iconName == 'repeate'">
      <polyline points="17 1 21 5 17 9"></polyline>
      <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
      <polyline points="7 23 3 19 7 15"></polyline>
      <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
    </template>
    <template v-else-if="iconName == 'save'">
      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
      <polyline points="17 21 17 13 7 13 7 21"></polyline>
      <polyline points="7 3 7 8 15 8"></polyline>
    </template>
    <template v-else-if="iconName == 'exit'">
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1="21" y1="12" x2="9" y2="12" />
    </template>
    <template v-else-if="iconName == 'stat'">
      <line x1="18" y1="20" x2="18" y2="10"></line>
      <line x1="12" y1="20" x2="12" y2="4"></line>
      <line x1="6" y1="20" x2="6" y2="14"></line>
    </template>
    <template v-else-if="iconName == 'users'">
      <g fill="currentColor">
        <g clip-rule="evenodd" fill-rule="evenodd">
          <path
            d="M6.25 3.5a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM2.69 11.57c.96-.55 2.22-.82 3.56-.82s2.6.27 3.56.82c.98.56 1.69 1.47 1.69 2.68 0 .7-.28 1.3-.78 1.71-.48.39-1.1.54-1.72.54H3.5c-.61 0-1.24-.15-1.72-.54-.5-.4-.78-1-.78-1.71 0-1.21.71-2.12 1.69-2.68zm.75 1.3c-.65.37-.94.84-.94 1.38 0 .3.1.44.22.54.14.11.4.21.78.21H9c.39 0 .64-.1.78-.21.12-.1.22-.25.22-.54 0-.54-.29-1-.94-1.38-.66-.39-1.65-.62-2.81-.62s-2.15.23-2.81.62zM13.75 3.5a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z">
          </path>
        </g>
        <path
          d="M13.75 12.25c-.23 0-.45.01-.68.03a.75.75 0 11-.14-1.49c.27-.03.54-.04.82-.04 1.34 0 2.6.27 3.56.82.98.56 1.69 1.47 1.69 2.68 0 .7-.28 1.3-.78 1.71-.48.39-1.1.54-1.72.54h-3a.75.75 0 010-1.5h3c.39 0 .64-.1.78-.21.12-.1.22-.25.22-.54 0-.54-.29-1-.94-1.38a5.77 5.77 0 00-2.81-.62z">
        </path>
      </g>
    </template>
    <template v-else-if="iconName == 'plus'">
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </template>
    <template v-else-if="iconName == 'back'">
      <path
        d="M0.646446 3.64645C0.451187 3.84171 0.451187 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53554 7.53553C4.7308 7.34027 4.7308 7.02369 4.53554 6.82843L1.70711 4L4.53554 1.17157C4.7308 0.976311 4.7308 0.659728 4.53554 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM81 3.5L1 3.5V4.5L81 4.5V3.5Z"
        :fill="fill" />
    </template>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    stroke: {
      type: String,
      default: "currentColor",
    },
    viewBox: {
      type: String,
      default: "0 0 24 24",
    },
    fill: {
      type: String,
      default: "none",
    },
    strockeWidth: {
      type: [Number, String],
      default: 2,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>