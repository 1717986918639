import axios from 'axios'

const Helpers = {
    nameCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        value = value.trim()
        return /^[а-яёa-z\.\(\)_0-9]+([- \`\'\(\)_0-9]{1}[а-яёa-z\.\(\)_0-9]+)*\.?$/i.test(value) ? true : false
    },
    statusCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^[1|2|3]$/.test(value) ? true : false
    },
    phoneCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^\+7\s\(9\d{2}\)\s\d{3}-\d{2}-\d{2}$/i.test(value) ? true : false;
    }
}

export const globalMethods = {
    methods: {
        checkPassword(data) {
            return new Promise((resolve, reject) => {
                if (!data?.password) {
                    return reject('Отсутсвует пароль')
                } else if (!Helpers.nameCheck(data.password)) {
                    return reject('Некорректны пароль')
                } else {
                    return resolve(data)
                }
            })
        },
        checkStatus(data) {
            return new Promise((resolve, reject) => {
                if (!data?.type) {
                    return reject('Отсутсвует статус')
                } else if (!Helpers.statusCheck(data.type)) {
                    return reject('Некорректный статус')
                } else {
                    return resolve(data)
                }
            })
        },
        checkPhone(data) {
            return new Promise((resolve, reject) => {
                if (!data?.phone) {
                    return reject('Отсутсвует телефон')
                } else if (!Helpers.phoneCheck(data.phone)) {
                    return reject('Некорректный телефон')
                } else {
                    return resolve(data)
                }
            })
        },
        valueModel(value, obj, field) {
            this.$set(obj, field, value)
        },
        checkAccess(adminType, forOpen) {
            if (Array.isArray(forOpen)) {
                if (forOpen.includes(Number(adminType))) {
                    return true
                } else {
                    return false
                }
            } else {
                if (Number(adminType) === forOpen) {
                    return true
                } else {
                    return false
                }
            }
        },
        sendRequest(method, data, url, currentHeaders = {}, preloader, plug) {
            const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
            axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3301' : 'http://localhost:3301'

            if (!currentHeaders || !currentHeaders?.['Content-Type'] || !currentHeaders?.['content-type']) {
                axios.defaults.headers.common['Content-Type'] = 'application/json';
            }

            let headers = {
                withCredentials: true,
                ...currentHeaders,
            }
            const token = this.$cookies.get('token')

            if (token) {
                headers['x-access-token'] = token
            }

            if (preloader) {
                this.$store.commit('showPreloader', true)
            }
            const errorMessage = (err) => {
                if (err?.response?.data) {
                    if (err?.response?.headers['content-type'] && err.response.headers['content-type'].indexOf('text/html') > -1) {
                        return err.message
                    } else {
                        if (err?.response?.data?.message) {
                            return err.response.data.message;
                        } else {
                            return err.response.data;
                        }
                    }
                } else {
                    return err.message;
                }
            }
            return new Promise((resolve, reject) => {
                if (plug) {
                    setTimeout(() => {
                        this.$store.commit('showPreloader', false)
                        resolve([])
                    }, 1000)
                } else {
                    axios.request({
                        url,
                        method,
                        [dataOrParams]: data,
                        headers: headers
                    })
                        .then(({ data }) => {
                            resolve(data)
                        })
                        .catch((err) => {
                            reject(errorMessage(err))
                        })
                        .finally(() => {
                            this.$store.commit('showPreloader', false)
                        });
                }
            })
        }
    }
}