<template>
    <div class="orders-page">
      <Heading level="2" text="Отзывы трека" :backButton="true"/>
      <div class="head-wrap">
        <span>Количество отзывов: {{ listReviewsDef?.length }}</span>
      </div>
      <Grid :column="1" :gap="10">
        <h3 class="moderation-info">Информация по треку</h3>
        <ContentBlock v-if="trackInfo">
          <V-row>
              <v-col cols="3">
                  <div>
                    <i>ID:</i>
                    <div> 
                      <v-text-field readonly :value="trackInfo.id"></v-text-field>
                    </div>
                  </div>
              </v-col>
              <v-col cols="3">
                  <div>
                    <i>Автор трека:</i>
                    <div> 
                      <v-text-field readonly :value="trackInfo.author"></v-text-field>
                    </div>
                  </div>
              </v-col>
              <v-col cols="3">
                  <div>
                    <i>Название трека:</i>
                    <div> 
                      <v-text-field readonly :value="trackInfo.name"></v-text-field>
                    </div>
                  </div>
              </v-col>
              <v-col cols="3">
                  <div>
                    <i>Средняя оценка:</i>
                    <div> <v-text-field readonly :value="trackInfo.average_rating.toFixed(1)" ></v-text-field></div>
                  </div>
              </v-col>
            </V-row>
        </ContentBlock>
        <div class="filters-wrapper">
          <h3 class="moderation-info">Отзывы трека</h3>
          <div class="filters">
            <div class="filters-select"> 
            <v-select 
              v-model="selectedStatusModeration.value"
              item-text="text" 
              item-value="value"
              :items="statusesModeration" 
              label="Выберите статус модерации"
              @change="makeSearch()"
            />
            </div>
            <div>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event_date"
                        label="Выберите дату"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="event_date"
                      range
                      :first-day-of-week="1"
                      locale="ru-RU"
                    >
                    <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu2 = false, event_date = '', makeSearch()"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="makeSearch(), menu2 = false"
                      >
                      Ок
                    </v-btn>
                  </v-date-picker>
                </v-menu>
            </div>
            <div class="search-wrapper">
              <input 
                type="text" 
                v-model="search" 
                @keyup="makeSearch()" 
                placeholder="Поиск..." 
                class="search-input" 
              />
              <div @click="makeSearch()" class="search">
                <SearchIcon/>
              </div>
            </div>
          </div>
        </div>
        <ContentBlock v-if="listReviews.length">
          <Table class="table-reviews">
            <!-- Таблица заголовков -->
            <template v-slot:head>
              <tr>
                <th><input type="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></th>
                <th>
                  <div class="column-title" @click="makeSort('id')">
                    <SortDefaultIcon v-if="sortedState.id === 'default'"/>
                    <SortAscIcon v-if="sortedState.id === 'asc'"/>
                    <SortDescIcon v-if="sortedState.id === 'desc'"/>
                    <strong>ID</strong> 
                  </div>
                </th>
                <th>
                  <div class="column-title" @click="makeSort('fio')">
                    <SortDefaultIcon v-if="sortedState.fio === 'default'"/>
                    <SortAscIcon v-if="sortedState.fio === 'asc'"/>
                    <SortDescIcon v-if="sortedState.fio === 'desc'"/>
                    <span>Автор</span> 
                  </div>
                </th>
                <th>
                  <div class="column-title" @click="makeSort('count')">
                    <SortDefaultIcon v-if="sortedState.count === 'default'"/>
                    <SortAscIcon v-if="sortedState.count === 'asc'"/>
                    <SortDescIcon v-if="sortedState.count === 'desc'"/>
                    <span>Оценка</span> 
                  </div>
                </th>
                <th>Статус</th>
                <th class="w-30">
                  Текст
                </th>
                <th>Дата</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </template>
            <!-- Таблица данных -->
            <template v-slot:body>
              <!-- Изменено: Используем отдельный computed свойство для пагинации -->
              <tr v-for="(order, ind) of pagedTracks" :key="`order-${ind}`">
                <td><input type="checkbox" v-model="order.selected" @change="toggleSelection(order)"></td>
                <td>{{ order.id }}</td>
                <td>{{ order?.fio }}</td>
                <td>{{ order.count}}</td>
                <td>{{ order.moderation_status | statusModeration}}</td>
                <td>
                  <div class="read-more">
                    <div class="text" :class="showReviews.find(rev => order.id === rev.id)?.isShow ? 'show' : 'hide'">
                      {{ order.text }}
                    </div>
                    <v-btn 
                      class="button"
                      small
                      outlined
                      color="error"
                      @click="showTextHandler(order.id)"
                    >
                      {{ !showReviews.find(rev => order.id === rev.id)?.isShow ? 'Развернуть' : 'Свернуть' }}
                    </v-btn>
                  </div>
                </td>
                <td>{{ order.created | formatDate }}</td>
                <td></td>
                <td align="right">
                  <ClipIcon v-if="order.files" />
                </td>
                <td align="right">
                <button>
                  <Icon iconName="delete" @click="removeOne(order.id)" width="24" height="24" stroke="#a6e71d"/>
                  </button>
                </td>
              </tr>
            </template>
          </Table>  
          <div class="buttons-group mt-5">
            <v-btn  :disabled="selectedTracks.length === 0" large color="#F35821" class="color-white" @click="remove">
              Удалить
            </v-btn>
          </div>
          <v-pagination
              v-model="filter.page"
              :length="totalPages"
              total-visible="7"
          ></v-pagination>
        </ContentBlock>
        <span v-else >Отзывов нет</span>
      </Grid>
    </div>
  </template>
  
  <script>
  import {mapState} from "vuex";
  import {globalMethods} from "@/mixins/globalMethods";
  import {trackActionTypes} from '@/store/modules/track';
  import {reviewsAppActionTypes} from '@/store/modules/reviews-app';
  import {toLowerCaseTrim} from '@/helpers/utils'
  import {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon } from '@/components/icons/index'
  
  export default {
    name: 'review-app-page',
    components: {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon },
    data() {
      return {
        isShowText: false,
        selectedAll: false,
        selectedTracks: [],
        showReviews: [],
        totalPageCount: 10,
        filter: {
          count: 10,
          page: 1,
        },
        selectedStatusModeration: {text: 'Все', value: 'all'},
        statusesModeration: [
          { text: 'Все', value: 'all' },
          { text: 'На модерации', value: 1},
          { text: 'Отклонен', value: 2 },
          { text: 'Одобрен', value: 3},
        ],
        menu2: false,
        event_date: '',
        search: '',
        waiteLoad: false,
        listReviews: [],
        listReviewsDef:[],
        sortedState: {
          id: 'desc',
          fio: 'default',
          count: 'default',
        }
      }
    },
    mixins: [globalMethods],
    computed: {
      ...mapState({
        isSubmitting: state => state.track.isSubmitting,
        validationErrors: state => state.track.validationErrors,
        trackInfo: state => state.track.trackInfo?.track[0],
      }),
      pagedTracks() {
        const startIdx = (this.filter.page - 1) * this.filter.count;
        const endIdx = startIdx + this.filter.count;
        return this.listReviews.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.listReviews.length / this.filter.count);
      },
    },
    async mounted() {
      await this.loadOrder()
      await this.loadLoans()
    },
    methods: {
      showTextHandler(id) {
        this.showReviews.forEach(rev => {
          if (rev.id === id) rev.isShow = !rev.isShow
        })
      },
      isMatchesDateFilter(reviewsApp) {
        const dates = this.event_date && this.event_date.sort((a, b) => +new Date(a) - +new Date(b))
        const created_date = +new Date(reviewsApp.created.split(' ')[0])
        if (dates.length === 2) {
            const startDate = +new Date(dates[0])
            const endDate = +new Date(dates[1])
            return created_date >= startDate && created_date <= endDate
        } else if (dates.length === 1) {
            const day = +new Date(dates[0])
            return created_date === day
        }
        return true
      },
      makeSort(key) {
        const sortedAsNumberKey = ['id', 'count']
        const sortedAsStringKey = ['fio']
        Object.keys(this.sortedState).forEach(k => {
          if (key !== k) this.sortedState[k] = 'default'
        })
        if (sortedAsNumberKey.includes(key)) {
          switch(this.sortedState[key]) {
            case 'desc':
              this.listReviews.sort((a, b) => +a[key] - +b[key])
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listReviews.sort((a, b) => b.id - a.id)
              this.sortedState.id = 'desc'
              key === 'count' && (this.sortedState[key] = 'default')
              break
            default:
              this.listReviews.sort((a, b) => +b[key] - +a[key])
              this.sortedState[key] = 'desc'
          }
        } else if (sortedAsStringKey.includes(key)) {
          switch(this.sortedState[key]) {
            case 'desc':
              this.listReviews.sort((a, b) => a[key] > b[key] ? 1 : -1)
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listReviews.sort((a, b) => b.id - a.id)
              this.sortedState.id = 'desc'
              this.sortedState[key] = 'default'
              break
            default:
              this.listReviews.sort((a, b) =>  b[key] > a[key] ? 1 : -1)
              this.sortedState[key] = 'desc'
          }
        }
      },
      makeSearch() {
        this.listReviews = this.listReviewsDef.filter((item) => {
          const keys = ['text', 'fio']

          if (keys.every(key => item[key] !== null)) {
            const isMatchesSearchFilter = keys.some(key => 
              toLowerCaseTrim(item[key]).includes(toLowerCaseTrim(this.search))
            )
            const isMatchesStatusMaoderationFilter = (
              this.selectedStatusModeration.value === item.moderation_status ||
              this.selectedStatusModeration.value === 'all'
            )
            return (
              isMatchesSearchFilter && this.isMatchesDateFilter(item) && isMatchesStatusMaoderationFilter
            )
          }
        })
      },
      toggleSelectionAll() {
        if (this.selectedAll) {
          this.pagedTracks.forEach(track => {
              this.selectedTracks.push(track)
              track.selected = true
          })
        } else {
          this.pagedTracks.forEach(track => {
              this.selectedTracks = []
              track.selected = false
          })
        }
      },
      toggleSelection(track) {
        if (track.selected) {
          this.selectedTracks.push(track);
        } else {
          const index = this.selectedTracks.findIndex(selectedTrack => selectedTrack.id === track.id);
          if (index !== -1) {
            this.selectedTracks.splice(index, 1);
          }
        }
      },
      loadLoans() {
        this.$set(this, "waiteLoad", true);
        this.$store.dispatch(reviewsAppActionTypes.getReviewsForTrack, {track_id: this.$route.params.id}).then(res => {
          this.listReviews = res.reviews
          this.listReviewsDef = res.reviews
          if (this.$route.query['search']) { 
            this.checkSearchParams()
            this.makeSearch()
          }
          this.listReviewsDef.forEach(({id}) => this.showReviews.push({id, isShow: false}))
        })
      },
      async loadOrder() {
        await this.$store.dispatch(trackActionTypes.trackInfo,{id: this.$route.params.id})
      },
      remove() {
        if (confirm('Вы уверены, что хотите удалить выбранные отзывы?')) {
          const reviews_id = this.selectedTracks.map(review => review.id);
          this.$store.dispatch(reviewsAppActionTypes.trackReviewsDelete, {
            track_id: this.$route.params.id, reviews_id
          }).then(() => {
            this.loadLoans()
            this.loadOrder()
            this.selectedTracks = [] 
          })
        }
      },
      removeOne(reviews_id) {
        if (confirm('Вы уверены, что хотите удалить выбранный отзыв?')) {
          this.$store.dispatch(reviewsAppActionTypes.trackReviewsDelete, {
            track_id: this.$route.params.id, reviews_id: [reviews_id]
          }).then(() => {this.loadLoans(); this.loadOrder()})
        }
    },
      checkSearchParams() {
        const searchQuery = this.$route.query
        const paramsKeys = ['search', 'date', 'status']
        if (paramsKeys.some(key => searchQuery[key])) {
          const findedStatus = this.statusesModeration.find(sm => sm.value == searchQuery.status)
          this.search = decodeURIComponent(searchQuery.search)
          findedStatus && (this.selectedStatusModeration = {...this.selectedStatusModeration, ...findedStatus}) 
          searchQuery.date && (this.event_date = searchQuery.date.split(','))    
        }
      },
      setRouteParams() {
        const search = `search=${this.search}`
        const date = `date=${this.event_date}`
        const status = `status=${this.selectedStatusModeration.value}`
        this.$router.push({path: `?${search}&${date}&${status}`})
      }
    },
    watch: {
      event_date () {
        this.setRouteParams()
      },
      search() {
        this.setRouteParams()
      },
      selectedStatusModeration: {
        handler () {
          this.setRouteParams()
        },
        deep: true,
      }
    },
  }
  </script>
  
  <style scoped lang="scss">
  .checkbox {
    margin-left: 5px;
  }
  .table-reviews td {
    vertical-align: top;
  }
  .read-more {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 400px;
  }

  .button {
    width: 160px;
    align-self: end;
  }

  .text {
    text-align: justify;
  }
  .text.hide{
    width: 400px;
    max-height: 90px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    // transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  }
  .text.show {
    display: block;
    // width: 345px;
    max-height: 1000px;
    // transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  }
  .filters-wrapper {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    gap: 20px;
  }

  .filters {
    display: flex;
    align-items: center; 
    gap: 20px;
  }

  .moderation-info {
    justify-items: start;
    margin: 10px
  }
  .filters-select {
    width: 200px;
  }
  .head-wrap {
    display: flex;
    justify-content: end;
    padding-right: 25px;
    margin-top: -20px;
  }
  .head {
    width: 400px!important;
  }

  .search-wrapper {
    display:flex; 
    justify-self: end;
  }

  .search-input {
    border-radius: 2px;
    height: 32px; 
    width: 200px; 
    padding: 10px;
    border: 1px solid #dfdfdf;
  }

  .search {
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 32px; 
    width: 37px; 
    background-color: #F32719;
  }

  .column-title {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;

    & svg {
      cursor: pointer;
    }
  }
  </style>
  