import appealsApi from '@/api/appeals';

const state = {
    listAppeals: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getAppealsStart: '[appeals] Get appeals start',
    getAppealsSuccess: '[appeals] Get appeals success',
    getAppealsFailure: '[appeals] Get appeals failure',
    
    deleteAppealsStart: '[appeals] Delete appeals start',
    deleteAppealsSuccess: '[appeals] Delete appeals success',
    deleteAppealsFailure: '[appeals] Delete appeals failure',
}

export const appealsActionTypes = {
    getAppeals: '[appeals] Get appeals',
    deleteAppeals: '[appeals] Delete appeals',
}

const mutations = {
    [mutationTypes.getAppealsStart](state) {
        state.isLoading = true
        state.listAppeals = null
    },
    [mutationTypes.getAppealsSuccess](state, payload) {
        state.isLoading = false
        state.listAppeals = payload
        state.validationErrors = null
    },
    [mutationTypes.getAppealsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.deleteAppealsStart](state) {
        state.isLoading = true
    },
    [mutationTypes.deleteAppealsSuccess](state, payload) {
        state.isLoading = false
        state.validationErrors = null
    },
    [mutationTypes.deleteAppealsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [appealsActionTypes.getAppeals](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getAppealsStart)
            appealsApi
                .getAppeals(data)
                .then(response => {
                    context.commit(mutationTypes.getAppealsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getAppealsFailure, response)
                })
        })
    },
    [appealsActionTypes.deleteAppeals](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.deleteAppealsStart)
            appealsApi
                .deleteAppeals(data)
                .then(response => {
                    context.commit(mutationTypes.deleteAppealsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.deleteAppealsFailure, response)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
}