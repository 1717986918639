import axios from '@/api/axios'

const getPrivacyPolicy = () => {
    return axios
        .get('/admin/privacy')
        .then(response => response.data)
}


const getPrivacyPolicyInfo = (data) => {
    return axios
        .post('/admin/privacy', { policy: data })
        .then(response => response.data)
}

const updatePrivacyPolicy = (data) => {
    return axios
        .post('/admin/privacy/update', { policy: data })
        .then(response => response.data)
}

const createPrivacyPolicy = (data) => {
    return axios
        .post('/admin/privacy/create', { policy: data })
        .then(response => response.data)
}

const deletePrivacyPolicy = (data) => {
    return axios
        .post('/admin/privacy/delete', data)
        .then(response => response.data)
}

export default {
    getPrivacyPolicy,
    updatePrivacyPolicy,
    createPrivacyPolicy,
    getPrivacyPolicyInfo,
    deletePrivacyPolicy
}
