import trackApi from '@/api/track';

const state = {
    listTracks: null,
    trackInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getTracksStart: '[track] Get tracks start',
    getTracksSuccess: '[track] Get tracks success',
    getTracksFailure: '[track] Get tracks failure',

    trackInfoStart: '[track] Get track info start',
    trackInfoSuccess: '[track] Get track info success',
    trackInfoFailure: '[track] Get track info failure',

    trackUpdateStart: '[track] Update track start',
    trackUpdateSuccess: '[track] Update track success',
    trackUpdateFailure: '[track] Update track failure',

    trackRemoveStart: '[track] Remove track start',
    trackRemoveSuccess: '[track] Remove track success',
    trackRemoveFailure: '[track] Remove track failure',

    trackCreateStart: '[track] Create track start',
    trackCreateSuccess: '[track] Create track success',
    trackCreateFailure: '[track] Create track failure',

    pointCreateStart: '[track] Point create start',
    pointCreateSuccess: '[track] Point create success',
    pointCreateFailure: '[track] Point create failure',
    
    updateTagsStart: '[track] Update tags start',
    updateTagsSuccess: '[track] Update tags success',
    updateTagsFailure: '[track] Update tags failure',
    
    deleteTagStart: '[track] Delete tag start',
    deleteTagSuccess: '[track] Delete tag success',
    deleteTagFailure: '[track] Delete tag failure',
    
    deleteMediaStart: '[track] Delete media start',
    deleteMediaSuccess: '[track] Delete media success',
    deleteMediaFailure: '[track] Delete media failure',

}

export const trackActionTypes = {
    getTracks: '[track] Get tracks',
    trackInfo: '[track] Get track info',
    trackUpdate: '[track] Update track',
    trackRemove: '[track] Remove track',
    trackCreate: '[track] Create track',
    pointCreate: '[track] Point create',
    deleteTag: '[track] Delete tag',
    updateTags: '[track] Update tags',
    deleteMedia: '[track] Delete media',
}

const mutations = {
    [mutationTypes.getTracksStart](state) {
        state.isLoading = true
        state.listTracks = null
    },
    [mutationTypes.getTracksSuccess](state, payload) {
        state.isLoading = false
        state.listTracks = payload
        state.validationErrors = null
    },
    [mutationTypes.getTracksFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.trackInfoStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.trackInfoSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.trackInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.trackUpdateStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.trackUpdateSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.trackUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.trackRemoveStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.trackRemoveSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.trackRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.trackCreateStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.trackCreateSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.trackCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },


    [mutationTypes.pointCreateStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.pointCreateSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.pointCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.updateTagsStart](state) {
        state.isLoading = true
    },
    [mutationTypes.updateTagsSuccess](state) {
        state.isLoading = false
    },
    [mutationTypes.updateTagsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.deleteTagStart](state) {
        state.isLoading = true
    },
    [mutationTypes.deleteTagSuccess](state, payload) {
        state.isLoading = false
    },
    [mutationTypes.deleteTagFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
    
    [mutationTypes.deleteMediaStart](state) {
        state.isLoading = true
    },
    [mutationTypes.deleteMediaSuccess](state, payload) {
        state.isLoading = false
    },
    [mutationTypes.deleteMediaFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [trackActionTypes.getTracks](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getTracksStart)
            trackApi
                .getTracks(data)
                .then(response => {
                    context.commit(mutationTypes.getTracksSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getTracksFailure, response)
                })
        })
    },
    [trackActionTypes.trackInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.trackInfoStart)
            trackApi
                .updateTrack(data)
                .then(response => {
                    context.commit(mutationTypes.trackInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.trackInfoFailure, response)
                })
        })
    },
    [trackActionTypes.trackUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.trackUpdateStart)
            trackApi
                .updateTrack(data)
                .then(response => {
                    context.commit(mutationTypes.trackUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.trackUpdateFailure, response)
                })
        })
    },
    [trackActionTypes.trackRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.trackRemoveStart)
            trackApi
                .deleteTrack(data)
                .then(response => {
                    context.commit(mutationTypes.trackRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.trackRemoveFailure, response)
                })
        })
    },

    [trackActionTypes.trackCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.trackCreateStart)
            trackApi
                .createTrack(data)
                .then(response => {
                    context.commit(mutationTypes.trackCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.trackCreateFailure, response)
                })
        })
    },

    [trackActionTypes.pointCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.pointCreateStart)
            trackApi
                .createPoint(data)
                .then(response => {
                    context.commit(mutationTypes.pointCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.pointCreateFailure, response)
                })
        })
    },

    [trackActionTypes.updateTags](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.updateTagsStart)
            trackApi
                .updateTags(data)
                .then(response => {
                    context.commit(mutationTypes.updateTagsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.updateTagsFailure, response)
                })
        })
    },

    [trackActionTypes.deleteTag](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.deleteTagStart)
            trackApi
                .deleteTag(data)
                .then(response => {
                    context.commit(mutationTypes.deleteTagSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.deleteTagFailure, response)
                })
        })
    },
    
    [trackActionTypes.deleteMedia](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.deleteMediaStart)
            trackApi
                .deleteMedia(data)
                .then(response => {
                    context.commit(mutationTypes.deleteMediaSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.deleteMediaFailure, response)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
}
