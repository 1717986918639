import moderationApi from '@/api/moderation';

const state = {
    listModeration: null,
    moderationInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getModerationStart: '[moderation] Get moderation start',
    getModerationSuccess: '[moderation] Get moderation success',
    getModerationFailure: '[moderation] Get moderation failure',

    moderationInfoStart: '[moderation] Get moderation info start',
    moderationInfoSuccess: '[moderation] Get moderation info success',
    moderationInfoFailure: '[moderation] Get moderation info failure',

    moderationUpdateStart: '[moderation] Update moderation start',
    moderationUpdateSuccess: '[moderation] Update moderation success',
    moderationUpdateFailure: '[moderation] Update moderation failure',

}

export const moderationActionTypes = {
    getModeration: '[moderation] Get moderation',
    moderationInfo: '[moderation] Get moderation info',
    moderationUpdate: '[moderation] Update moderation',
}

const mutations = {
    [mutationTypes.getModerationStart](state) {
        state.isLoading = true
        state.listModeration = null
    },
    [mutationTypes.getModerationSuccess](state, payload) {
        state.isLoading = false
        state.listModeration = payload
        state.validationErrors = null
    },
    [mutationTypes.getModerationFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.moderationInfoStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.moderationInfoSuccess](state, payload) {
        state.isLoading = false
        state.moderationkInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.moderationInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.moderationUpdateStart](state) {
        state.isLoading = true
        state.moderationInfo = null
    },
    [mutationTypes.moderationUpdateSuccess](state, payload) {
        state.isLoading = false
        state.moderationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.moderationUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [moderationActionTypes.getModeration](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getModerationStart)
            moderationApi
                .getModeration(data)
                .then(response => {
                    context.commit(mutationTypes.getModerationSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getModerationFailure, response)
                })
        })
    },
    [moderationActionTypes.moderationInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.moderationInfoStart)
            moderationApi
                .updateModeration(data)
                .then(response => {
                    context.commit(mutationTypes.moderationInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.moderationInfoFailure, response)
                })
        })
    },
    [moderationActionTypes.moderationUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.moderationUpdateStart)
            moderationApi
                .updateModeration(data)
                .then(response => {
                    context.commit(mutationTypes.moderationUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.moderationUpdateFailure, response)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
}