import agreementApi from '@/api/agreement';

const state = {
    listAgreements: null,
    agreementInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getAgreementsStart: '[agreement] Get agreements start',
    getAgreementsSuccess: '[agreement] Get agreements success',
    getAgreementsFailure: '[agreement] Get agreements failure',

    agreementInfoStart: '[agreement] Get agreement info start',
    agreementInfoSuccess: '[agreement] Get agreement info success',
    agreementInfoFailure: '[agreement] Get agreement info failure',

    agreementCreateStart: '[agreement] Create agreement start',
    agreementCreateSuccess: '[agreement] Create agreement success',
    agreementCreateFailure: '[agreement] Create agreement failure',

    agreementUpdateStart: '[agreement] Update agreement start',
    agreementUpdateSuccess: '[agreement] Update agreement success',
    agreementUpdateFailure: '[agreement] Update agreement failure',

    agreementRemoveStart: '[agreement] Remove agreement start',
    agreementRemoveSuccess: '[agreement] Remove agreement success',
    agreementRemoveFailure: '[agreement] Remove agreement failure'
}

export const agreementActionTypes = {
    getAgreements: '[agreement] Get agreements',
    agreementInfo: '[agreement] Get agreement info',
    agreementCreate: '[agreement] Create agreement',
    agreementUpdate: '[agreement] Update agreement',
    agreementRemove: '[agreement] Remove agreement',
}

const mutations = {
    [mutationTypes.getAgreementsStart](state) {
        state.isLoading = true
        state.listAgreements = null
    },
    [mutationTypes.getAgreementsSuccess](state, payload) {
        state.isLoading = false
        state.listAgreements = payload
        state.validationErrors = null
    },
    [mutationTypes.getAgreementsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.agreementInfoStart](state) {
        state.isLoading = true
        state.agreementInfo = null
    },
    [mutationTypes.agreementInfoSuccess](state, payload) {
        state.isLoading = false
        state.agreementInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.agreementInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.agreementCreateStart](state) {
        state.isLoading = true
        state.agreementInfo = null
    },
    [mutationTypes.agreementCreateSuccess](state, payload) {
        state.isLoading = false
        state.agreementInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.agreementCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.agreementUpdateStart](state) {
        state.isLoading = true
        state.agreementInfo = null
    },
    [mutationTypes.agreementUpdateSuccess](state, payload) {
        state.isLoading = false
        state.agreementInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.agreementUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.agreementRemoveStart](state) {
        state.isLoading = true
        state.agreementInfo = null
    },
    [mutationTypes.agreementRemoveSuccess](state, payload) {
        state.isLoading = false
        state.agreementInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.agreementRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [agreementActionTypes.getAgreements](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getAgreementsStart)
            agreementApi
                .getUserAgreement(data)
                .then(response => {
                    context.commit(mutationTypes.getAgreementsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getAgreementsFailure, response)
                })
        })
    },
    [agreementActionTypes.agreementInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.agreementInfoStart)
            agreementApi
                .agreementInfo(data)
                .then(response => {
                    context.commit(mutationTypes.agreementInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.agreementInfoFailure, response)
                })
        })
    },
    [agreementActionTypes.agreementCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.agreementCreateStart)
            agreementApi
                .createUserAgreement(data)
                .then(response => {
                    context.commit(mutationTypes.agreementCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.agreementCreateFailure, response)
                })
        })
    },
    [agreementActionTypes.agreementUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.agreementUpdateStart)
            agreementApi
                .updateUserAgreement(data)
                .then(response => {
                    context.commit(mutationTypes.agreementUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.agreementUpdateFailure, response)
                })
        })
    },
    [agreementActionTypes.agreementRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.agreementRemoveStart)
            agreementApi
                .deleteAgreement(data)
                .then(response => {
                    context.commit(mutationTypes.agreementRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.agreementRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}
