<template>
  <div class="orders-page">
    <Heading level="2" text="Юридическая информация"/>
    <Grid :column="1" :gap="10">

      <ContentBlock>
        <div class="data-controll">
          <router-link :to="`/main/directory`" >Локации</router-link>
          <router-link :to="`/main/subjects-appeals`" >Тематика обращений</router-link>
          <router-link :to="`/main/tags`">Теги</router-link>
          <router-link :to="`/main/ranks`">Звания</router-link>
          <router-link :to="`/main/privacy`">Юридическая информация</router-link>
          <router-link :to="`/main/legal`">Пользовательское соглашение</router-link>
          <router-link :to="`/main/agreements`">Политика конфиденциальности</router-link>
        </div>


      </ContentBlock>
      <ContentBlock>
        <router-link :to="`/main/privacy/create`" >
          Добавить
        </router-link>
      </ContentBlock>
      <ContentBlock>
        <Table v-if="listPrivacy">
          <template v-slot:head>
            <tr>
              <th>
                ID
              </th>
              <th>
                Описание
              </th>

              <th>
              </th>
            </tr>
          </template>
          <template v-slot:body >
            <tr v-for="(order, ind) of listPrivacy.policy" :key="`order-${ind}`">
              <td>{{ order.id }}</td>
              <td>{{ order.policy }}</td>
              <td align="right">
                <router-link :to="`/main/privacy/update/${order.id}`">
                  <Icon iconName="edit" width="24" height="24" stroke="#a6e71d"/>
                </router-link>
              </td>
              <td align="right">
                <button @click="remove(order.id)">
                  <Icon iconName="delete" width="24" height="24" stroke="#a6e71d"/>
                </button>
              </td>
            </tr>
          </template>
        </Table>

      </ContentBlock>
    </Grid>
    <Modal ref="confirm">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        {{ confirmData.body }}
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.confirm._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="$refs.confirm._confirm()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

const debounce = require("lodash.debounce");
import {globalMethods} from "@/mixins/globalMethods";
import  {privacyActionTypes} from '@/store/modules/privacy';

export default {
  name: 'Loans-page',
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      listPrivacy: state => state.privacy.listPrivacy
    })
  },
  data() {
    return {
      selectedValue: '',
      testData: [
        { id: 1, name: 'локация1' },
        { id: 2, name: 'локация2'},
        { id: 3, name: 'локация3' },
      ],
      newTag: '',
      queryTerm: '',
      isLoading: false,
      itemsBranches: [],
      orders: [],
      totalPageCount: 10,
      filter: {
        loan_id: null,
        affiliate_id: '',
        status: ['0'],
        count: 10,
        max_sum_loan: null,
        page: 1,
      },
      statusList: {
        '0': 'В обработкe',
        '1': 'Займ выдан',
        '2': 'Отказ',
        '3': 'Архив',
      },
      countList: [10, 30, 50, 100],
      search: '',
      waiteLoad: false,
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Удалить',
      }
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.branchFind()
      }
    }
  },
  created() {
    this.branchFind = debounce(this.branchFind, 500);
  },
  mounted() {
    this.loadLoans()
  },
  methods: {
    branchFind() {
      this.$set(this, "isLoading", true);
      this.sendRequest('GET', {term: this.search}, '/search_branch', {}, false, true)
          .then(data => {
            data = [
              {id: '1', name: 'Филиал 1'},
              {id: '2', name: 'Филиал 2'},
              {id: '3', name: 'Филиал 3'},
            ]

            this.$set(this, "itemsBranches", data);
            this.$set(this, "isLoading", false);
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    loadLoans() {

        this.$set(this, "waiteLoad", true);
        this.$store.dispatch(privacyActionTypes.getPrivacy);

    },
    remove(id) {

      this.$set(this.confirmData, 'header', `Удалить #${id}`);
      this.$set(this.confirmData, 'body', `Вы уверены что хотите удалить #${id}? После удаления вернуть данные будет не возможно!`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.$store.dispatch(privacyActionTypes.privacyRemove, {
            id
          })
          .then(() => {
            this.loadLoans()
          })
        }
      })

    }
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";


.orders-page {
  .filter {
    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        &.coll-2 {
          width: calc(50% - 3px);
        }

        &.coll-3 {
          width: calc(33% - 3px);
        }

        &.coll-4 {
          width: calc(25% - 3px);
        }
      }
    }

    .buttons-group {
      display: flex;
      justify-content: flex-end;
    }
  }

  .data-controll {

    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      margin-right: 10px;
      color: $color-orange;
    }
    .count {
      width: 85px;
    }

    .data-page {
      font-weight: bold;

      span {
        color: $color-orange;
      }
    }
  }

}
</style>