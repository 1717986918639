<template>
  <div class="orders-page">
    <Heading level="2" :text="`Звание #${$route.params.id}`" :backButton="true"/>
    <Grid :column="1" :gap="10">
      <ContentBlock v-if="rankInfo">

          <div class="order-info__item">
            <span>Звание:</span>
            <div> <v-text-field v-model="rankInfo.ranks.title"  required></v-text-field></div>
          </div>

          <div class="order-info__item">
            <span>Описание:</span>
            <div> <v-text-field v-model="rankInfo.ranks.description"  required></v-text-field></div>
          </div>

          <v-col cols="6" sm="6" md="6">
                  <form @submit.prevent="onSubmitImage" v-if="!rankInfo.ranks.image">
                    <v-file-input
                        v-model="file"
                        accept="image/*"
                        label="Изображение"
                        required
                        clear-icon="mdi-close-circle"
                    ></v-file-input>
                    <!-- <v-col cols="2" sm="2" md="2">
                      <v-btn
                          type="submit"
                          block
                          class="mt-6"
                          color="primary"
                      >
                        Добавить картинку
                      </v-btn>
                    </v-col> -->
                  </form>
                  <div v-if="rankInfo.ranks.image" :style="'background-image:url('+rankInfo.ranks.image+'); background-size:cover; background-repeat:no-repeat; background-position:center; width:40px; height:40px;margin:5px 0px;'"></div>           
                </v-col>
        
        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm"
          >
            Сохранить
          </v-btn>



        </div>
      </ContentBlock>
    
    </Grid>

  </div>

</template>

<script>
import {globalMethods} from "@/mixins/globalMethods";
import {json} from "body-parser";
import ranks, {ranksActionTypes} from '@/store/modules/ranks';
import {mapState} from "vuex";

export default {
  name: 'UpdateRank',
  mixins: [globalMethods],
  data() {
    return {
      orderData: {},
      statusList: {
        '0': 'В обработкe',
        '1': 'Займ выдан',
        '2': 'Отказ',
        '3': 'Архив',
      },
      rankOne: {
        title: '',
        description: ''
      },
      file:null
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.loan.isSubmitting,
      validationErrors: state => state.loan.validationErrors,
      rankInfo: state => state.ranks.rankInfo
    })
  },
  mounted() {
    this.loadOrder()
  },
  watch: {
    // rankInfo() {
    //   console.log(this.rankInfo);
    // }
  },
  methods: {
    loadOrder() {
      this.$store.dispatch(ranksActionTypes.rankInfo, {
        id: this.$route.params.id
      });
    },
    submitForm() {
      console.log(this.rankInfo)

      if (this.isFormValid()) {
        let formData = new FormData();
        formData.append('id', this.$route.params.id);
          formData.append('title', this.rankInfo.ranks.title);
          formData.append('description', this.rankInfo.ranks.description);
        if(this.file){
          formData.append('files', this.file);
        }
        console.log(formData)
        this.$store.dispatch(ranksActionTypes.rankUpdate, formData).then(()=>{
          this.$router.push('/main/ranks');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
    isFormValid() {
        if (this.rankInfo.ranks.title === null || this.rankInfo.ranks.title === '' || this.rankInfo.ranks.description === '' || this.rankInfo.ranks.description === null) {
          return false;
        }
      return true;
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.order-page {
  .order-info {
    padding-bottom: 15px;

    &__item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        padding-bottom: 3px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }

      span {
        font-weight: bold;
        width: 20%;

      }

      &.column {
        flex-direction: column;

        span {
          width: 100%;
        }
      }
    }
  }
}
</style>