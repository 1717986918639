import axios from '@/api/axios'

const getNotices = (data) => {
    return axios
        .post('/admin/notice', data)
        .then(response => response.data)
}

const createNotice = (data) => {
    return axios
        .post('/admin/notice/create', { notice: data})
        .then(response => response.data)
}

const updateNotice = (data) => {
    return axios
        .post('/admin/notice/update', { notice: data})
        .then(response => response.data)
}

export default {
    getNotices,
    createNotice,
    updateNotice
}
