import tariffApi from '@/api/tariff';

const state = {
    listTarrifs: null,
    updatedTariff: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getTariffsStart: '[tariffs] Get tariffs start',
    getTariffsSuccess: '[tariffs] Get tariffs success',
    getTariffsFailure: '[tariffs] Get tariffs failure',
    updateTariffStart: '[tariff] Update tariff start',
    updateTariffSuccess: '[tariff] Update tariff success',
    updateTariffFailure: '[tariff] Update tariff failure',
}

export const tariffsActionTypes = {
    getTariffs: '[tariffs] Get tariffs',
    updateTariff: '[tariff] Update tariff',
}

const mutations = {
    [mutationTypes.getTariffsStart](state) {
        state.isLoading = true
        state.listTarrifs = null
    },
    [mutationTypes.getTariffsSuccess](state, payload) {
        state.isLoading = false
        state.listTarrifs = payload
        state.validationErrors = null
    },
    [mutationTypes.getTariffsFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.updateTariffStart](state) {
        state.isLoading = true
        state.updatedTariff = null
    },
    [mutationTypes.updateTariffSuccess](state, payload) {
        state.isLoading = false
        state.updatedTariff = payload
        state.validationErrors = null
    },
    [mutationTypes.updateTariffFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [tariffsActionTypes.getTariffs](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getTariffsStart)
            tariffApi
                .getTariffs(data)
                .then(response => {
                    context.commit(mutationTypes.getTariffsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getTariffsFailure,response)
                })
        })
    },
    [tariffsActionTypes.updateTariff](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.updateTariffStart)
            tariffApi
                .updateTariff(data)
                .then(response => {
                    context.commit(mutationTypes.updateTariffSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.updateTariffFailure,response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}