import axios from '@/api/axios'

const getTariffs = (data) => {
    return axios
        .post('/admin/tariff', data)
        .then(response => response.data)
}

const updateTariff = (data) => {
    return axios
        .post('/admin/tariff/update', { tariff: data})
        .then(response => response.data)
}

export default {
    getTariffs,
    updateTariff
}
