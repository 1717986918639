import locationsApi from '@/api/locations';

const state = {
    listLocations: null,
    locationInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getLocationsStart: '[locations] Get locations start',
    getLocationsSuccess: '[locations] Get locations success',
    getLocationsFailure: '[locations] Get locations failure',

    locationInfoStart: '[location] Get location info start',
    locationInfoSuccess: '[location] Get location info success',
    locationInfoFailure: '[location] Get location info failure',

    locationUpdateStart: '[location] Get location update start',
    locationUpdateSuccess: '[location] Get location update success',
    locationUpdateFailure: '[location] Get location update failure',

    locationRemoveStart: '[location] Get location remove start',
    locationRemoveSuccess: '[location] Get location remove success',
    locationRemoveFailure: '[location] Get location remove failure',

    locationCreateStart: '[location] Create location remove start',
    locationCreateSuccess: '[location] Create location remove success',
    locationCreateFailure: '[location] Create location remove failure'
}

export const locationsActionTypes = {
    getLocations: '[locations] Get locations',
    locationInfo: '[location] Get location',
    locationUpdate: '[location] Get update location',
    locationRemove: '[location] Get remove location',
    locationCreate: '[location] Create location',
}

const mutations = {
    [mutationTypes.getLocationsStart](state) {
        state.isLoading = true
        state.listLocations = null
    },
    [mutationTypes.getLocationsSuccess](state, payload) {
        state.isLoading = false
        state.listLocations = payload
        state.validationErrors = null
    },
    [mutationTypes.getLocationsFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.locationInfoStart](state) {
        state.isLoading = true
        state.locationInfo = null
    },
    [mutationTypes.locationInfoSuccess](state, payload) {
        state.isLoading = false
        state.locationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.locationInfoFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },


    [mutationTypes.locationUpdateStart](state) {
        state.isLoading = true
        state.locationInfo = null
    },
    [mutationTypes.locationUpdateSuccess](state, payload) {
        state.isLoading = false
        state.locationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.locationUpdateFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.locationRemoveStart](state) {
        state.isLoading = true
        state.locationInfo = null
    },
    [mutationTypes.locationRemoveSuccess](state, payload) {
        state.isLoading = false
        state.locationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.locationRemoveFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },


    [mutationTypes.locationCreateStart](state) {
        state.isLoading = true
        state.locationInfo = null
    },
    [mutationTypes.locationCreateSuccess](state, payload) {
        state.isLoading = false
        state.locationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.locationCreateFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [locationsActionTypes.getLocations](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getLocationsStart)
            locationsApi
                .getLocations(data)
                .then(response => {
                    context.commit(mutationTypes.getLocationsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getLocationsFailure,response)
                })
        })
    },
    [locationsActionTypes.locationInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.locationInfoStart)
            locationsApi
                .locationInfo(data)
                .then(response => {
                    context.commit(mutationTypes.locationInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.locationInfoFailure,response)
                })
        })
    },
    [locationsActionTypes.locationCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.locationCreateStart)
            locationsApi
                .locationCreate(data)
                .then(response => {
                    context.commit(mutationTypes.locationCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.locationCreateFailure,response)
                })
        })
    },
    [locationsActionTypes.locationUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.locationUpdateStart)
            locationsApi
                .locationUpdate(data)
                .then(response => {
                    context.commit(mutationTypes.locationUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.locationUpdateFailure,response)
                })
        })
    },
    [locationsActionTypes.locationRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.locationRemoveStart)
            locationsApi
                .deleteLocation(data)
                .then(response => {
                    context.commit(mutationTypes.locationRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.locationRemoveFailure,response)
                })
        })
    }

}

export default {
    state,
    actions,
    mutations
}
