<template>
  <transition name="fade">
    <div class="modal-backdrop" v-if="open" @click.self="close">
      <div class="modal" :class="size" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="modal-header" id="modalTitle" :class="!captinType ? 'no-border' : ''">
          <Icon width="17" height="17" :iconName="'close'" stroke="#000" @click="close" />

          <slot name="header">
            <button type="button" class="btn-close" @click="close" aria-label="Close modal">
              x
            </button>
          </slot>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body"></slot>
        </section>
        <footer class="modal-footer" :class="{ 'not-border': notFooterBorder }">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "modal",
  props: {
    notFooterBorder: {
      typeof: Boolean,
      default: false
    },
    size: {
      typeof: String,
      default: ''
    }
  },
  data() {
    return {
      open: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      captinType: false,
      closeAsCancel: false
    };
  },
  methods: {
    show(opts = {}) {
      if (typeof opts.preload === 'function') {
        opts.preload()
      }
      if (opts.confirmNow === true) {
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
          this.close();
          this.resolvePromise(true);
        });
      }
      this.open = true;
      this.captinType = opts.captin_type || false;
      // this.title = opts.title;
      // this.message = opts.message;
      // this.okButton = opts.okButton;
      // if (opts.cancelButton) {
      //   this.cancelButton = opts.cancelButton;
      // }
      // Once we set our config, we tell the popup modal to open
      // this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
    close() {
      this.open = false;
      if (this.closeAsCancel) {
        this.resolvePromise(false);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;

  .modal {
    background: #ffffff;
    // box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-width: 600px;
    width: 600px;
    color: #1c1c1c;
    max-height: 99%;

    @media screen and (max-width: 560px) {
      max-width: 95%;
      width: 95%;
    }

    &.full {
      max-width: 99%;
      max-width: 99%;
      width: 100%;
      height: 100%;
    }

    .modal-header,
    .modal-footer {
      padding: 5px 10px;
      display: flex;

      button {
        margin-top: 0;
        border-radius: 5px;
      }
    }

    .modal-header {
      border-bottom: 1px solid #eeeeee;
      // color: var(--color-green);
      font-size: 20px;
      font-weight: 700;
      justify-content: space-between;
      position: relative;
      min-height: 25px;
      flex-direction: row-reverse;
      align-items: flex-start;

      svg {
        cursor: pointer;
        transition: all 0.3s;
        color: #000;

        &:hover {
          color: $color-red;
        }
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .modal-footer {
      border-top: 1px solid #eeeeee;
      justify-content: flex-end;

      &.not-border {
        border: none;
      }

      .btn {
        &:not(:last-child) {
          margin-right: 10px;
        }

        &.reject {
          background: rgba(0, 0, 0, 0.5) !important;
          color: #fff;
        }
      }
    }

    .modal-body {
      position: relative;
      padding: 20px 10px;
      height: 100%;
      width: 100%;
    }

    .btn-close {
      border: none;
      font-size: 20px;
      padding: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #4aae9b;
      background: transparent;
    }

    .btn-green {
      color: white;
      background: #4aae9b;
      border: 1px solid #4aae9b;
      border-radius: 2px;
    }
  }
}
</style>