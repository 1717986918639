import axios from '@/api/axios'

const getUserAgreement = () => {
    return axios
        .get('/admin/agreement')
        .then(response => response.data)
}

const createUserAgreement = (data) => {
    return axios
        .post('/admin/agreement/create', { agreement: data })
        .then(response => response.data)
}

const updateUserAgreement = (data) => {
    return axios
        .post('/admin/agreement/update', { agreement: data })
        .then(response => response.data)
}

const agreementInfo = (data) => {
    return axios
        .post('/admin/agreement', { agreement: data })
        .then(response => response.data)
}


const deleteAgreement= (data) => {
    return axios
        .post('/admin/agreement/delete', data)
        .then(response => response.data)
}


export default {
    getUserAgreement,
    updateUserAgreement,
    deleteAgreement,
    createUserAgreement,
    agreementInfo
}
