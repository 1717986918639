<template>
    <div>
        <span class="heading">Прикрепленные видео к точкам:</span>
        <div style="width:100%; padding: 15px;" >
          <v-row>
            <v-col
              v-for="({fullpath, filename}) in videoSrcPoint"
              :key="filename"
              cols="4"
            >
              <div class="img-wrapper">
                <div v-if="!reodonly" class="close-icon" @click="deleteHandler(filename)">
                  <CloseIcon />
                </div>
                <div>
                  <video controls width="150">
                    <source :src="fullpath" type="video/webm" />
                    <source :src="fullpath" type="video/mp4" />
                  </video>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <span class="heading">Прикрепленные видео к треку:</span>
        <div style="width:100%; padding: 15px;" >
          <v-row>
            <v-col
              v-for="({fullpath, filename}) in videoSrcMedia"
              :key="filename"
              cols="4"
            >
              <div class="img-wrapper">
                <div v-if="!reodonly" class="close-icon" @click="deleteHandler(filename)">
                  <CloseIcon />
                </div>
                <div>
                  <video controls width="150">
                    <source :src="fullpath" type="video/webm" />
                    <source :src="fullpath" type="video/mp4" />
                  </video>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    
    <script>
    import CloseIcon from '@/components/icons/CloseIcon.vue'
    export default {
        components: { CloseIcon },
        props: {
            reodonly: {
                type: Boolean,
                default: false,
            },
            videoSrcPoint: {
                type: Array,
                default: () => [],
            },
            videoSrcMedia: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            deleteHandler(filename) {
                this.$emit('deleteMedia', filename)
            }
        }
    }
    </script>
    
    <style scoped lang="scss">
    .heading {
        font-weight: bold;
        width: 20%;
        font-size: 15px;
    }
    .img-wrapper {
      margin-top: 15px;
      position: relative;
      box-sizing: border-box;
      margin: 20px;
      width: 150px;
      .close-icon {
        background: #FF5252;
        border-radius: 100%;
        padding: 10px;
        display: flex;
        width: 29px;
        height: 29px;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        right: -15px;
        top: -15px;
        cursor: pointer;
    
        &:hover {
          background: #E57373;
        }
    
        & svg {
          fill: #fff;
        }
      }
    }
    </style>