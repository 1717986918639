<template>
  <div class="orders-page">
    <Heading level="2" text="Новая тематика обращения"/>
    <Grid :column="1" :gap="10">
      <ContentBlock >
        <div class="order-info__item">
          <span>Название тематики:</span>
          <div> <v-text-field v-model="newSubjectAppeal.subjectAppeal"  required></v-text-field></div>
        </div>
        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm">
            Сохранить
          </v-btn>
        </div>
      </ContentBlock>
    </Grid>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import {subjectsAppealsActionTypes} from '@/store/modules/subjects-appeals';

export default {
  name: 'CreateSubjectsAppeals',
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      subjectAppealInfo: state => state.complaints.subjectAppeal
    })
  },
  data() {
    return {
      itemsBranches: [
        { id: '2', name: 'Теги' },
        { id: '3', name: 'Звания' },
        { id: '4', name: 'Юридическая информация' },
        { id: '5', name: 'Пользовательское соглашение' },
        { id: '6', name: 'Политика конфиденциальности' },
      ],
      newSubjectAppeal: {
        subjectAppeal: ''
      }
    }
  },

  methods: {
    isFormValid() {
      return this.newSubjectAppeal.subjectAppeal !== null && this.newSubjectAppeal.subjectAppeal !== '';
    },
    submitForm() {
      console.log(this.newTag)
      if (this.isFormValid()) {
        this.$store.dispatch(subjectsAppealsActionTypes.subjectAppealCreate, {
          subject: this.newSubjectAppeal.subjectAppeal
        }).then(()=>{
          this.$router.push('/main/subjects-appeals');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
  }
}
</script>

<style scoped lang="scss">
/* ... ваши стили ... */
</style>
