<template>
    <div class="login-page">
        <slot />
        <Error />
    </div>
</template>
<script>
import Error from "../components/Errors";
export default {
    name: "default",
    components: { Error },
}
</script>
<style scoped lang="scss">
.login-page {
    height: 100vh;
    width: 100vw;
    background-color: #F32719;
}
</style>