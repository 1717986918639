import axios from '@/api/axios'

const getAppeals = () => {
    return axios
        .post('/admin/complaints/user-complaints')
        .then(response => response.data)
}

const deleteAppeals = (data) => {
    return axios
        .delete('/admin/complaints/user-complaint-delete', {data})
        .then(response => response.data)
}

export default {
    getAppeals,
    deleteAppeals
}