<template>
    <div>
        <!-- <span>
              <v-tooltip right variant="primary">
                <template v-slot:activator="{ on, attrs }">
                    <spzn v-on="on" v-bind="attrs">
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg">
                            <path fill="#2196f3" d="M13,9H11V7H13M13,17H11V11H13M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path>
                        </svg>
                    </spzn>
                </template>
                <span>Здесь вы можете сделать поиск тегов или добавить новый тег</span>
            </v-tooltip>
          </span> -->
          <strong>Теги: </strong>
        <div class="tags-wrapper">
          <v-chip
            v-for="(pt, index) of points_tags" 
            :key="index"   
            :close="!reodonly"
            @click:close="!reodonly && deleteTag(pt)"
            class="margin"
          >
              {{ pt }}
          </v-chip>
          <span v-if="!points_tags.length && !returned">Тегов пока нет</span>
          <br v-if="!points_tags.length && !returned" />
          <div v-if="!reodonly" class="add-tag">
            <br>
            <div>
              <v-combobox
                v-model="selectedTags"
                label="Добавить тег к треку"
                :items="listTags?.tags || []"
                item-text="tag" 
                item-value="id"
                clearable
                chips
                multiple
                @blur="returned && returnData()"
              />
            </div>
            <v-btn 
              color="#F35821" 
              class="color-white"
              @click="addTags"
            >+ Добавить</v-btn>
          </div>
        </div>
        <div class="v-divider my-5"></div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {trackActionTypes} from "@/store/modules/track";
import {tagsActionTypes} from '@/store/modules/tags';
export default {
    props: {
        reodonly: {
            type: Boolean,
            default: false,
        },
        returned: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selectedTags: [],
            points_tags: [],
        }
    },
    computed: {
        ...mapState({
            listTracks: state => state.track.trackInfo,
            listTags: state => state.tags.listTags
        }),
    },
    async mounted() {
        await this.loadTags()
        this.mapTags()
    },
    methods: {
        addTags() {
            if (this.returned) return this.returnData()
            
            // из всех тегов производим поиск выбранных тегов по id
            const newTags = this.selectedTags.map(({tag}) => tag)
            const newTagsId = this.selectedTags.map(({id}) => id)
                
            if (this.selectedTags.length) {
                this.$store.dispatch(trackActionTypes.updateTags, {
                    track_id: this.$route.params.id,
                    tags: newTagsId.join(',')
                }).then(
                () => {
                    // добавляем новый теги, при этом чтоб теги не повторялись делаем массив уникальных значений
                    const uniqTags = [...new Set([...this.points_tags, ...newTags])]
                    this.points_tags = uniqTags
                })
                this.selectedTags = []
            }
        },
        returnData() {
            setTimeout(() => {
                const newTags = []
                this.selectedTags.forEach(tag => {
                    if (typeof tag === 'string' && tag) {
                        newTags.push(tag)
                    } else {
                        newTags.push(tag.tag)
                    }
                })
                this.$emit('return-tags-id', newTags.join(','))
            },200)
        },
        deleteTag(name) {
            const tag = this.listTags.tags.find(tag => tag.tag === name)
            if (tag) {
                this.$store.dispatch(trackActionTypes.deleteTag, {
                    track_id: +this.$route.params.id,
                    tag_id: String(tag.id)
                }).then(
                    () => this.points_tags = this.points_tags.filter(tagName => tagName !== tag.tag)
                )
            }
        },
        mapTags() {
            this.points_tags = this.listTracks.track.flatMap(({points_tags}) => points_tags)
        },
        async loadTags() {
            await this.$store.dispatch(tagsActionTypes.getTags)
        },
    }
}
</script>

<style scoped>
.tags-wrapper {
    padding: 10px 0 0 0;
    -webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
.margin {
  margin: 5px;
}
.add-tag {
    display: flex; 
    align-items: center; 
    gap: 10px;
}
</style>