<template v-ref:errorModal>
  <div class="error-wrap" v-show="error" @click.self="$store.commit('clearError')">
    <div class="error-content">
      <div class="caption">
        <h3>Ошибка</h3>
        <svg @click="$store.commit('clearError')" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round"
          stroke-linejoin="round" class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <div class="message" v-html="error"></div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: "ErrorModal",
  computed: {
    ...mapState({
      error: state => state.error
    }),
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.error-wrap {
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .error-content {
    background: #fff;
    width: 500px;
    padding: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    .caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 10px;

      svg {
        cursor: pointer;
        background: $color-gray;
        border-radius: 50%;
        padding: 5px;
        transition: all .3s;

        &:hover {
          background: darken($color: #e5e5e5, $amount: 10%);
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 1px;
        width: 45%;
        background: rgba(0, 0, 0, 0.2);
      }

      h3 {
        margin: 0;
        text-transform: uppercase;
        color: $color-red;
        font-size: 22px;
      }
    }
  }
}
</style>