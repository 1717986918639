import reviewsAppApi from '@/api/reviews-app';

const state = {
    listReviewsApp: null,
    reviewsAppInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getReviewsAppStart: '[reviewsApp] Get reviewsApp start',
    getReviewsAppSuccess: '[reviewsApp] Get reviewsApp success',
    getReviewsAppFailure: '[reviewsApp] Get reviewsApp failure',
    
    getReviewsForTrackStart: '[reviewsForTrack] Get reviewsForTrack start',
    getReviewsForTrackSuccess: '[reviewsForTrack] Get reviewsForTrack success',
    getReviewsForTrackFailure: '[reviewsForTrack] Get reviewsForTrack failure',

    reviewsAppInfoStart: '[reviewsApp] Get reviewsApp info start',
    reviewsAppInfoSuccess: '[reviewsApp] Get reviewsApp info success',
    reviewsAppInfoFailure: '[reviewsApp] Get reviewsApp info failure',

    reviewsAppUpdateStart: '[reviewsApp] Update reviewsApp start',
    reviewsAppUpdateSuccess: '[reviewsApp] Update reviewsApp success',
    reviewsAppUpdateFailure: '[reviewsApp] Update reviewsApp failure',
    
    trackReviewsDeleteStart: '[reviewsApp] delete reviewsForTrack start',
    trackReviewsDeleteSuccess: '[reviewsApp] delete reviewsForTrack success',
    trackReviewsDeleteFailure: '[reviewsApp] delete reviewsForTrack failure',
    
    reviewsDeleteStart: '[reviewsApp] delete reviews',
    reviewsDeleteSuccess: '[reviewsApp] delete reviews success',
    reviewsDeleteFailure: '[reviewsApp] delete reviews failure',

}

export const reviewsAppActionTypes = {
    getReviewsApp: '[reviewsApp] Get reviewsApp',
    getReviewsForTrack: '[reviewsForTrack] Get reviewsForTrack',
    reviewsAppInfo: '[reviewsApp] Get reviewsApp info',
    reviewsAppUpdate: '[reviewsApp] Update reviewsApp',
    trackReviewsDelete: '[reviewsApp] delete reviewsForTrack',
    reviewsDelete: '[reviewsApp] delete reviews',
}

const mutations = {
    [mutationTypes.getReviewsAppStart](state) {
        state.isLoading = true
        state.listReviewsApp = null
    },
    [mutationTypes.getReviewsAppSuccess](state, payload) {
        state.isLoading = false
        state.listReviewsApp = payload
        state.validationErrors = null
    },
    [mutationTypes.getReviewsAppFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
    
    [mutationTypes.getReviewsForTrackStart](state) {
        state.isLoading = true
        state.reviewsAppInfo = null
    },
    [mutationTypes.getReviewsForTrackSuccess](state, payload) {
        state.isLoading = false
        state.reviewsAppInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.getReviewsForTrackFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.reviewsAppInfoStart](state) {
        state.isLoading = true
        state.reviewsAppInfo = null
    },
    [mutationTypes.reviewsAppInfoSuccess](state, payload) {
        state.isLoading = false
        state.reviewsAppInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsAppInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.reviewsAppUpdateStart](state) {
        state.isLoading = true
        state.reviewsAppInfo = null
    },
    [mutationTypes.reviewsAppUpdateSuccess](state, payload) {
        state.isLoading = false
        state.reviewsAppInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsAppUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
    
    [mutationTypes.trackReviewsDeleteStart](state) {
        state.isLoading = true
        state.reviewsAppInfo = null
    },
    [mutationTypes.trackReviewsDeleteSuccess](state, payload) {
        state.isLoading = false
        state.reviewsAppInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.trackReviewsDeleteFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
    
    [mutationTypes.reviewsDeleteStart](state) {
        state.isLoading = true
        state.reviewsAppInfo = null
    },
    [mutationTypes.reviewsDeleteSuccess](state, payload) {
        state.isLoading = false
        state.reviewsAppInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsDeleteFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [reviewsAppActionTypes.getReviewsApp](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getReviewsAppStart)
            reviewsAppApi
                .getReviewsApp(data)
                .then(response => {
                    context.commit(mutationTypes.getReviewsAppSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getReviewsAppFailure, response)
                })
        })
    },
    [reviewsAppActionTypes.getReviewsForTrack](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getReviewsForTrackStart)
            reviewsAppApi
                .getReviewsForTrack(data)
                .then(response => {
                    context.commit(mutationTypes.getReviewsForTrackSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getReviewsForTrackFailure, response)
                })
        })
    },
    [reviewsAppActionTypes.reviewsAppInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.reviewsAppInfoStart)
            reviewsAppApi
                .updateReviewsApp(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsAppInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsAppInfoFailure, response)
                })
        })
    },
    [reviewsAppActionTypes.reviewsAppUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.reviewsAppUpdateStart)
            reviewsAppApi
                .updateReviewsApp(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsAppUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsAppUpdateFailure, response)
                })
        })
    },
    [reviewsAppActionTypes.trackReviewsDelete](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.trackReviewsDeleteStart)
            reviewsAppApi
                .trackReviewsDelete(data)
                .then(response => {
                    context.commit(mutationTypes.trackReviewsDeleteSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.trackReviewsDeleteFailure, response)
                })
        })
    },
    
    [reviewsAppActionTypes.reviewsDelete](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.reviewsDeleteStart)
            reviewsAppApi
                .reviewsDelete(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsDeleteSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsDeleteFailure, response)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
}