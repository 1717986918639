<template>
    <div class="table-scroll">
        <table :class="customClass">
            <thead>
                <slot name="head"></slot>
            </thead>
            <tbody>
                <slot name="body"></slot>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: "Ctable",
    props: {
        customClass: {
            typeof: String,
            default: ''
        }
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.table-scroll {
    overflow-x: scroll;

    table {
        border-spacing: 0;
        min-width: 100%;

        thead {
            background: $color-gray;
            padding: 10px 15px;
            border-bottom: 1px solid #e2e2e2;
            font-weight: 700;
            left: 0;

            th {
                background: $color-gray;
                padding: 10px 5px;
                white-space: nowrap;
                text-align: left;
            }
        }

        tbody {
            tr {
                td {
                    padding: 10px 5px;
                    background: #fff;
                    border-bottom: 1px solid #e2e2e2;
                    transition: all 0.3s;
                }

                &:hover {
                    td {
                        background: #f5f6f7;
                    }
                }
            }
        }
    }
}
</style>