import axios from '@/api/axios'

const getBanners = (data) => {
    return axios
        .post('/admin/banner', data)
        .then(response => response.data)
}

const createBanner = (data) => {
    return axios
        .post('/admin/banner/create', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
}

const updateBanner = (data) => {
    return axios
        .post('/admin/banner/update', { banner: data})
        .then(response => response.data)
}

const deleteBanner = (data) => {
    return axios
        .post('/admin/banner/delete', { banner: data})
        .then(response => response.data)
}

const saveSort = (data) => {
    return axios
        .post('/admin/banner/save-sort', data)
        .then(response => response.data)
}

export default {
    getBanners,
    createBanner,
    updateBanner,
    deleteBanner,
    saveSort
}
