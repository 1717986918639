<template>
  <div class="order-page">
    <div v-if="isLoading" class="overlay">
      <div class="loader">
        Loading...
      </div>
    </div>
    <Heading level="2" :text="`Трек`" :backButton="true"/>
    <Grid :column="2" :gap="12">
      <ContentBlock style="    min-height: 400px;" >
        <div class="order-info">
          <div class="track-map-modal" style="height: 350px;max-width: 700px">
            <template>
              <div id="app">
                <yandex-map
                    :settings="settings"
                    :coords="coords"
                    :zoom="10"
                    @click="addBalloon($event)"
                    ref="yandexMap"
                >
                  <ymap-marker
                      v-for="(balloon, index) in balloons"
                      :marker-id='index'
                      :key="index"
                      :coords="balloon.coords"
                      :draggable="true"
                      @dragend="dragendHandler($event, index)"
                      :options="getMarkerOptions(index, balloon)"
                      @click="toggleBalloon($event, balloon, index)"
                      @contextmenu="contextmenuHandler(balloon.coords, index + 1)"
                  />
                  <Grid :column="3" :gap="5">
                  <v-btn block color="#F35821"  class="color-white"   @click="calculateRoute">
                    Построить маршрут
                  </v-btn>
                  <v-btn block color="#F35821"  class="color-white"   @click="routeResetHandler">
                    Сбросить маршрут
                  </v-btn>
                  <v-btn block color="#F35821"  class="color-white"   @click="routeClearHandler">
                    Удалить маршрут
                  </v-btn>
                  </Grid>
                </yandex-map>
              </div>
            </template>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock >
         <div class="order-info__item" style="flex-direction: column;">
          <span>Название трека:</span>
          <div style="width:100%;"> 
            <v-text-field v-model="form.trackName" required></v-text-field>
          </div>
        </div>
        <div class="order-info__item">
          <span>Дистанция (км):</span>
          <div> 
            <v-text-field 
              v-model="distance" 
              type="number"
              required
            />
          </div>
        </div>
        <div class="order-info__item">
          <span>Время трека:</span>
          <div> <v-text-field v-model="form.track_time" @keyup="changeTime($event)" required></v-text-field></div>
        </div>
        <div class="order-info__item">
          <span>Тип трека:</span>
          <div>
            <v-select 
              v-model="form.track_type"
              item-text="text" 
              item-value="value"
              :items="statusList" 
              label="Выберите тип"
              class="select"
            />
          </div>
        </div>
        <div class="order-info__item">
          <span>Уровень сложности:</span>
          <div>
            <v-select 
              v-model="form.complexity"
              item-text="text" 
              item-value="value"
              :items="complexityList" 
              label="Выберите сложность"
              class="select"
            />
          </div>
        </div>
        <div class="choice">
          <strong>Выбор редакции:</strong>
          <v-checkbox
            class="checkbox"
            v-model="is_choice"
            color="red"
            hide-details
          />
        </div>
      </ContentBlock>
    </Grid>
    <Grid :column="1" :gap="12" class="mt-5">
    <ContentBlock >
      <TagSection v-if="listTracks?.track?.length" />
      <ImgMediaSection 
        v-if="imagesSrcPoint.length || imagesSrcMedia.length" 
        :imagesSrcPoint="imagesSrcPoint" 
        :imagesSrcMedia="imagesSrcMedia" 
        @deleteMedia="deleteMedia($event)"
        class="order-info__item" 
        style="flex-direction: column;" 
      />
      <VideoMediaSection 
        v-if="videoSrcPoint.length || videoSrcMedia.length" 
        :videoSrcPoint="videoSrcPoint"
        :videoSrcMedia="videoSrcMedia"
        @deleteMedia="deleteMedia($event)"
        class="order-info__item" 
        style="flex-direction: column;" 
      />
      <div class="order-info__item" style="flex-direction: column;">
        <span>Описание трека:</span>
        <div style="width:100%;">
          <v-textarea auto-grow  rows="2" v-model="form.trackDescription" ></v-textarea>
        </div>
      </div>
      <div class="order-info__item" style="flex-direction: column;">
          <span>Адрес:</span>
          <div style="width: 100%;"> <v-textarea  rows="2" v-model="form.track_address" required></v-textarea></div>
      </div>
      <div class="v-divider"></div>
      <v-btn block color="#F35821" large class="color-white" @click="submitForm">
        Сохранить
      </v-btn>
    </ContentBlock>
    </Grid>
    <Modal ref="confirm">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        {{ confirmData.body }}
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.confirm._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="$refs.confirm._confirm()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
    <v-dialog v-model="showModal" max-width="800px"  class="dialog">
      <div class="modal-content" style="background-color: white">
        <Grid :column="2" :gap="12">
          <ContentBlock>
            <v-card>
              <v-card-title>
                Загрузка файла
              </v-card-title>
              <v-card-text>
                <div>
                  <input type="file" ref="tus_file" name="tus_file" @change="handleFileChange" />
                </div>
                <div class="buttons-group mt-3">
                  <v-btn large color="#F35821" class="color-white"
                      @click="uploadFile"
                    >
                    Загрузить
                  </v-btn>
                  <div v-if="isLoading">Загрузка файла...</div>
                  <div v-if="errorMessage">{{ errorMessage }}</div>
                  <v-btn plain large class="btn-cancel" @click="closeModal()">
                    Отменить
                  </v-btn>
                </div>
                <div class="buttons-group mt-3">
                  <v-btn large color="red" class="color-white" @click="deleteBalloon">
                    Удалить точку маршрута
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </ContentBlock >
          <ContentBlock class="align-content-center">
            <v-card v-if="media">
              <v-card-text>
                <a :href="toFile()" target="_blank">
                  Посмотреть фаил
                </a>
              </v-card-text>
            </v-card>
          </ContentBlock>
        </Grid>
      </div>
    </v-dialog>
    <v-snackbar
        v-model="isSuccessLoadFile"
        color="success"
        top
        right
        :timeout="2000"
      >
        Файл успешно загружен
    </v-snackbar>
    <v-snackbar
      v-model="isErrorBuildRoutes"
      color="error"
      top
      right
      :timeout="2000"
    >
      Ошибка при построении маршрута
    </v-snackbar>
  </div>
</template>

<script>
import {globalMethods} from "@/mixins/globalMethods";
import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'
import {mapState} from "vuex";
import {trackActionTypes} from "@/store/modules/track";
import apiConfig from "@/config/config.json";
import axios from '@/api/axios'
import TagSection from '@/components/TagSection.vue'
import ImgMediaSection from '@/components/ImgMediaSection.vue'
import VideoMediaSection from '@/components/VideoMediaSection.vue'

export default {
  name: 'Order-page',
  mixins: [globalMethods],
  components: { yandexMap,ymapMarker, TagSection, ImgMediaSection, VideoMediaSection },
  data() {
    return {
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Удалить',
      },
      routingModeButton: null,
      routingMode: 'pedestrian',
      distance: 0,
      is_choice: false,
      ymap:null,
      latitude:null,
      longitude:null,
      isLoading: true,
      errorMessage: '',
      isSuccessLoadFile: false,
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [], // maintain an upload queue
      showModal: false,
      selectedImage: null,
      media:null,
      isErrorBuildRoutes: false,
      medialat:null,
      medialon:null,
      uploadedImageUrl: null,
      selectedBalloonCoords: null,
      isBalloonOpen: false,
      imagesSrcPoint: [],
      imagesSrcMedia: [],
      videoSrcPoint: [],
      videoSrcMedia: [],
      multiRoutes: [],
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: 'https://image.flaticon.com/icons/png/512/33/33447.png',
        imageSize: [43, 43],
        imageOffset: [0, 0],
        contentOffset: [0, 15],
        balloonContentHeader: "Балун метки",
        balloonContentBody: "Содержимое <em>балуна</em> метки",
        balloonContentFooter: "Подвал",
      },
      settings:{
        apiKey: '3fc29144-38c4-4744-8e15-c26afc359bd1',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      form:{
        id:null,
        trackName:null,
        trackDescription:null,
        track_address: null,
        track_distance: null,
        track_time:null,
        track_type:null,
        complexity: null,
        coord:[]
      },
      city: null,
      coords: [54.82896654088406, 39.831893822753904],
      layout: '<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>',
      orderData: {},
      balloons: [],
      isBuildedRoutes: false,
      coordinates: [],
      statusList: [
        { text: 'Бег', value: 'run' },
        { text: 'Ходьба', value: 'walk' },
        { text: 'Велосипед', value: 'bike' },
      ],
      complexityList: [
        { text: 'Начинающий', value: 0},
        { text: 'Средний', value: 1 },
        { text: 'Продвинутый', value: 2 },
      ],
    }
  },
  computed: {
    balloonTemplate(index) {
      return `

      `
    },
    mask() {
      if (this.form.track_distance[1] === '.') return ['#.###']
      else return ['##.###']
    },
    ...mapState({
      isSubmitting: state => state.loan.isSubmitting,
      validationErrors: state => state.loan.validationErrors,
      listTracks: state => state.track.trackInfo,
    }),
  },
  async mounted() {
    this.locationCenter()
    await loadYmap(this.settings)
    await this.loadOrder()
    this.ymap = ymaps;
    setTimeout(async () => {
      await this.calculateRoute()
      this.getAllMediaSrc()
      const btn = new this.ymap.control.Button({
        data: {content: 'пешком'},
        options: {selectOnClick: true, maxWidth: 200},
      })
      this.routingModeButton = btn
      this.ymap.ready(() => this.$refs.yandexMap.myMap.controls.add(btn))
      this.initChangeRouteEvents()
    }, 3000);
  },
  watch: {
    loanInfo() {
     // console.log(this.loanInfo);
    }
  },
  methods: {
    contextmenuHandler(coords, num) {
        const html = `
          <h4>Точка №${num}</h4>
          <span>lat: ${coords[0]}</span><br/>
          <span>lon: ${coords[1]}</span>
        `
        this.$refs.yandexMap.myMap.balloon.open(coords, html)
    },
    routeResetHandler() {
      this.$set(this.confirmData, 'body', `Сбросить маршрут?`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.calculateRouteClear()
        }
      })
    },
    routeClearHandler() {
      this.$set(this.confirmData, 'body', `Сбросить маршрут и удалить все точки?`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.clearRoutesAndPoints()
        }
      })
    },
    deleteBalloon() {
      if (this.selectedBalloonCoords !== null) {
        // Удалите балун из массива balloons по индексу
        this.balloons.splice(this.selectedBalloonCoords, 1);
        this.coordinates.splice(this.selectedBalloonCoords, 1);
        // Закройте модальное окно
        this.closeModal();
        // Сбросьте значение индекса
        this.selectedBalloonCoords = null;
        if (this.isBuildedRoutes) {
          this.calculateRouteClear()
          this.calculateRoute()
        }
      }
    },
    dragendHandler(e, index) {
      this.balloons[index] = {coords:  e.originalEvent.target.geometry._bounds[0]}
      if (this.isBuildedRoutes) {
        this.calculateRouteClear()
        this.calculateRoute()
      }
    },
    deleteMedia(image_name) {
      this.$store.dispatch(trackActionTypes.deleteMedia, {
        track_id: this.$route.params.id,
        image_name
      }).then (
        () => { 
          this.imagesSrcPoint = this.imagesSrcPoint.filter(({filename}) => filename !== image_name)
          this.videoSrcPoint = this.videoSrcPoint.filter(({filename}) => filename !== image_name)
          this.imagesSrcMedia = this.imagesSrcMedia.filter(({filename}) => filename !== image_name)
          this.videoSrcMedia = this.videoSrcMedia.filter(({filename}) => filename !== image_name)
        }
      )
    },
    checkTypeFile(ext) {
        const imgExt = ['png', 'jpeg', 'gif', 'jpg', 'tiff', 'bmp', 'psd']
        const videoExt = ['avi', 'mp4', 'mov', 'mkv']

        if (imgExt.includes(ext.toLowerCase())) return 'img' 
        else if (videoExt.includes(ext.toLowerCase())) return 'video'
        else return null
    },
    getAllMediaSrc() {
      this.balloons.forEach(coor => {
        if (coor.media) {
          const ext = coor.media.split('.')[1]
          const filetype = this.checkTypeFile(ext)
          const file = {
              fullpath: `${apiConfig.apiHost}/images/${coor.media}`,
              filename: coor.media
          }
          
          if ( filetype === 'img') this.imagesSrcPoint.push(file)
          else if (filetype === 'video') this.videoSrcPoint.push(file)
        }
      })
      this.listTracks.track[0].media.forEach(med => {
        if (med.name) {
          const ext = med.name.split('.')[1]
          const filetype = this.checkTypeFile(ext)
          const file = {
              fullpath: `${apiConfig.apiHost}/images/${med.name}`,
              filename: med.name
          }
          
          if ( filetype === 'img') this.imagesSrcMedia.push(file)
          else if (filetype === 'video') this.videoSrcMedia.push(file)
        }
      })
    },
    getMarkerOptions(index, coordinat) {
      if (index === 0) {
        return { preset: 'islands#blueHomeCircleIcon', draggable: true};
      } else if (coordinat.media != null) {
        return { preset: 'islands#blueVideoCircleIcon', draggable: true,};
      } else {
        return { preset: 'islands#blueCircleIcon', draggable: true,};
      }
    },
    onMapInitialized() {
      this.calculateRoute();
    },
    calculateRouteClear(){
      this.distance = 0
      // сброс маршрута
      this.isBuildedRoutes = false
      this.multiRoutes.forEach(multiRoute => {
        this.$refs.yandexMap.myMap.geoObjects.remove(multiRoute)
      })
    },
    clearRoutesAndPoints() {
      this.balloons = []
      this.coordinates = []
      this.distance = 0
      this.$refs.yandexMap.myMap.geoObjects.removeAll();
    },
    initChangeRouteEvents() {
      this.routingModeButton.events.add('select', (e) => {
        this.routingMode = 'bicycle'
        this.calculateRouteClear()
        this.calculateRoute()
        e.originalEvent.target.data._data.content = 'велосипед'
      })
        
      this.routingModeButton.events.add('deselect', (e) => {
        this.routingMode = 'pedestrian'
        this.calculateRouteClear()
        this.calculateRoute()
        e.originalEvent.target.data._data.content = 'пешком'
      })
    },
    buildMultiRoute(waypoints = []) {
      // Создаем объект маршрутизации
      return new this.ymap.multiRouter.MultiRoute(
          {
            referencePoints: waypoints,
            params: {routingMode: this.routingMode},
          },
          {
            // Опции маршрута
            boundsAutoApply: true,
            editorDrawOver: false,
            wayPointDraggable: true,
            viaPointDraggable: false,
            wayPointVisible: false,
            // Задаем собственное оформление линий мультимаршрута.
            routeActiveStrokeColor: "ff0000",
            pinIconFillColor: "ff0000",
            
            routeStrokeStyle: 'dot',
            routeStrokeColor: "ff0000",
            routeStrokeWidth: 4,

            routeActiveBicycleSegmentStrokeStyle: "dot",
            routeActiveBicycleSegmentStrokeColor: "ff0000",

            dragUpdateInterval:10000,
            preventDragUpdate:true,
            routeActiveMarkerVisible: false,
          }
      )
    },
    calculateRoute() {
      console.log('calculateRoute')
      if (!this.ymap) {
        console.log('calculateRoute 2')
        return;
      }

      this.calculateRouteClear()
      // Создаем массив координат маркеров для прокладывания маршрута
      const waypoints = this.balloons.map(balloon => balloon.coords);
      this.multiRoutes.push(this.buildMultiRoute(waypoints.slice(0, 20)))

      for (let i = 20; i < 200; i += 20) {
          this.multiRoutes.push(this.buildMultiRoute(waypoints.slice(i - 1, i + 20)))
      }

      let distance = 0

      this.multiRoutes.forEach(route => {
        route.model.events.once("requestsuccess", () => {
          const propertiesRoutes = route.getRoutes().get(0)?.properties
          if (!propertiesRoutes) return this.isErrorBuildRoutes = true
          
            const distanceText = propertiesRoutes.get('distance').text
            let distanceNumber = 0
            const match = distanceText.match(/(\d+(?:,\d+)?)\s*(\S*)/)
            if (!distanceText.includes('км') && match) 
              distanceNumber = parseFloat(match[1].replace(',', '.')) / 1000
            else if (match) 
              distanceNumber = parseFloat(match[1].replace(',', '.'))           
            distance += distanceNumber

            for(let i=0; i < route.getWayPoints().getLength(); i++) {
              const properties = route.getWayPoints().get(i)?.properties
              if (!properties) return this.isErrorBuildRoutes = true
            }
            // Добавляем маршрут на карту
            this.$refs.yandexMap.myMap.geoObjects.add(route)            
            this.coordinates = waypoints

            const fractionalLength = String(distance).split('.')[1]?.length

            if (fractionalLength > 2)
              this.distance = distance.toFixed(2)      
            else
              this.distance = distance
            
            this.isBuildedRoutes = true  
          })
      })
    },
    generateUniqueFilename(originalFilename) {
      const uniqueId = Date.now();
      const fileExtension = originalFilename.split('.').pop();
      return `${uniqueId}.${fileExtension}`;
    },
    // apiConfig,
    toFile(){
      return apiConfig.apiHost+'/images/'+this.media
    },
    locationCenter(){
      navigator.geolocation.getCurrentPosition(function(position) {
        // Получаем координаты пользователя
        // this.latitude = position.coords.latitude;
        // this.longitude = position.coords.longitude;

        console.log(position.coords.longitude, position.coords.latitude)
      })
    },
    changeTime(e){
      if(e.target.value.split(':')[0].match(/[a-zA-Z]/g) || e.target.value.split(':')[1].match(/[a-zA-Z]/g) || e.target.value.split(':')[2].match(/[a-zA-Z]/g)){
        this.form.track_time = '00:00:00'
      }
      if(e.target.value.split(':')[0].match(/[а-я]/i) || e.target.value.split(':')[1].match(/[а-я]/i) || e.target.value.split(':')[2].match(/[а-я]/i)){
        this.form.track_time = '00:00:00'
      }
      if(e.target.value.split(':')[0].match(/[^a-z\d]+/i) || e.target.value.split(':')[1].match(/[^a-z\d]+/i) || e.target.value.split(':')[2].match(/[^a-z\d]+/i)){
        this.form.track_time = '00:00:00'
      }
      
      if(+e.target.value == 0){
        e.target.value = '00'
      }
      var mmH = e.target.value.split(':')[0]
      var mmIn = e.target.value.split(':')[1]
      var mmS = e.target.value.split(':')[2]
      if(+mmH < 0){
        this.form.track_time = '00:'+e.target.value.split(':')[1]+':'+e.target.value.split(':')[2]
      }
      if(+mmIn > 60){
        this.form.track_time = e.target.value.split(':')[0]+':59:'+e.target.value.split(':')[2]
      }
      if(+mmIn < 0){
        this.form.track_time = e.target.value.split(':')[0]+':00:'+e.target.value.split(':')[2]
      }
      if(+mmS > 60){
        this.form.track_time = e.target.value.split(':')[0]+':'+e.target.value.split(':')[1]+':59'
      }
      if(+mmS < 0){
        this.form.track_time = e.target.value.split(':')[0]+':'+e.target.value.split(':')[1]+':00'
      }
    },
    async getAddressForCoords(coords) {
      if (!this.ymap) {
        return null;
      }
      const result = await this.ymap.geocode(coords)
      const firstGeoObject = result.geoObjects.get(0)
      return firstGeoObject.getAddressLine()
    },
    async getCity(coords) {
      if (!this.ymap) {
        return null;
      }
      const result = await this.ymap.geocode(coords)
      const firstGeoObject = result.geoObjects.get(0)
      this.city = firstGeoObject.getLocalities()[0] || null
    },
    addBalloon(event) {
      this.form.coord = []
      const coords = event.get('coords');
      this.balloons.push({coords})
      this.form.coord.push(coords)
      if(!this.form.track_address) this.form.track_address = this.getAddressForCoords(coords)
      if(!this.city) this.getCity(coords)
      setTimeout(() => {
        if (this.balloons.length === 1) {
          this.$refs.yandexMap.myMap.geoObjects.get(0).options.set('preset', 'islands#blueHomeCircleIcon');
        }
      }, 10)
    },
    toggleBalloon(e,coordinat, index) {
      this.coords = e.get('coords');
      this.form.coord=e.get('coords');
      this.medialat = coordinat.coords[0]
      this.medialon = coordinat.coords[1]
      this.media = coordinat.media
      this.selectedBalloonCoords = index
      this.openModal()
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    isFormValid() {
      for (const key in this.form) {
        if (this.form[key] === null || this.form[key] === '') {
          return false;
        }
      }
      return true;
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    uploadFile() {
      if (this.selectedFile) {
        this.isLoading = true;
        this.errorMessage = '';
        const uniqueFilename = this.generateUniqueFilename(this.selectedFile.name);
        let data = new FormData();
        data.append('tus_file', this.selectedFile,uniqueFilename);
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: apiConfig.apiHost+'/admin/track/media?lon='+this.medialon+'&lat='+this.medialat+'&track_id='+ this.$route.params.id+'&time='+this.form.track_time,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: data
        };
        axios.request(config)
            .then(async (res) => {
              this.showModal = false
              this.isLoading = true;
              await this.loadOrder()
              await this.locationCenter()
              this.isSuccessLoadFile = true

              const ext = res.data.name.split('.')[1]
              const filetype = this.checkTypeFile(ext)
  
              const file = {
                fullpath: `${apiConfig.apiHost}/images/${res.data.name}`,
                filename: res.data.name
              }
            
              if ( filetype === 'img') this.imagesSrcPoint.push(file)
              else if (filetype === 'video') this.videoSrcPoint.push(file)

            })
            .catch((error) => {
              console.log(error);
              this.showModal = false;
              this.errorMessage = 'Произошла ошибка при загрузке файла.';
            }).
            finally(() => {});
      } else {
        console.error('No file selected');
      }
    },
    submitForm() {
      this.form.id = this.$route.params.id
      if (this.isFormValid()) {
        this.$store.dispatch(trackActionTypes.pointCreate, {
          track_id: this.$route.params.id,
          name:this.form.trackName,
          trackDescription:this.form.trackDescription,
          address:this.form.track_address,
          city: this.city,
          data: this.coordinates,
          track_distance: this.distance.toString(),
          track_time:this.form.track_time,
          track_type:this.form.track_type,
          is_choice: this.is_choice ? 1 : 0 ,
          complexity: this.form.complexity,
        }).then((res)=>{
          console.log('res: ', res)
          this.$router.push('/main/track');
        }).catch((err) => console.log('err: ', err))
      }else {
        alert("Не все поля заполнены")
      }
    },
    async loadOrder() {
      this.$set(this, "waiteLoad", true);
      await this.$store.dispatch(trackActionTypes.trackInfo,{id: this.$route.params.id}).then(res=> {
        this.isLoading = false;
        for (const point of this.listTracks.track) {
          if(point.points_array.length>0)
            this.coords= [point.points_array[0].lat,point.points_array[0].lon]

          this.form.trackName = point.name
          this.form.trackDescription = point.description
          this.form.track_address = point.track_address
          this.form.track_distance = point.track_distance
          this.form.track_time = point.track_time
          this.form.track_type = point.track_type
          this.form.complexity = point.complexity
          this.is_choice = !!point.is_choice
          this.city = point.city
          
          for (const points_array of point.points_array) {
            this.balloons.push(
              {
                coords:[points_array.lat,points_array.lon],
                media:points_array.media?.name
              }
            );
          }
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";
.select {
  width: 195px;
}

.dialog {
  z-index: 20200;
}
.choice {
  display: flex;
  gap: 15px;
  align-items: center;
  .checkbox {
    margin-top: -4px;
    margin-bottom: 5px;
  }
}
.img-wrapper {
  margin-top: 15px;
  position: relative;
  box-sizing: border-box;
  margin: 20px;
  width: 150px;
  .close-icon {
    background: #FF5252;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    width: 29px;
    height: 29px;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    right: -15px;
    top: -15px;
    cursor: pointer;

    &:hover {
      background: #E57373;
    }

    & svg {
      fill: #fff;
    }
  }
}
.cu-pointer {
  cursor: pointer;
}
.my-5 {
  margin: 15px 0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Убедитесь, что loader находится впереди всех окон */
}

.loader {
  font-size: 18px;
  color: #333;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
#app {

  height: 100%;
}

.ymap-container {
  height: 100%;
}
.order-page {
  .order-info {
    padding-bottom: 15px;

    &__item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        padding-bottom: 3px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }

      span {
        font-weight: bold;
        width: 20%;
      }

      &.column {
        flex-direction: column;

        span {
          width: 100%;
        }
      }
    }
  }
}
</style>