import subjectsAppealsApi from '@/api/subjects-appeals';

const state = {
    listSubjectsAppeals: null,
    subjectAppealInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getSubjectsAppealsStart: '[subjectsAppeals] Get subjectsAppeals start',
    getSubjectsAppealsSuccess: '[subjectsAppeals] Get subjectsAppeals success',
    getSubjectsAppealsFailure: '[subjectsAppeals] Get subjectsAppeals failure',

    getSubjectAppealInfoStart: '[subjectAppeal] Get subjectAppeal info start',
    getSubjectAppealInfoSuccess: '[subjectAppeal] Get subjectAppeal info success',
    getSubjectAppealInfoFailure: '[subjectAppeal] Get subjectAppeal info failure',

    subjectAppealCreateStart: '[subjectAppeal] Create subjectAppeal start',
    subjectAppealCreateSuccess: '[subjectAppeal] Create subjectAppeal success',
    subjectAppealCreateFailure: '[subjectAppeal] Create subjectAppeal failure',

    subjectAppealUpdateStart: '[subjectAppeal] Update subjectAppeal start',
    subjectAppealUpdateSuccess: '[subjectAppeal] Update subjectAppeal success',
    subjectAppealUpdateFailure: '[subjectAppeal] Update subjectAppeal failure',

    subjectAppealRemoveStart: '[subjectAppeal] Remove subjectAppeal start',
    subjectAppealRemoveSuccess: '[subjectAppeal] Remove subjectAppeal success',
    subjectAppealRemoveFailure: '[subjectAppeal] Remove subjectAppeal failure'
}

export const subjectsAppealsActionTypes = {
    getSubjectsAppeals: '[subjectsAppeals] Get subjectsAppeals',
    getSubjectAppealInfo: '[subjectAppeal] Get subjectAppeal info',
    subjectAppealCreate: '[subjectAppeal] subjectAppealCreate subjectAppeal',
    subjectAppealUpdate: '[subjectAppeal] Update subjectAppeal',
    subjectAppealRemove: '[subjectAppeal] Remove subjectAppeal',
}

const mutations = {
    [mutationTypes.getSubjectsAppealsStart](state) {
        state.isLoading = true
        state.listSubjectsAppeals = null
    },
    [mutationTypes.getSubjectsAppealsSuccess](state, payload) {
        state.isLoading = false
        state.listSubjectsAppeals = payload
        state.validationErrors = null
    },
    [mutationTypes.getSubjectsAppealsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.getSubjectAppealInfoStart](state) {
        state.isLoading = true
        state.subjectAppealInfo = null
    },
    [mutationTypes.getSubjectAppealInfoSuccess](state, payload) {
        state.isLoading = false
        state.subjectAppealInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.getSubjectAppealInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.subjectAppealCreateStart](state) {
        state.isLoading = true
        state.subjectAppealInfo = null
    },
    [mutationTypes.subjectAppealCreateSuccess](state, payload) {
        state.isLoading = false
        state.subjectAppealInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.subjectAppealCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.subjectAppealUpdateStart](state) {
        state.isLoading = true
        state.subjectAppealInfo = null
    },
    [mutationTypes.subjectAppealUpdateSuccess](state, payload) {
        state.isLoading = false
        state.subjectAppealInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.subjectAppealUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.subjectAppealRemoveStart](state) {
        state.isLoading = true
        state.subjectAppealInfo = null
    },
    [mutationTypes.subjectAppealRemoveSuccess](state, payload) {
        state.isLoading = false
        state.subjectAppealInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.subjectAppealRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [subjectsAppealsActionTypes.getSubjectsAppeals](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getSubjectsAppealsStart)
            subjectsAppealsApi
                .getSubjectsAppeals(data)
                .then(response => {
                    context.commit(mutationTypes.getSubjectsAppealsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getSubjectsAppealsFailure, response)
                })
        })
    },
    [subjectsAppealsActionTypes.getSubjectAppealInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getSubjectAppealInfoStart)
            subjectsAppealsApi
                .subjectAppealInfo(data)
                .then(response => {
                    context.commit(mutationTypes.getSubjectAppealInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getSubjectAppealInfoFailure, response)
                })
        })
    },
    [subjectsAppealsActionTypes.subjectAppealCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.subjectAppealCreateStart)
            subjectsAppealsApi
                .subjectAppealCreate(data)
                .then(response => {
                    context.commit(mutationTypes.subjectAppealCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.subjectAppealCreateFailure, response)
                })
        })
    },
    [subjectsAppealsActionTypes.subjectAppealUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.subjectAppealUpdateStart)
            subjectsAppealsApi
                .subjectAppealUpdate(data)
                .then(response => {
                    context.commit(mutationTypes.subjectAppealUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.subjectAppealUpdateFailure, response)
                })
        })
    },
    [subjectsAppealsActionTypes.subjectAppealRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.subjectAppealRemoveStart)
            subjectsAppealsApi
                .deleteSubjectAppeal(data)
                .then(response => {
                    context.commit(mutationTypes.subjectAppealRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.subjectAppealRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}