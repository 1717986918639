import adminApi from '@/api/admin';

const state = {
    listAdmins: null,
    createdAdmin: null,
    updatedAdmin: null,
    deletedAdmin: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getListAdminStart: '[admins] Get list admins start',
    getListAdminSuccess: '[admins] Get list admins success',
    getListAdminFailure: '[admins] Get list admins failure',
    createAdminStart: '[admin] Create admin start',
    createAdminSuccess: '[admin] Create admin success',
    createAdminFailure: '[admin] Create admin failure',
    updateAdminStart: '[admin] Update admin start',
    updateAdminSuccess: '[admin] Update admin success',
    updateAdminFailure: '[admin] Update admin failure',
    deleteAdminStart: '[admin] Delete admin start',
    deleteAdminSuccess: '[admin] Delete admin success',
    deleteAdminFailure: '[admin] Delete admin failure',
}

export const adminsActionTypes = {
    getListAdmin: '[admins] Get list admins',
    createAdmin: '[admin] Create admin',
    updateAdmin: '[admin] Update admin',
    deleteAdmin: '[admin] Delete admin',
}

const mutations = {
    [mutationTypes.getListAdminStart](state) {
        state.isLoading = true
        state.listAdmins = null
    },
    [mutationTypes.getListAdminSuccess](state, payload) {
        state.isLoading = false
        state.listAdmins = payload
        state.validationErrors = null
    },
    [mutationTypes.getListAdminFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.createAdminStart](state) {
        state.isLoading = true
        state.createdAdmin = null
    },
    [mutationTypes.createAdminSuccess](state, payload) {
        state.isLoading = false
        state.createdAdmin = payload
        state.validationErrors = null
    },
    [mutationTypes.createAdminFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.updateAdminStart](state) {
        state.isLoading = true
        state.updatedAdmin = null
    },
    [mutationTypes.updateAdminSuccess](state, payload) {
        state.isLoading = false
        state.updatedAdmin = payload
        state.validationErrors = null
    },
    [mutationTypes.updateAdminFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.deleteAdminStart](state) {
        state.isLoading = true
        state.deletedAdmin = null
    },
    [mutationTypes.deleteAdminSuccess](state, payload) {
        state.isLoading = false
        state.deletedAdmin = payload
        state.validationErrors = null
    },
    [mutationTypes.deleteAdminFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [adminsActionTypes.getListAdmin](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getListAdminStart)
            adminApi
                .getListAdmin(data)
                .then(response => {
                    context.commit(mutationTypes.getListAdminSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getListAdminFailure,response)
                })
        })
    },
    [adminsActionTypes.createAdmin](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.createAdminStart)
            adminApi
                .createAdmin(data)
                .then(response => {
                    context.commit(mutationTypes.createAdminSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.createAdminFailure,response)
                })
        })
    },
    [adminsActionTypes.updateAdmin](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.updateAdminStart)
            adminApi
                .updateAdmin(data)
                .then(response => {
                    context.commit(mutationTypes.updateAdminSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.updateAdminFailure,response)
                })
        })
    },
    [adminsActionTypes.deleteAdmin](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.deleteAdminStart)
            adminApi
                .deleteAdmin(data)
                .then(response => {
                    context.commit(mutationTypes.deleteAdminSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.deleteAdminFailure,response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}