<template>
  <div class="orders-page">
    <Heading level="2" text="Теги"/>
    <Grid :column="1" :gap="10">

      <ContentBlock>
        <div class="data-controll">
          <router-link :to="`/main/subjects-appeals`" >Тематика обращений</router-link>
          <router-link :to="`/main/directory`" >Локации</router-link>
          <router-link :to="`/main/tags`">Теги</router-link>
          <router-link :to="`/main/ranks`">Звания</router-link>
          <router-link :to="`/main/privacy`">Юридическая информация</router-link>
          <router-link :to="`/main/legal`">Пользовательское соглашение</router-link>
          <router-link :to="`/main/agreements`">Политика конфиденциальности</router-link>
        </div>


      </ContentBlock>
      <ContentBlock>
        <router-link :to="`/main/tags/create`" >
          Добавить
        </router-link>
        <div class="filters-wrapper">
          <div class="search-wrapper">
            <input 
              type="text" 
              v-model="search" 
              @keyup="makeSearch()" 
              placeholder="Поиск..." 
              class="search-input" 
            />
            <div @click="makeSearch()" class="search">
              <SearchIcon/>
            </div>
          </div>
        </div>
      </ContentBlock>

      <ContentBlock>
        <Table v-if="listTags">
          <template v-slot:head>
            <tr>
              <th><input type="checkbox" class="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></th>
              <th>
                <div class="column-title" @click="makeSort('id')">
                  <SortDefaultIcon v-if="sortedState.id === 'default'"/>
                  <SortAscIcon v-if="sortedState.id === 'asc'"/>
                  <SortDescIcon v-if="sortedState.id === 'desc'"/>
                  <span>ID</span> 
                </div>
              </th>
              <th>
                <div class="column-title" @click="makeSort('tag')">
                  <SortDefaultIcon v-if="sortedState.tag === 'default'"/>
                  <SortAscIcon v-if="sortedState.tag === 'asc'"/>
                  <SortDescIcon v-if="sortedState.tag === 'desc'"/>
                  <span>Тег</span> 
                </div>
              </th>
              <th>
              </th>
              <th>
              </th>
            </tr>
          </template>
          <template v-slot:body >
            <tr v-for="(order) of pagedTracks" :key="`order-${order.id}`">
              <td><input type="checkbox" v-model="order.selected" @change="toggleSelection(order)"></td>
              <td>{{ order.id }}</td>
              <td>{{ order.tag }}</td>
              <td align="right" width="44">
                <router-link :to="`/main/tags/update/${order.id}`">
                  <Icon iconName="edit" width="24" height="24" stroke="#a6e71d"/>
                </router-link>
              </td>
              <td align="right" width="44">
                <button @click="removeOne(order.id)">
                  <Icon iconName="delete" width="24" height="24" stroke="#a6e71d"/>
                </button>
              </td>
            </tr>
          </template>
        </Table>
        <div class="buttons-group mt-5">
            <v-btn  
              :disabled="selectedTag.length === 0" 
              large 
              color="#F35821" 
              class="color-white" 
              @click="remove"  
            >
              Удалить
            </v-btn>
        </div> 
        <v-pagination
            v-model="filter.page"
            :length="totalPages"
            total-visible="7"
        ></v-pagination>
      </ContentBlock>
    </Grid>
    <Modal ref="confirm">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        {{ confirmData.body }}
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.confirm._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="$refs.confirm._confirm()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

const debounce = require("lodash.debounce");
import {globalMethods} from "@/mixins/globalMethods";
import {tagsActionTypes} from '@/store/modules/tags';
import {toLowerCaseTrim} from '@/helpers/utils'
import {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon} from '@/components/icons/index'

export default {
  name: 'Loans-page',
  components: {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon},
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      // listTags: state => state.tags.listTags
    }),
    pagedTracks() {
      const startIdx = (this.filter.page - 1) * this.filter.count;
      const endIdx = startIdx + this.filter.count;
      console.log('lt: ', this.listTags)
      return this.listTags.slice(startIdx, endIdx);
    },
    totalPages() {
      return Math.ceil(this.listTags.length / this.filter.count);
    },
  },
  data() {
    return {
      selectedValue: '',
      testData: [
        { id: 1, name: 'локация1' },
        { id: 2, name: 'локация2'},
        { id: 3, name: 'локация3' },
      ],
      newTag: '',
      queryTerm: '',
      isLoading: false,
      itemsBranches: [],
      orders: [],
      totalPageCount: 10,
      filter: {
        count: 10,
        page: 1,
      },
      statusList: {
        '0': 'В обработкe',
        '1': 'Займ выдан',
        '2': 'Отказ',
        '3': 'Архив',
      },
      countList: [10, 30, 50, 100],
      search: '',
      waiteLoad: false,
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Удалить',
      },
      listTags: [],
      listTagsDef: [],
      selectedTag: [],
      selectedAll: false,
      sortedState: {
        id: 'asc',
        tag: 'default',
      },
    }
  },
  watch: {
    // search(val) {
    //   if (val) {
    //     this.branchFind()
    //   }
    // },
    search() {
      this.setRouteParams()
    },
  },
  created() {
    this.branchFind = debounce(this.branchFind, 500);
  },
  mounted() {
    this.loadLoans()
  },
  methods: {
    makeSort(key) {
      const sortedAsNumberKey = ['id']
      const sortedAsStringKey = ['tag']
      Object.keys(this.sortedState).forEach(k => {
        if (key !== k) this.sortedState[k] = 'default'
      })
      if (sortedAsNumberKey.includes(key)) {
        switch(this.sortedState[key]) {
          case 'desc':
            this.listTags.sort((a, b) => +a[key] - +b[key])
            this.sortedState[key] = 'asc'
            break
          case 'asc':
            this.listTags.sort((a, b) => b.id - a.id)
            this.sortedState.id = 'desc'
            break
          default:
            this.listTags.sort((a, b) => +b[key] - +a[key])
            this.sortedState[key] = 'desc'
        }
      } else if (sortedAsStringKey.includes(key)) {
        switch(this.sortedState[key]) {
          case 'desc':
            this.listTags.sort((a, b) => [key] > b[key] ? 1 : -1)
            this.sortedState[key] = 'asc'
            break
          case 'asc':
            this.listTags.sort((a, b) => b.id - a.id)
            this.sortedState.id = 'desc'
            this.sortedState[key] = 'default'
            break
          default:
            this.listTags.sort((a, b) =>  b[key] > a[key] ? 1 : -1)
            this.sortedState[key] = 'desc'
        }
      }
    },
    makeSearch() {
        this.listTags = this.listTagsDef.filter((item) => {
        const keys = [
          'id', 
          'tag'
        ]
        if (keys.every(key => item[key] != null)) {
          const isMatchesSearchFilter = keys.some(key => 
            toLowerCaseTrim(item[key]).includes(toLowerCaseTrim(this.search))
          )
          return isMatchesSearchFilter
        }
      })
    },
    toggleSelectionAll() {
        if (this.selectedAll) {
          this.listTags.forEach(tags => {
              this.selectedTag.push(tags)
              tags.selected = true
          })
        } else {
          this.listTags.forEach(tags => {
              this.selectedTag = []
              tags.selected = false
          })
        }
      },
    toggleSelection(tag) {
        if (tag.selected) {
          this.selectedTag.push(tag);
        } else {
          const index = this.selectedTag.findIndex(selectedTag => selectedTag.id === tag.id);
          if (index !== -1) {
            this.selectedTag.splice(index, 1);
          }
        }
    },
    branchFind() {
      this.$set(this, "isLoading", true);
      this.sendRequest('GET', {term: this.search}, '/search_branch', {}, false, true)
          .then(data => {
            data = [
              {id: '1', name: 'Филиал 1'},
              {id: '2', name: 'Филиал 2'},
              {id: '3', name: 'Филиал 3'},
            ]

            this.$set(this, "itemsBranches", data);
            this.$set(this, "isLoading", false);
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    loadLoans() {
        this.$set(this, "waiteLoad", true);
        this.$store.dispatch(tagsActionTypes.getTags).then((res) => {
          this.listTags = res.tags
          this.listTags.forEach(tags => tags.selected = false)
          this.listTagsDef = [...this.listTags]
          this.checkSearchParams()
          this.makeSearch()
        })
    },
    removeOne(id) {
      this.$set(this.confirmData, 'header', `Удалить тег #${id}`);
      this.$set(this.confirmData, 'body', `Вы уверены что хотите удалить тег #${id}? После удаления вернуть данные будет не возможно!`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.$store.dispatch(tagsActionTypes.tagRemove, {
            id
          })
          .then(() => {
            this.loadLoans()
          })
        }
      })
    },
    remove() { 
      if (confirm('Вы уверены, что хотите удалить выбранные обращения?')) {
        const selectedIds = this.selectedTag.map(tag => tag.id);
        this.$store.dispatch(tagsActionTypes.tagRemove, { id: selectedIds}).then(() => {
          this.selectedTag = []; // Очистить выбранные записи
          this.loadLoans()
        });
      }
    },
    checkSearchParams() {
      const searchQuery = this.$route.query
      const paramsKeys = ['search']
      if (paramsKeys.some(key => searchQuery[key])) {
        this.search = decodeURIComponent(searchQuery.search)
      }
    },
    setRouteParams() {
      const search = `search=${this.search}`
      this.$router.push({path: `?${search}`})
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";
.column-title {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;

    & svg {
      cursor: pointer;
    }
  }
  .filters-wrapper {
    display: flex; 
    flex-direction: row-reverse;
    align-items: center; 
    justify-self: end; 
    gap: 20px;
    margin-bottom: 5px;
}
  .search-wrapper {
    display:flex; 
    justify-self: end;
  }

  .search-input {
    border-radius: 2px;
    height: 32px; 
    width: 200px; 
    padding: 10px;
    border: 1px solid #dfdfdf;
  }

  .search {
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 32px; 
    width: 37px; 
    background-color: #F32719;
  }

.orders-page {
  .filter {
    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        &.coll-2 {
          width: calc(50% - 3px);
        }

        &.coll-3 {
          width: calc(33% - 3px);
        }

        &.coll-4 {
          width: calc(25% - 3px);
        }
      }
    }

    .buttons-group {
      display: flex;
      justify-content: flex-end;
    }
  }

  .data-controll {

    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      margin-right: 10px;
      color: $color-orange;
    }
    .count {
      width: 85px;
    }

    .data-page {
      font-weight: bold;

      span {
        color: $color-orange;
      }
    }
  }

}
</style>