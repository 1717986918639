<template>
  <div class="order-page">
    <Heading level="2" :text="`Новая локация`" :backButton="true"/>
    <Grid :column="1" :gap="10">

      <ContentBlock >
        <div class="order-info__item">

          <span>Адрес:</span>

          <div> <v-text-field   v-model="form.track_address" @change="addressChange()" label="" required></v-text-field> </div>

        </div>
        <div class=" mb-5" ></div>
        <v-btn block color="#F35821" large class="color-white" :disabled="isSaveButtonDisabled"   @click="submitForm">
          Сохранить
        </v-btn>
      </ContentBlock>
      <ContentBlock >
        <div class="order-info">
          <div class="track-map-modal" style="height: 800px;width: 100%">
            <template>
              <div id="app">
                <yandex-map :coords="coords" :zoom="10" @click="handleMapClick($event)">


                </yandex-map>




              </div>
            </template>
          </div>

        </div>

      </ContentBlock>

    </Grid>


  </div>

</template>



<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import {locationsActionTypes} from "@/store/modules/locations";;

export default {
  name: 'Loans-page',
  components: {yandexMap},
  mixins: [globalMethods],
  computed: {
    isSaveButtonDisabled() {
      return !this.isFormValid();
    },
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      listLocations: state => state.locations.listLocations
    })
  },
  data() {
    return {
      apiKey:'3fc29144-38c4-4744-8e15-c26afc359bd1',
      coordinates: [],
      coords: [54.82896654088406, 39.831893822753904],
      coordsName:null,
      form:{
        track_address: null,
      },
      itemsBranches: [
        { id: '1', name: 'Локации' },
        { id: '2', name: 'Теги' },
        { id: '3', name: 'Звания' },
        { id: '4', name: 'Юридическая информация' },
        { id: '5', name: 'Пользовательское соглашение' },
        { id: '6', name: 'Политика конфиденциальности' },
      ],
      newLocation: {
        title: '',
        description: '',
        selectedValue: '',
        file: null
      }
    }
  },
  methods: {
    async addressChange(){
      this.form.track_address
      console.log(this.form.track_address)
      const response = await this.geocodeAddress(this.form.track_address);
      var resResp = response
      console.log(resResp)
      //this.selectedAddress = response.text;
    },
    async handleMapClick(event) {
      const coordinates = event.get('coords');
      const response = await this.geocodeCoordinates(coordinates);
      this.selectedAddress = response.text;
    },
    async geocodeAddress(address) {

      const response = await fetch(
        `https://api-maps.yandex.ru/services/search//v2/?apikey=${this.apiKey}&callback=id_169401484922636369354&text=${address}&format=json&rspn=0&lang=ru_RU&results=10&token=1057e88dd7f3294380c1e6f953a3e8b3&type=biz&properties=addressdetails&geocoder_sco=latlong&origin=jsapi2searchcontrol`, 
        {
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
          }
         }
        );
      //const response = await fetch(`https://search-maps.yandex.ru/v1/?text=${address}&type=geo&lang=ru_RU&apikey=${this.apiKey}`)
      
      const data = await response;
      // const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;
      // const [lat, lon] = geoObject.Point.pos.split(' ');
      // this.coordinates = {
      //   lat:lat,
      //   lon:lon
      // }
      // this.form.track_address = geoObject.description+ ' '+ geoObject.name
      return data;

      //console.log(data)

    },
    async geocodeCoordinates(coordinates) {
      const response = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${this.apiKey}&format=json&geocode=${coordinates[1]},${coordinates[0]}`);
      const data = await response.json();
      const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;
      const [lat, lon] = geoObject.Point.pos.split(' ');
      this.coordinates = {
        lat:lat,
        lon:lon
      }
      this.form.track_address = geoObject.description+ ' '+ geoObject.name

      console.log(geoObject)

    },
    isFormValid() {
      return this.form.track_address !== null && this.form.track_address !== '';
    },
    submitForm() {
      if (this.isFormValid()) {
        this.$store.dispatch(locationsActionTypes.locationCreate, {
          lat:this.coordinates.lat,
          lon:     this.coordinates.lon,
          address:   this.form.track_address
        }).then(()=>{
          this.$router.push('/main/directory');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },

    handleFileChange(event) {
      this.newLocation.file = event.target.files[0];
    },
  }
}
</script>

<style scoped lang="scss">
#app {

  height: 100%;
}
.ymap-container {
  height: 100%;
}
</style>
