import tagsApi from '@/api/tags';

const state = {
    listTags: null,
    tagInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getTagsStart: '[tags] Get tags start',
    getTagsSuccess: '[tags] Get tags success',
    getTagsFailure: '[tags] Get tags failure',

    getTagInfoStart: '[tag] Get tag info start',
    getTagInfoSuccess: '[tag] Get tag info success',
    getTagInfoFailure: '[tag] Get tag info failure',

    tagCreateStart: '[tag] Create tag start',
    tagCreateSuccess: '[tag] Create tag success',
    tagCreateFailure: '[tag] Create tag failure',

    tagUpdateStart: '[tag] Update tag start',
    tagUpdateSuccess: '[tag] Update tag success',
    tagUpdateFailure: '[tag] Update tag failure',

    tagRemoveStart: '[tag] Remove tag start',
    tagRemoveSuccess: '[tag] Remove tag success',
    tagRemoveFailure: '[tag] Remove tag failure'
}

export const tagsActionTypes = {
    getTags: '[tags] Get tags',
    getTagInfo: '[tag] Get tag info',
    tagCreate: '[tag] tagCreate tag',
    tagUpdate: '[tag] Update tag',
    tagRemove: '[tag] Remove tag',
}

const mutations = {
    [mutationTypes.getTagsStart](state) {
        state.isLoading = true
        state.listTags = null
    },
    [mutationTypes.getTagsSuccess](state, payload) {
        state.isLoading = false
        state.listTags = payload
        state.validationErrors = null
    },
    [mutationTypes.getTagsFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.getTagInfoStart](state) {
        state.isLoading = true
        state.tagInfo = null
    },
    [mutationTypes.getTagInfoSuccess](state, payload) {
        state.isLoading = false
        state.tagInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.getTagInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.tagCreateStart](state) {
        state.isLoading = true
        state.tagInfo = null
    },
    [mutationTypes.tagCreateSuccess](state, payload) {
        state.isLoading = false
        state.tagInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.tagCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.tagUpdateStart](state) {
        state.isLoading = true
        state.tagInfo = null
    },
    [mutationTypes.tagUpdateSuccess](state, payload) {
        state.isLoading = false
        state.tagInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.tagUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.tagRemoveStart](state) {
        state.isLoading = true
        state.tagInfo = null
    },
    [mutationTypes.tagRemoveSuccess](state, payload) {
        state.isLoading = false
        state.tagInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.tagRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [tagsActionTypes.getTags](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getTagsStart)
            tagsApi
                .getTags(data)
                .then(response => {
                    context.commit(mutationTypes.getTagsSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getTagsFailure, response)
                })
        })
    },
    [tagsActionTypes.getTagInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getTagInfoStart)
            tagsApi
                .tagInfo(data)
                .then(response => {
                    context.commit(mutationTypes.getTagInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getTagInfoFailure, response)
                })
        })
    },
    [tagsActionTypes.tagCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.tagCreateStart)
            tagsApi
                .tagCreate(data)
                .then(response => {
                    context.commit(mutationTypes.tagCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.tagCreateFailure, response)
                })
        })
    },
    [tagsActionTypes.tagUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.tagUpdateStart)
            tagsApi
                .tagUpdate(data)
                .then(response => {
                    context.commit(mutationTypes.tagUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.tagUpdateFailure, response)
                })
        })
    },
    [tagsActionTypes.tagRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.tagRemoveStart)
            tagsApi
                .deleteTag(data)
                .then(response => {
                    context.commit(mutationTypes.tagRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.tagRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}
