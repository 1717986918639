import axios from '@/api/axios'


const login = credentials => {
  return axios.post('https://kb.razrab.space/api/user/auth',  credentials)
}


export default {
  login
}
