import axios from '@/api/axios'

const getSubjectsAppeals = () => {
    return axios
        .get('/admin/complaints/')
        .then(response => response.data)
}

const subjectAppealInfo = (data) => {
    return axios
        .post('/admin/complaints', { complaint: data })
        .then(response => response.data)
}

const subjectAppealCreate = (data) => {
    return axios
        .post('/admin/complaints/create', { complaint: data })
        .then(response => response.data)
}

const subjectAppealUpdate = (data) => {
    return axios
        .post('/admin/complaints/update', { complaint: data })
        .then(response => response.data)
}

const deleteSubjectAppeal = (data) => {
    return axios
        .post('/admin/complaints/delete', { complaint: data })
        .then(response => response.data)
}

export default {
    getSubjectsAppeals,
    subjectAppealInfo,
    subjectAppealCreate,
    subjectAppealUpdate,
    deleteSubjectAppeal,
}
