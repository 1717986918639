import axios from '@/api/axios'

const getModeration = (data) => {
    return axios
        .post('/admin/moderation', { track: data })
        .then(response => response.data)
}

const updateModeration = (data) => {
    return axios
        .post('/admin/moderation/update', data)
        .then(response => response.data)
}

export default {
    getModeration,
    updateModeration,
}