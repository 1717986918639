<template>
  <div class="orders-page">
    <Heading level="2" text="Новый документ Политика конфиденциальности"/>
    <Grid :column="1" :gap="10">
      <ContentBlock >

        <div class="order-info__item">

          <span>Описание:</span>
          <div> <v-text-field v-model="newAgreement.text"  required></v-text-field></div>
        </div>

        <!-- <div class="order-info__item mt-5">


          <div>
            <input type="file" id="file" @change="handleFileChange">

          </div>

        </div> -->

        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm"
          >
            Сохранить
          </v-btn>



        </div>
      </ContentBlock>

    </Grid>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import  {agreementActionTypes} from '@/store/modules/agreement';

export default {
  name: 'CreateAgreement',
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      listAgreements: state => state.agreement.listAgreements
    })
  },
  data() {
    return {
      itemsBranches: [
        { id: '2', name: 'Теги' },
        { id: '3', name: 'Звания' },
        { id: '4', name: 'Юридическая информация' },
        { id: '5', name: 'Пользовательское соглашение' },
        { id: '6', name: 'Политика конфиденциальности' },
      ],
      newAgreement: {
        text: ''
      }
    }
  },

  methods: {
    isFormValid() {
      return this.newAgreement.text !== null && this.newAgreement.text !== '';
    },
    submitForm() {
      console.log(this.newAgreement)
      if (this.isFormValid()) {
        this.$store.dispatch(agreementActionTypes.agreementCreate, {
          text:this.newAgreement.text
        }).then(()=>{
          this.$router.push('/main/agreements');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
  }
}
</script>

<style scoped lang="scss">
/* ... ваши стили ... */
</style>
