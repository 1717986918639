import axios from '@/api/axios'

const getLegalList= () => {
    return axios
        .get('/admin/legal')
        .then(response => response.data)
}

const getLegalInfo = (data) => {
    return axios
        .post('/admin/legal', { info: data })
        .then(response => response.data)
}

const updateLegalInfo = (data) => {
    return axios
        .post('/admin/legal/update', { info: data })
        .then(response => response.data)
}

const createLegalInfo = (data) => {
    return axios
        .post('/admin/legal/create', { info: data })
        .then(response => response.data)
}

const deleteLegal= (data) => {
    return axios
        .post('/admin/legal/delete', data)
        .then(response => response.data)
}

export default {
    getLegalList,
    getLegalInfo,
    updateLegalInfo,
    createLegalInfo,
    deleteLegal
}
