<template>
    <div class="content-block" >
        <slot />
    </div>
</template>
<script>
export default {
    name: "content-block",
    props: {

    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.content-block {
    background: $color-white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, .1), 0 1px 3px 0 rgb(0, 0, 0, .08)
}
</style>