<template>
<div>
        <span v-if="imagesSrcPoint?.length" class="heading">Прикрепленные изображения к точкам:</span>
        <div v-if="imagesSrcPoint?.length" style="width:100%" >
            <v-row>
                <v-col
                    v-for="({fullpath, filename}, index) in imagesSrcPoint"
                    :key="filename"
                    cols="2"
                >
                    <div class="img-wrapper">
                    <div v-if="!reodonly" class="close-icon" @click="deleteHandler(filename)">
                        <CloseIcon/>
                    </div>
                    <v-img
                        :src="fullpath"
                        aspect-ratio="1"
                        class="grey lighten-2 cu-pointer"
                        width="150"
                        @click="overlay = true, activeIndex = index"
                    >
                        <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                            />
                        </v-row>
                        </template>
                    </v-img>
                    </div>
                </v-col>
                <v-overlay
                    v-model="overlay"
                    @click="overlay = false"
                >
                    <v-img
                        :src="imagesSrcPoint[activeIndex]?.fullpath"
                        aspect-ratio="1"
                        class="grey"
                        min-width="650"
                    />
                </v-overlay>
            </v-row>
        </div>
        <span v-if="imagesSrcMedia?.length" class="heading">Прикрепленные изображения к треку:</span>
        <div v-if="imagesSrcMedia?.length" style="width:100%" >
            <v-row>
                <v-col
                    v-for="({fullpath, filename}, index) in imagesSrcMedia"
                    :key="filename"
                    cols="2"
                >
                    <div class="img-wrapper">
                        <div v-if="!reodonly" class="close-icon" @click="deleteHandler(filename)">
                            <CloseIcon/>
                        </div>
                        <v-img
                            :src="fullpath"
                            aspect-ratio="1"
                            class="grey lighten-2 cu-pointer"
                            width="150"
                            @click="overlay = true, activeIndex = index"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                />
                            </v-row>
                            </template>
                        </v-img>
                    </div>
                </v-col>
                <v-overlay
                    v-model="overlay"
                    @click="overlay = false"
                >
                    <v-img
                        :src="imagesSrcMedia[activeIndex]?.fullpath"
                        aspect-ratio="1"
                        class="grey"
                        min-width="650"
                    />
                </v-overlay>
            </v-row>
        </div>
</div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue'
export default {
    components: { CloseIcon },
    props: {
        reodonly: {
            type: Boolean,
            default: false,
        },
        imagesSrcPoint: {
            type: Array,
            default: () => [],
        },
        imagesSrcMedia: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            activeIndex: null,
            overlay: false,
        }
    },
    methods: {
        deleteHandler(filename) {
            this.$emit('deleteMedia', filename)
        }
    }
}
</script>

<style scoped lang="scss">
.heading {
    font-weight: bold;
    width: 30%;
    font-size: 15px;
}
.img-wrapper {
  margin-top: 15px;
  position: relative;
  box-sizing: border-box;
  margin: 20px;
  width: 150px;
  .close-icon {
    background: #FF5252;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    width: 29px;
    height: 29px;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    right: -15px;
    top: -15px;
    cursor: pointer;

    &:hover {
      background: #E57373;
    }

    & svg {
      fill: #fff;
    }
  }
}
</style>