import loanApi from '@/api/loan';

const state = {
    listLoans: null,
    loanInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getLoansStart: '[loans] Get loans start',
    getLoansSuccess: '[loans] Get loans success',
    getLoansFailure: '[loans] Get loans failure',

    loanInfoStart: '[loan] Get loan info start',
    loanInfoSuccess: '[loan] Get loan info success',
    loanInfoFailure: '[loan] Get loan info failure'
}

export const loansActionTypes = {
    getLoans: '[loans] Get loans',
    loanInfo: '[loan] Get loan',
}

const mutations = {
    [mutationTypes.getLoansStart](state) {
        state.isLoading = true
        state.listLoans = null
    },
    [mutationTypes.getLoansSuccess](state, payload) {
        state.isLoading = false
        state.listLoans = payload
        state.validationErrors = null
    },
    [mutationTypes.getLoansFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.loanInfoStart](state) {
        state.isLoading = true
        state.loanInfo = null
    },
    [mutationTypes.loanInfoSuccess](state, payload) {
        state.isLoading = false
        state.loanInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.loanInfoFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [loansActionTypes.getLoans](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getLoansStart)
            loanApi
                .getLoans(data)
                .then(response => {
                    context.commit(mutationTypes.getLoansSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getLoansFailure,response)
                })
        })
    },
    [loansActionTypes.loanInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.loanInfoStart)
            loanApi
                .loanInfo(data)
                .then(response => {
                    context.commit(mutationTypes.loanInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.loanInfoFailure,response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}