<template>
    <div class="order-page">
      <div v-if="isLoading" class="overlay">
        <div class="loader">
          Loading...
        </div>
      </div>
      <Heading level="2" :text="`Отзыв на модерации`" :backButton="true"/>
      <Grid :column="2" :gap="12">
        <ContentBlock style="    min-height: 400px;" >
          <div class="order-info">
            <div class="track-map-modal" style="height: 350px;max-width: 700px">
              <template>
                <div id="app">
                  <yandex-map
                      :settings="settings"
                      :coords="coords"
                      :zoom="10"
                      ref="yandexMap"
                      @afterOpen="onMapInitialized"
                  >
                    <ymap-marker
                        v-for="(coordinat, index) in coordinates"
                        :key="index"
                        :coords="coordinat.coord"
                        :options="getMarkerOptions(index, coordinat)"
                    />
                  </yandex-map>
                </div>
              </template>
            </div>
          </div>
        </ContentBlock>
        <ContentBlock >
           <div class="order-info__item" style="flex-direction: column;">
            <span>Название трека:</span>
            <div style="width:100%;"> 
              <v-text-field :value="form.trackName" readonly ></v-text-field>
            </div>
          </div>
                <div class="order-info__item">
                  <span>Дистанция (км):</span>
                  <div> 
                    <v-text-field :value="form.track_distance" readonly></v-text-field>
                  </div>
                </div>
                <div  class="order-info__item">
                  <span>Время трека:</span>
                  <div> <v-text-field :value="form.track_time" readonly></v-text-field></div>
                </div>
                <div class="order-info__item">
                  <span>Тип трека:</span>
                  <div> <v-text-field :value="form.track_type | translate" readonly></v-text-field></div>
                </div>
          <!-- <div class="v-divider"></div> -->
        </ContentBlock>
      </Grid>
      <Grid :column="1" :gap="12" class="mt-5">
      <ContentBlock >
        <TagSection reodonly />
        <ImgMediaSection 
          v-if="imagesSrcPoint.length || imagesSrcPoint.length" 
          :imagesSrcPoint="imagesSrcPoint" 
          :imagesSrcMedia="imagesSrcMedia" 
          reodonly
          @deleteMedia="deleteMedia($event)"
          class="order-info__item" 
          style="flex-direction: column;" 
        />
        <VideoMediaSection 
          v-if="videoSrcPoint.length || videoSrcMedia.length" 
          :videoSrcPoint="videoSrcPoint"
          :videoSrcMedia="videoSrcMedia"
          reodonly
          @deleteMedia="deleteMedia($event)"
          class="order-info__item" 
          style="flex-direction: column;" 
        />
        <div class="order-info__item" style="flex-direction: column;">
          <span>Описание трека:</span>
          <div style="width:100%;"><v-textarea auto-grow  rows="2" :value="form.trackDescription" readonly></v-textarea></div>
        </div>
        <div class="order-info__item" style="flex-direction: column;">
          <span>Адрес:</span>
          <div style="width:100%;"> <v-textarea rows="2" readonly :value="form.track_address"></v-textarea></div>
        </div>
      </ContentBlock>
      <h3 class="moderation-info">Информация по модерации отзыва</h3>
      <ContentBlock>
        <div style="flex-direction: column;">
          <span>Текст отзыва:</span>
          <div style="width:100%;"><v-textarea auto-grow  rows="2" readonly :value="reviewsModerationInfo?.text"></v-textarea></div>
        </div>
        <V-row>
            <v-col cols="4">
                <div>
                  <i>Автор отзыва:</i>
                  <div> 
                    <v-text-field readonly :value="reviewsModerationInfo?.fo"></v-text-field>
                  </div>
                </div>
            </v-col>
            <v-col cols="4">
                <div>
                  <i>Дата обновления статуса отзыва:</i>
                  <div> <v-text-field readonly :value="reviewsModerationInfo?.moderation_time" ></v-text-field></div>
                </div>
            </v-col>
            <v-col cols="4">
                <div>
                  <i>Статус модерации:</i>
                  <div> 
                    <v-select 
                      :value="reviewsModerationInfo?.moderation_status"
                      item-text="text" 
                      item-value="value"
                      :items="statusModeration" 
                      label="Выберите тип"
                      @change="moderationSelectHandler"
                    />
                  </div>
                </div>
            </v-col>
          </V-row>
        <div v-if="reviewsModerationInfo?.moderation_status === 2" class="order-info__item" style="flex-direction: column;">
          <span>Причина отклонения: </span>
          <div style="width:100%;"> <v-textarea auto-grow  rows="2" v-model="reason"  required></v-textarea></div>
        </div>
        <v-btn block color="#F35821" large class="color-white" @click="submitForm">
        Сохранить
      </v-btn>
      </ContentBlock>
      </Grid>
      <v-snackbar
        v-model="isReasonEmty"
        color="red"
        top
        right
        :timeout="2000"
      >
        Заполните поле "Причина отклонения"
    </v-snackbar>
    </div>
  </template>
  
  <script>
  import {globalMethods} from "@/mixins/globalMethods";
  import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'
  import {mapState} from "vuex";
  import {trackActionTypes} from "@/store/modules/track";
  import {reviewsModerationActionTypes} from '@/store/modules/reviews-moderation';
  import apiConfig from "@/config/config.json";
  import TagSection from '@/components/TagSection.vue'
  import ImgMediaSection from '@/components/ImgMediaSection.vue'
  import VideoMediaSection from '@/components/VideoMediaSection.vue'

  export default {
    name: 'Order-page',
    mixins: [globalMethods],
    components: { yandexMap,ymapMarker, TagSection, ImgMediaSection, VideoMediaSection },
    data() {
      return {
        ymap:null,
        latitude:null,
        longitude:null,
        isLoading: true,
        errorMessage: '',
        isSuccessLoadFile: false,
        uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
        fileRecordsForUpload: [], // maintain an upload queue
        showModal: false,
        selectedImage: null,
        media:null,
        medialat:null,
        medialon:null,
        uploadedImageUrl: null,
        selectedBalloonCoords: null,
        isBalloonOpen: false,
        imagesSrcPoint: [],
        imagesSrcMedia: [],
        videoSrcPoint: [],
        videoSrcMedia: [],
        overlay: false,
        activeIndex: 0,
        markerIcon: {
          layout: 'default#imageWithContent',
          imageHref: 'https://image.flaticon.com/icons/png/512/33/33447.png',
          imageSize: [43, 43],
          imageOffset: [0, 0],
          content: '<svg fill="#ff5c5c" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" stroke="#ff5c5c"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.9359999999999999"></g><g id="SVGRepo_iconCarrier"> <title>dot-circle-line</title> <path d="M18,11a7,7,0,1,1-7,7,7,7,0,0,1,7-7" class="clr-i-outline clr-i-outline-path-1"></path><path d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" class="clr-i-outline clr-i-outline-path-2"></path> <rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect> </g></svg>',
          contentOffset: [0, 15],
          contentLayout: '<div style="width: 16px;">$[properties.iconContent]</div>'
        },
        settings:{
          apiKey: '3fc29144-38c4-4744-8e15-c26afc359bd1',
          lang: 'ru_RU',
          coordorder: 'latlong',
          enterprise: false,
          version: '2.1'
        },
        form:{
          id:null,
          trackName:null,
          trackDescription:null,
          track_address: null,
          track_distance:null,
          track_time:null,
          track_type:null,
        },
        coords: [54.82896654088406, 39.831893822753904],
        layout: '<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>',
        orderData: {},
        balloons: [],
        coordinates: [],
        statusList: [
          { text: 'Бег', value: 'run' },
          { text: 'Ходьба', value: 'walk' },
          { text: 'Велосипед', value: 'bike' },
        ],
        statusModeration: [
          { text: 'На модерации', value: 1 },
          { text: 'Отклонен', value: 2 },
          { text: 'Одобрен', value: 3 },
        ],
        reviewsModerationInfo: null,
        isReasonEmty: false,
        reason: null,
      }
    },
    computed: {
      balloonTemplate(index) {
        return `
  
        `
      },
      ...mapState({
        isSubmitting: state => state.loan.isSubmitting,
        validationErrors: state => state.loan.validationErrors,
        listTracks: state => state.track.trackInfo,
        listReviewsModeration: state => state.reviewsModeration.reviewsModerationInfo
      })
    },
    async mounted() {
      await this.loadModeration()
      this.loadOrder()
      this.locationCenter()
      await loadYmap(this.settings);
      this.ymap = ymaps;
      setTimeout(async () => {
        await this.calculateRoute();
        this.getAllMediaSrc()
      }, 3000);
    },
    watch: {
      loanInfo() {
       // console.log(this.loanInfo);
      }
    },
    methods: {
      moderationSelectHandler(e) {
        this.reviewsModerationInfo.moderation_status = e
      },
      checkTypeFile(ext) {
        const imgExt = ['png', 'jpeg', 'gif', 'jpg', 'tiff', 'bmp', 'psd']
        const videoExt = ['avi', 'mp4', 'mov', 'mkv']

        if (imgExt.includes(ext.toLowerCase())) return 'img' 
        else if (videoExt.includes(ext.toLowerCase())) return 'video'
        else return null
      },
      getAllMediaSrc() {
        this.coordinates.forEach(coor => {
          if (coor.media) {
            const ext = coor.media.split('.')[1]
            const filetype = this.checkTypeFile(ext)
            const file = {
                fullpath: `${apiConfig.apiHost}/images/${coor.media}`,
                filename: coor.media
            }
            
            if ( filetype === 'img') this.imagesSrcPoint.push(file)
            else if (filetype === 'video') this.videoSrcPoint.push(file)
          }
        })
        this.listTracks.track[0].media.forEach(med => {
          if (med.name) {
            const ext = med.name.split('.')[1]
            const filetype = this.checkTypeFile(ext)
            const file = {
                fullpath: `${apiConfig.apiHost}/images/${med.name}`,
                filename: med.name
            }
            
            if ( filetype === 'img') this.imagesSrcMedia.push(file)
            else if (filetype === 'video') this.videoSrcMedia.push(file)
          }
        })
      },
      getMarkerOptions(index, coordinat) {
        if (index === 0) {
          return { preset: 'islands#blueHomeCircleIcon' };
        } else if (coordinat.media != null) {
          return { preset: 'islands#blueVideoCircleIcon' };
        } else {
          return { preset: 'islands#blueCircleIcon' };
        }
      },
      onMapInitialized() {
        this.calculateRoute();
      },
      buildMultiRoute(waypoints = []) {
      // Создаем объект маршрутизации
      return new this.ymap.multiRouter.MultiRoute(
          {
            referencePoints: waypoints,
            params: {
              routingMode: 'pedestrian',
            },
          },
          {
            // Опции маршрута
            boundsAutoApply: true,
            editorDrawOver: false,
            wayPointDraggable: true,
            viaPointDraggable: false,
            wayPointVisible: false,
            // Задаем собственное оформление линий мультимаршрута.
            routeStrokeColor: "000088",
            routeActiveStrokeColor: "ff0000",
            pinIconFillColor: "ff0000",
            dragUpdateInterval:10000,
            preventDragUpdate:true,
            routeActiveMarkerVisible: false,
          }
      )
    },
    calculateRoute() {
      if (!this.ymap) {
        return;
      }

      // Создаем массив координат маркеров для прокладывания маршрута
      const waypoints = this.coordinates.map(balloon => balloon.coord);
      const multiRoutes = [this.buildMultiRoute(waypoints.slice(0, 20))]

      for (let i = 20; i < 200; i += 20) {
          multiRoutes.push(this.buildMultiRoute(waypoints.slice(i - 1, i + 20)))
      }

      const data =[]
      let distance = 0

      multiRoutes.forEach(route => {
        route.model.events.once("requestsuccess", () => {
          const distanceNumber = route.getRoutes()?.get(0)?.properties?.get('distance')?.text
            const match = distanceNumber.match(/(\d+(?:,\d+)?)\s*(\S*)/);
            if (match) {
              distance += parseFloat(match[1].replace(",", "."))
            }

            for(let i=0; i < route.getWayPoints().getLength(); i++) {
              const properties = route.getWayPoints()?.get(i)?.properties
              if (!properties) {
                console.log('Ошибка построения маршрута')
                return
              }
              const cord = [
                properties.get('coordinates')[1],
                properties.get('coordinates')[0]
              ]
  
              data.push(cord)
            }
            
            // Добавляем маршрут на карту
            this.$refs.yandexMap.myMap.geoObjects.add(route)           
          })
      })
      this.$refs.yandexMap.myMap.geoObjrects?.get(0).options.set('preset', 'islands#blueHomeCircleIcon');
    },
      apiConfig,
      locationCenter(){
        navigator.geolocation.getCurrentPosition(function(position) {
          // Получаем координаты пользователя
        //  this.latitude = position.coords.latitude;
         // this.longitude = position.coords.longitude;
        })
      },
      changeTime(e){
        if(e.target.value.split(':')[0].match(/[a-zA-Z]/g) || e.target.value.split(':')[1].match(/[a-zA-Z]/g) || e.target.value.split(':')[2].match(/[a-zA-Z]/g)){
          this.form.track_time = '00:00:00'
        }
        if(e.target.value.split(':')[0].match(/[а-я]/i) || e.target.value.split(':')[1].match(/[а-я]/i) || e.target.value.split(':')[2].match(/[а-я]/i)){
          this.form.track_time = '00:00:00'
        }
        if(e.target.value.split(':')[0].match(/[^a-z\d]+/i) || e.target.value.split(':')[1].match(/[^a-z\d]+/i) || e.target.value.split(':')[2].match(/[^a-z\d]+/i)){
          this.form.track_time = '00:00:00'
        }
        
        if(+e.target.value == 0){
          e.target.value = '00'
        }
        var mmH = e.target.value.split(':')[0]
        var mmIn = e.target.value.split(':')[1]
        var mmS = e.target.value.split(':')[2]
        if(+mmH < 0){
          this.form.track_time = '00:'+e.target.value.split(':')[1]+':'+e.target.value.split(':')[2]
        }
        if(+mmIn > 60){
          this.form.track_time = e.target.value.split(':')[0]+':59:'+e.target.value.split(':')[2]
        }
        if(+mmIn < 0){
          this.form.track_time = e.target.value.split(':')[0]+':00:'+e.target.value.split(':')[2]
        }
        if(+mmS > 60){
          this.form.track_time = e.target.value.split(':')[0]+':'+e.target.value.split(':')[1]+':59'
        }
        if(+mmS < 0){
          this.form.track_time = e.target.value.split(':')[0]+':'+e.target.value.split(':')[1]+':00'
        }
      },
      submitForm() {
          if (!this.reason && this.reviewsModerationInfo.moderation_status === 2) {
            this.isReasonEmty = true
            return
          }
          this.$store.dispatch(reviewsModerationActionTypes.reviewsModerationUpdate, {
            reviews_id: this.$route.params.id,
            status: this.reviewsModerationInfo.moderation_status,    // 1 - На модерации, 2 - отклонен, 3 -опубликован
            description: this.reason || null
          }).then(()=>{
            this.$router.push('/main/reviews-moderation');
          })
      },
      async loadModeration() {
        this.$set(this, "waiteLoad", true);
        await this.$store.dispatch(reviewsModerationActionTypes.getReviewsModeration).then(result => {
          this.reviewsModerationInfo = result?.track?.find(reviewsModeration => reviewsModeration.id == this.$route.params.id)
        });
      },
      loadOrder() {
        this.$set(this, "waiteLoad", true);
        this.$store.dispatch(trackActionTypes.trackInfo,{id: this.reviewsModerationInfo.track_id}).then(()=>{
          this.isLoading = false;
          for (const point of this.listTracks.track) {
            if(point.points_array.length>0)
              this.coords= [point.points_array[0].lat,point.points_array[0].lon]
  
            this.form.trackName = point.name
            this.form.trackDescription = point.description
            this.form.track_address = point.track_address
            this.form.track_distance = point.track_distance
            this.form.track_time = point.track_time
            this.form.track_type = point.track_type
  
            for (const points_array of point.points_array) {
              this.coordinates.push(
                {
                  coord:[points_array.lat,points_array.lon],
                  media:points_array.media?.name
                }
              );
            }
          }
        })
      },
    },
  }
  </script>
  <style scoped lang="scss">
  @import "@/assets/scss/global-styles.scss";

  .v-input__slot:before textarea{
    white-space: normal;
  }
  .moderation-info {
    margin: 20px
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 30px;
  }
  .cu-pointer {
    cursor: pointer;
  }
  .my-5 {
    margin: 15px 0;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Убедитесь, что loader находится впереди всех окон */
  }
  .loader {
    font-size: 18px;
    color: #333;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  #app {
    height: 100%;
  }
  
  .ymap-container {
    height: 100%;
  }
  .order-page {
    .order-info {
      padding-bottom: 15px;
  
      &__item {
        display: flex;
        align-items: flex-start;
  
        &:not(:last-child) {
          padding-bottom: 3px;
          margin-bottom: 5px;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
        }
  
        span {
          font-weight: bold;
          width: 20%;
  
        }
  
        &.column {
          flex-direction: column;
  
          span {
            width: 100%;
          }
        }
      }
    }
  }
  </style>