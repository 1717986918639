<template>
  <div class="orders-page">
    <Heading level="2" text="Новое звание"/>
    <Grid :column="1" :gap="10">
      <ContentBlock >

        <div class="order-info__item">

          <span>Звание:</span>
          <div> <v-text-field v-model="rankInfo.title"  required></v-text-field></div>
        </div>

        <div class="order-info__item">

          <span>Описание:</span>
          <div> <v-text-field v-model="rankInfo.description"  required></v-text-field></div>
        </div>

     

        <v-col cols="6" sm="6" md="6">
                  <form @submit.prevent="onSubmitImage">
                    <v-file-input
                        v-model="file"
                        accept="image/*"
                        label="Изображение"
                        required
                        clear-icon="mdi-close-circle"
                    ></v-file-input>
                    <!-- <v-col cols="2" sm="2" md="2">
                      <v-btn
                          type="submit"
                          block
                          class="mt-6"
                          color="primary"
                      >
                        Добавить картинку
                      </v-btn>
                    </v-col> -->
                  </form>
                </v-col>

        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm"
          >
            Сохранить
          </v-btn>



        </div>
      </ContentBlock>

    </Grid>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import ranks, {ranksActionTypes} from '@/store/modules/ranks';

export default {
  name: 'CreateRank',
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      rankInfo: state => state.rank.rankInfo
    })
  },
  data() {
    return {
      itemsBranches: [
        { id: '2', name: 'Теги' },
        { id: '3', name: 'Звания' },
        { id: '4', name: 'Юридическая информация' },
        { id: '5', name: 'Пользовательское соглашение' },
        { id: '6', name: 'Политика конфиденциальности' },
      ],
      rankInfo: {
        title: '',
        description: ''
      },
      file:null
    }
  },

  methods: {
    onSubmitImage() {
     //let formData = new FormData();
     
      if(this.file){
        //formData.append('files', this.file);
        // this.$store.dispatch(actionTypes.createBanners, formData).then(() => {
        //     this.$router.push({ name: 'Banners', params: {} })
        // })
      }
    },
    isFormValid() {
        if (this.rankInfo.title === null || this.rankInfo.title === '' || this.rankInfo.description === '' || this.rankInfo.description === null) {
          return false;
        }
      return true;
    },
    submitForm() {
      console.log(this.rankInfo)
      if (this.isFormValid()) {
        let formData = new FormData();
          formData.append('title', this.rankInfo.title);
          formData.append('description', this.rankInfo.description);
        if(this.file){
          formData.append('files', this.file);
        }
        console.log(formData)
        this.$store.dispatch(ranksActionTypes.rankCreate, formData).then(()=>{
          this.$router.push('/main/ranks');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
  }
}
</script>

<style scoped lang="scss">
/* ... ваши стили ... */
</style>
