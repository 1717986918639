import bannerApi from '@/api/banner';

const state = {
    listBanners: null,
    createdBanner: null,
    updatedBanner: null,
    deletedBanner: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getBannersStart: '[banners] Get banners start',
    getBannersSuccess: '[banners] Get banners success',
    getBannersFailure: '[banners] Get banners failure',
    createBannerStart: '[banner] Create banner start',
    createBannerSuccess: '[banner] Create banner success',
    createBannerFailure: '[banner] Create banner failure',
    updateBannerStart: '[banner] Update banner start',
    updateBannerSuccess: '[banner] Update banner success',
    updateBannerFailure: '[banner] Update banner failure',
    deleteBannerStart: '[banner] Delete banner start',
    deleteBannerSuccess: '[banner] Delete banner success',
    deleteBannerFailure: '[banner] Delete banner failure',
    saveSortStart: '[banner] Save sort start',
    saveSortSuccess: '[banner] Save sort success',
    saveSortFailure: '[banner] Save sort failure',
}

export const bannersActionTypes = {
    getBanners: '[banners] Get banners',
    createBanner: '[banner] Create banner',
    updateBanner: '[banner] Update banner',
    deleteBanner: '[banner] Delete banner',
    saveSort: '[banner] Save sort'
}

const mutations = {
    [mutationTypes.getBannersStart](state) {
        state.isLoading = true
        state.listBanners = null
    },
    [mutationTypes.getBannersSuccess](state, payload) {
        state.isLoading = false
        state.listBanners = payload
        state.validationErrors = null
    },
    [mutationTypes.getBannersFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.createBannerStart](state) {
        state.isLoading = true
        state.createdBanner = null
    },
    [mutationTypes.createBannerSuccess](state, payload) {
        state.isLoading = false
        state.createdBanner = payload
        state.validationErrors = null
    },
    [mutationTypes.createBannerFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.updateBannerStart](state) {
        state.isLoading = true
        state.updatedBanner = null
    },
    [mutationTypes.updateBannerSuccess](state, payload) {
        state.isLoading = false
        state.updatedBanner = payload
        state.validationErrors = null
    },
    [mutationTypes.updateBannerFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.deleteBannerStart](state) {
        state.isLoading = true
        state.deletedBanner = null
    },
    [mutationTypes.deleteBannerSuccess](state, payload) {
        state.isLoading = false
        state.deletedBanner = payload
        state.validationErrors = null
    },
    [mutationTypes.deleteBannerFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.saveSortStart](state) {
        state.isLoading = true
        state.listBanners = null
    },
    [mutationTypes.saveSortSuccess](state, payload) {
        state.isLoading = false
        state.listBanners = payload
        state.validationErrors = null
    },
    [mutationTypes.saveSortFailure](state,payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [bannersActionTypes.getBanners](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getBannersStart)
            bannerApi
                .getBanners(data)
                .then(response => {
                    context.commit(mutationTypes.getBannersSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getBannersFailure,response)
                })
        })
    },
    [bannersActionTypes.createBanner](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.createBannerStart)
            bannerApi
                .createBanner(data)
                .then(response => {
                    context.commit(mutationTypes.createBannerSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.createBannerFailure,response)
                })
        })
    },
    [bannersActionTypes.updateBanner](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.updateBannerStart)
            bannerApi
                .updateBanner(data)
                .then(response => {
                    context.commit(mutationTypes.updateBannerSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.updateBannerFailure,response)
                })
        })
    },
    [bannersActionTypes.deleteBanner](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.deleteBannerStart)
            bannerApi
                .deleteBanner(data)
                .then(response => {
                    context.commit(mutationTypes.deleteBannerSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.deleteBannerFailure,response)
                })
        })
    },
    [bannersActionTypes.saveSort](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.saveSortStart)
            bannerApi
                .saveSort(data)
                .then(response => {
                    context.commit(mutationTypes.saveSortSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.saveSortFailure,response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}