<template>

  <div>

  </div>

</template>

<script>
export default {
  name: 'McvOrders',
  components: {
  },
  data() {
    return {
      id: "",
      date_receipt_id: "",
      status_id: "",
      shop_name: "",
      user_data: "",
      city: "",
      created: "",
      payments: "",
      dateStatusListItems: ["", "Сегодня", "Завтра", "Послезавтра"],
      orderStatusListItems: ["Отменён", "В обработке", "Заказ собран", "Заказ выдан"],
      paymentsStatusListItems: ["", "Оплата в процессе", "Оплачен", "Ошибка оплаты", "Нет оплаты"]
    }
  },
}
</script>