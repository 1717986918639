<template>
  <div class="sidebar">
    <ul class="menu">
      <template v-for="(m, ind) of menu">
        <li class="menu__item" :key="'menu-' + ind" >
          <router-link  :to="m.url" exact exact-active-class="active">
            {{ m.title }}
          </router-link>
          <div v-if="m.withBridge && m.withBridge === 'moderation' && listModeration?.track?.length" class="bridge">
            <small>{{ listModeration?.track?.length }}</small>
          </div>
          <div v-if="m.withBridge && m.withBridge === 'appeals' && listAppeals?.complaints?.length" class="bridge">
            <small>{{ listAppeals?.complaints?.length }}</small>
          </div>
          <div v-if="m.withBridge && m.withBridge === 'reviews-moderation' && listAppeals?.complaints?.length" class="bridge">
            <small>{{ listReviewsModeration?.track?.length }}</small>
          </div>
          <div v-if="m.withBridge && m.withBridge === 'reviews-app' && listAppeals?.complaints?.length" class="bridge">
            <small>{{ listReviewsApp?.reviews?.length }}</small>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import {globalMethods} from "@/mixins/globalMethods";
import {mapState} from 'vuex'
import {moderationActionTypes} from '@/store/modules/moderation';
import {appealsActionTypes} from '@/store/modules/appeals';
import {reviewsAppActionTypes} from '@/store/modules/reviews-app';
import {reviewsModerationActionTypes} from '@/store/modules/reviews-moderation';

export default {
  mixins: [globalMethods],
  computed: {
    ...mapState({
      userType: state => state.userType,
      listModeration: state => state.moderation.listModeration,
      listAppeals: state => state.appeals.listAppeals,
      listReviewsApp: state => state.reviewsApp.listReviewsApp,
      listReviewsModeration: state => state.reviewsModeration.listReviewsModeration,
    })
  },
  mounted() {
    this.loadLoans()
  },
  data() {
    return {
      menu: [
        {
          title: 'Треки',
          url: '/main/track',
          openFor: [1, 2, 3],
        },
        {
          title: 'Справочники',
          url: '/main/directory',
          openFor: [1, 2, 3],
        },
        {
          title: 'Уведомления',
          url: '/main/notices',
          openFor: [1, 2],
        },
        {
          title: 'Администраторы',
          url: '/main/admins',
          openFor: [1, 2],
        },
        {
          title: 'Пользователи',
          url: '/main/clients',
          openFor: [1, 2],
        },
        {
          title: 'Треки на модерации',
          url: '/main/track-moderation',
          openFor: [1, 2],
          withBridge: 'moderation'
        },
        {
          title: 'Обращения',
          url: '/main/appeals',
          openFor: [1, 2],
          withBridge: 'appeals'
        },
        {
          title: 'Отзывы на модерации',
          url: '/main/reviews-moderation',
          openFor: [1, 2],
          withBridge: 'reviews-moderation'
        },
        {
          title: 'Оценки приложения',
          url: '/main/reviews-app',
          openFor: [1, 2],
          withBridge: 'reviews-app'
        },
      ]
    }
  },
  methods: {
    loadLoans() {
      this.$set(this, "waiteLoad", true);
      this.$store.dispatch(moderationActionTypes.getModeration)
      this.$store.dispatch(appealsActionTypes.getAppeals)
      this.$store.dispatch(reviewsAppActionTypes.getReviewsApp)
      this.$store.dispatch(reviewsModerationActionTypes.getReviewsModeration).then(res => console.log('res: ', res))
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.sidebar {
  background: $color-orange;
  color: $color-white;
  grid-row: 2/3;
  height: 100%;
  z-index: 2;
  padding: 15px;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, .2);

  .menu {
    padding: 0;
    margin: 0;
    margin-top: 50px;
    list-style: none;
    width: 100%;
    position: sticky;
    top: 0;

    &__item {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, .3);
      }

      a {
        text-decoration: none;
        padding: 5px 0;
        transition: all .3s;
        color: $color-white;
        opacity: .7;

        &:hover,
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .bridge {
    margin-right: -10px;
    color: #fff;
    background: #108dec;
    border-radius: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 5px;
    width: 30px;
    height: 30px;
  }

}
</style>