<template>
  <div class="admin-page">
    <Heading level="2" text="Управление администраторами"/>
    <div class="grid-section">
      <ContentBlock>
        <Table>
          <template v-slot:head>
            <tr>
              <th><input type="checkbox" class="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></th>
              <th>Email</th>
              <th>Имя</th>
              <th>Фамилия</th>
              <th>Статус</th>
              <th></th>
            </tr>
          </template>
          <template v-slot:body v-if="listAdmins?.data">
            <tr v-for="(admin, ind) of listAdmins.data" :key="`admin-${ind}`">
              <td><input type="checkbox" v-model="admin.isSelected" @change="toggleSelection(admin)"></td>
              <td>{{ admin.email }}</td>
              <td>{{ admin?.first_name }}</td>
              <td>{{ admin?.last_name }}</td>
              <td>{{ statusType[admin.type] }}</td>
              <td align="right">
                <button class="ghost" @click="edit(ind)">
                  <Icon iconName="edit" width="20" height="20" stroke="#a6e71d"/>
                </button>
                <button class="ghost" @click="deleteAdminOne(admin.id, ind)">
                  <Icon 
                    iconName="delete" 
                    width="25" 
                    height="20" 
                    :viewBox="'0 0 20 20'"
                    :stroke="'unset'" 
                    :fill="'#d30909'"
                  />
                </button>
              </td>
            </tr>
          </template>
        </Table>
        <div class="buttons-group mt-5">
            <v-btn  
              :disabled="selectedAdmin.length === 0" 
              large 
              color="#F35821" 
              class="color-white" 
              @click="deleteAdmin(selectedAdmin.map(admin => admin.id))"
            >
              Удалить
            </v-btn>
        </div>
      </ContentBlock>
      <ContentBlock>
        <v-form @submit.prevent="onSubmit">


          <v-text-field
              v-model.trim="adminForm.email"
              name="email"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Email"
          >
          </v-text-field>


          <v-text-field
              v-model.trim="adminForm.password"
              name="password"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Пароль"
          >
          </v-text-field>
          
          <v-text-field
              v-model.trim="adminForm.first_name"
              name="first_name"
              type="text"
              color="#F35821"
              outlined
              placeholder=""
              label="Имя"
          >
          </v-text-field>
          
          <v-text-field
              v-model.trim="adminForm.last_name"
              name="last_name"
              type="text"
              color="#F35821"
              outlined
              placeholder=""
              label="Фамилия"
          >
          </v-text-field>


          <v-radio-group
              v-model="adminForm.type"
              label="Полномочия"
          >
            <v-radio
                v-for="(value, ind) of statusType"
                name="status"
                color="#F35821"
                style="    opacity: 1;"
                :key="`status-${ind}`"
                :label="value"
                :value="ind"
            >
            </v-radio>
          </v-radio-group>

          <v-btn type="submit" block color="#F32719" style="height: 48px !important;" :disabled="disabledBtn">
            <template v-if="adminForm.id">
              Изменить
            </template>
            <template v-else>
              Добавить
            </template>
          </v-btn>
          <v-btn plain block large class="btn-cancel" @click="cancel()">
            Отменить
          </v-btn>
        </v-form>
      </ContentBlock>
    </div>
    <Modal ref="confirm">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        {{ confirmData.body }}
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.confirm._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="$refs.confirm._confirm()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
  </div>

</template>

<script>
import {globalMethods} from "@/mixins/globalMethods";
import {adminsActionTypes} from '@/store/modules/admin';
import {mapState} from "vuex";

export default {
  name: 'Admins-page',
  mixins: [globalMethods],
  data() {
    return {
      selectedAll: false,
      selectedAdmin: [],
      listAdmins: [],
      admins: [],
      adminForm: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        type: '',
        id: null
      },
      statusType: {
        '0': 'Не задан',
        '1': 'Админ',
        '2': 'Модератор',
      },
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Удалить',
      }
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.admin.isSubmitting,
      validationErrors: state => state.admin.validationErrors,
    }),
    disabledBtn() {
      if (!this.adminForm?.id) {
        return !this.adminForm.password || !/^[1|2|3]$/.test(this.adminForm.type) && !this.adminForm.email
      } else {
        let index = this.listAdmins.data.findIndex(admin => admin.id === this.adminForm.id)
        if ( this.adminForm.email === this.listAdmins.data[index].email)
          return false
        return true
      }
    }
  },
  mounted() {
    this.loadAdmins()
  },
  methods: {
    cancel() {
      this.$set(this, "adminForm", {
        password: '',
        type: '',
        email: '',
        first_name: '',
        last_name: '',
        id: null
      });
    },
    edit(ind) {
      this.$set(this, "adminForm", {...this.listAdmins.data[ind]});
    },
    deleteAdmin(id) {
      this.$set(this.confirmData, 'header', `Удалить выбранных админов?`);
      this.$set(this.confirmData, 'body', `После удаления вернуть данные будет не возможно!`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.$store.dispatch(adminsActionTypes.deleteAdmin, {
            id
          })
          .then(() => {
            this.loadAdmins()
          })
        }
      })
    },
    deleteAdminOne(id, ind) {
      this.$set(this.confirmData, 'header', `Удалить ${this.listAdmins.data[ind].email}`);
      this.$set(this.confirmData, 'body', `Вы уверены что хотите удалить ${this.listAdmins.data[ind].email}? После удаления вернуть данные будет не возможно!`);
      this.$refs.confirm.show({}).then((result) => {
        if (result) {
          this.$store.dispatch(adminsActionTypes.deleteAdmin, {
            id
          })
          .then(() => {
            this.loadAdmins()
          })
        }
      })
    },
    onSubmit() {
      this.checkPassword(this.adminForm)
          .then(this.checkStatus)
          .then(data => {
            if (data?.id) {
              this.updateAdmin(data)
            } else {
              this.createdAdmin(data)
            }
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    createdAdmin(data) {
      this.$store.dispatch(adminsActionTypes.createAdmin, {
        password: data.password,
        email: data.email,
        last_name: data.last_name,
        first_name: data.first_name,
        type: data.type,
      })
          .then(() => {
            this.$set(this, "adminForm", {
              password: '',
              type: '',
              email: '',
              last_name: '',
              first_name: '',
              id: null
            });
            this.loadAdmins()
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    updateAdmin(data) {
      this.$store.dispatch(adminsActionTypes.updateAdmin, {
        id: data.id,
        password: data.password,
        email: data.email,
        last_name: data.last_name,
        first_name: data.first_name,
        type: data.type,
      })
          .then(() => {
            this.$set(this, "adminForm", {
              password: '',
              type: '',
              email: '',
              last_name: '',
              first_name: '',
              id: null
            });
            this.loadAdmins()
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
    },
    loadAdmins() {
      this.$store.dispatch(adminsActionTypes.getListAdmin).then(res => {
        this.listAdmins = res
        this.listAdmins.data.forEach(admin => admin.isSelected = false)
      })
    },
    toggleSelectionAll() {
      if (this.selectedAll) {
          this.listAdmins.data.forEach(admin => {
            admin.isSelected = true
            this.selectedAdmin.push(admin)
          })
        } else {
          this.listAdmins.data.forEach(admin => admin.isSelected = false)
          this.selectedAdmin = []
        }
    },
    toggleSelection(admin) {
      this.listAdmins.data.forEach(adm => {
        if (admin.id === adm.id) {
          if (adm.isSelected) {
            adm.isSelected = true
            this.selectedAdmin.push(adm)
          } else {
            adm.isSelected = false
            this.selectedAdmin = this.selectedAdmin.filter(item => item.id !== admin.id)
          }
        }
      })
      if (!this.selectedAdmin.length) {
        this.selectedAll = false
      }
    }
  },
}
</script>
<style  lang="scss">
@import "@/assets/scss/global-styles.scss";

.admin-page {
  .grid-section {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
  form {
    button {
      color: $color-white;

      &.btn-cancel {
        color: $color-black !important;
      }
    }
  }

  .color-white {
    color: $color-white;
  }

}


 .v-input--selection-controls__input input[role=checkbox], .v-input--selection-controls__input input[role=radio], .v-input--selection-controls__input input[role=switch] {
opacity: 1!important;

}
</style>