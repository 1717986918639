import reviewsModerationApi from '@/api/reviews-moderation';

const state = {
    listReviewsModeration: null,
    reviewsModerationInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getReviewsModerationStart: '[reviewsModeration] Get reviewsModeration start',
    getReviewsModerationSuccess: '[reviewsModeration] Get reviewsModeration success',
    getReviewsModerationFailure: '[reviewsModeration] Get reviewsModeration failure',

    reviewsModerationInfoStart: '[reviewsModeration] Get reviewsModeration info start',
    reviewsModerationInfoSuccess: '[reviewsModeration] Get reviewsModeration info success',
    reviewsModerationInfoFailure: '[reviewsModeration] Get reviewsModeration info failure',

    reviewsModerationUpdateStart: '[reviewsModeration] Update reviewsModeration start',
    reviewsModerationUpdateSuccess: '[reviewsModeration] Update reviewsModeration success',
    reviewsModerationUpdateFailure: '[reviewsModeration] Update reviewsModeration failure',
    
    reviewsModerationRemoveStart: '[reviewsModeration] Remove reviewsModeration start',
    reviewsModerationRemoveSuccess: '[reviewsModeration] Remove reviewsModeration success',
    reviewsModerationRemoveFailure: '[reviewsModeration] Remove reviewsModeration failure',

}

export const reviewsModerationActionTypes = {
    getReviewsModeration: '[reviewsModeration] Get reviewsModeration',
    reviewsModerationInfo: '[reviewsModeration] Get reviewsModeration info',
    reviewsModerationUpdate: '[reviewsModeration] Update reviewsModeration',
    reviewsModerationRemove: '[reviewsModeration] Remove reviewsModeration',
}

const mutations = {
    [mutationTypes.getReviewsModerationStart](state) {
        state.isLoading = true
        state.listReviewsModeration = null
    },
    [mutationTypes.getReviewsModerationSuccess](state, payload) {
        state.isLoading = false
        state.listReviewsModeration = payload
        state.validationErrors = null
    },
    [mutationTypes.getReviewsModerationFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.reviewsModerationInfoStart](state) {
        state.isLoading = true
        state.reviewsModerationInfo = null
    },
    [mutationTypes.reviewsModerationInfoSuccess](state, payload) {
        state.isLoading = false
        state.reviewsModerationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsModerationInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.reviewsModerationUpdateStart](state) {
        state.isLoading = true
        state.reviewsModerationInfo = null
    },
    [mutationTypes.reviewsModerationUpdateSuccess](state, payload) {
        state.isLoading = false
        state.reviewsModerationInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsModerationUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.reviewsModerationRemoveStart](state) {
        state.isLoading = true
        state.trackInfo = null
    },
    [mutationTypes.reviewsModerationRemoveSuccess](state, payload) {
        state.isLoading = false
        state.trackInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.reviewsModerationRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },
}

const actions = {
    [reviewsModerationActionTypes.getReviewsModeration](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getReviewsModerationStart)
            reviewsModerationApi
                .getReviewsModeration(data)
                .then(response => {
                    context.commit(mutationTypes.getReviewsModerationSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getReviewsModerationFailure, response)
                })
        })
    },
    [reviewsModerationActionTypes.reviewsModerationInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.moderationInfoStart)
            reviewsModerationApi
                .updateReviewsModeration(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsModerationInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsModerationInfoFailure, response)
                })
        })
    },
    [reviewsModerationActionTypes.reviewsModerationUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.reviewsModerationUpdateStart)
            reviewsModerationApi
                .updateReviewsModeration(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsModerationUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsModerationUpdateFailure, response)
                })
        })
    },
    [reviewsModerationActionTypes.reviewsModerationRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.reviewsModerationRemoveStart)
            reviewsModerationApi
                .removeReviewsModeration(data)
                .then(response => {
                    context.commit(mutationTypes.reviewsModerationRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.reviewsModerationRemoveFailure, response)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
}