<template>
  <div class="tariff-page">
    <Heading level="2" text="Управление уведомлениями"/>
    <Grid :column="1" :gap="10">
      <ContentBlock>
        <v-btn large color="#F32719" class="color-white" @click="apply()">
          Отправить уведомления
        </v-btn>
      </ContentBlock>
      <ContentBlock>
        <Table>
          <template v-slot:head>
            <tr>
              <th>№</th>
              <th>Дата и время отправки</th>
              <th>Статус</th>
              <th>Заголовок уведомления</th>
              <th>Текст уведомления</th>
              <th></th>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(notice, ind) of listNotices.notice" :key="`notice-${ind}`">
              <td>{{ notice.id }}</td>
              <td>{{ notice.send_date }}</td>
              <td :style="getColor(notice.status)">{{ getStatus(notice.status) }}</td>
              <td>{{ notice.title }}</td>
              <td>{{ notice.text }}</td>
              <td>
                <button class="ghost" @click="edit(ind)" v-if="notice.status === 1">
                  <Icon iconName="edit" width="20" height="20" stroke="#a6e71d"/>
                </button>
                <v-btn color="#F35821" class="color-white" @click="repeat(ind)" v-if="notice.status === 0">
                  Повторить
                </v-btn>
                <v-btn plain class="btn-cancel" @click="cancel(ind)" v-if="notice.status === 0">
                  Отменить
                </v-btn>
              </td>
            </tr>
          </template>
        </Table>
      </ContentBlock>
    </Grid>
    <Modal ref="noticeCreate" :notFooterBorder="true">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        <div class="input-group">
          <v-text-field
              hide-details
              v-model="notice.title"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Заголовок уведомления"
          >
          </v-text-field>

          <v-text-field
              hide-details
              v-model="notice.text"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Текст уведомления"
          >
          </v-text-field>

          <v-checkbox
              hide-details
              v-model="schedule_repeat"
              label="Повторяющееся"
              color="orange"
          >
          </v-checkbox>


          <v-checkbox
              hide-details
              v-model="schedule_wait"
              label="Отложенное"
              color="orange"
          >
          </v-checkbox>

          <v-datetime-picker
              datetime="String"
              label="Укажите дату и время отправки"
              clearText="Сбросить"
              okText="Ок"
              v-if="schedule_wait || schedule_repeat"
              v-model="notice.send_date"
              dialogWidth="400"
              :datePickerProps="{
                locale: 'ru',
                color: '#F35821'
              }"
              :timePickerProps="{
                format: '24hr',
                color: '#F35821'
              }"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-blank-outline</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-time-five-outline</v-icon>
            </template>
          </v-datetime-picker>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.noticeCreate._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="createNotice()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
    <Modal ref="noticeUpdate" :notFooterBorder="true">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        <div class="input-group">
          <v-text-field
              hide-details
              v-model="notice.title"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Заголовок уведомления"
          >
          </v-text-field>

          <v-text-field
              hide-details
              v-model="notice.text"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Текст уведомления"
          >
          </v-text-field>


          <v-checkbox
              hide-details
              v-model="schedule_repeat"
              label="Повторяющееся"
              color="orange"
          >
          </v-checkbox>

          <v-checkbox
              hide-details
              v-model="schedule_wait"
              label="Отложенное"
              color="orange"
          >
          </v-checkbox>

          <v-datetime-picker
              datetime="String"
              label="Укажите дату и время отправки"
              v-if="schedule_repeat"
              v-model="notice.send_date"
              dialogWidth="400"
              :datePickerProps="{
                locale: 'ru',
                color: '#F35821'
              }"
              :timePickerProps="{
                format: '24hr',
                color: '#F35821'
              }"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-blank-outline</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-time-five-outline</v-icon>
            </template>
          </v-datetime-picker>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.noticeUpdate._cancel()">
          Отменить
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="updateNotice()">
          {{ confirmData.btnConfirm }}
        </v-btn>
      </template>
    </Modal>
    <Modal ref="noticeCancel" :notFooterBorder="true">
      <template v-slot:header>
        {{ confirmData.header }}
      </template>
      <template v-slot:body>
        <div class="input-group">
          <v-text-field
              hide-details
              readonly
              v-model="notice.title"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Заголовок уведомления"
          >
          </v-text-field>

          <v-text-field
              hide-details
              readonly
              v-model="notice.text"
              type="text"
              color="#F35821"
              outlined
              class="input"
              placeholder=""
              label="Текст уведомления"
          >
          </v-text-field>

          <v-datetime-picker
              disabled
              datetime="String"
              label="Укажите дату и время отправки"
              v-model="notice.send_date"
              dialogWidth="400"
              :datePickerProps="{
                locale: 'ru',
                color: '#F35821'
              }"
              :timePickerProps="{
                format: '24hr',
                color: '#F35821'
              }"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-blank-outline</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-time-five-outline</v-icon>
            </template>
          </v-datetime-picker>


        </div>
      </template>
      <template v-slot:footer>
        <v-btn plain large class="btn-cancel" @click="$refs.noticeUpdate._cancel()">
          Нет
        </v-btn>
        <v-btn large class="color-white" color="#F35821" @click="cancelNotice()">
          Да
        </v-btn>
      </template>
    </Modal>
  </div>

</template>

<script>
import {noticesActionTypes} from '@/store/modules/notice';
import {mapState} from "vuex";

export default {
  name: 'notice-page',
  data() {
    return {
      notice: {},
      menu: false,
      schedule_repeat: '',
      schedule_wait: '',
      confirmData: {
        header: '',
        body: '',
        btnConfirm: 'Отправить',
      }
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.notice.isSubmitting,
      validationErrors: state => state.notice.validationErrors,
      listNotices: state => state.notice.listNotices
    })
  },
  mounted() {
    this.loadNotices()
  },
  methods: {
    apply() {
      this.$refs.noticeCreate.show()
    },
    edit(ind) {
      this.$set(this, "notice", {...this.listNotices.notice[ind]});
      this.$set(this.confirmData, 'header', `Изменить №${this.listNotices.notice[ind].id}`);
      this.$refs.noticeUpdate.show()
    },
    repeat(ind) {
      this.$set(this, "notice", {...this.listNotices.notice[ind]});
      this.$set(this.confirmData, 'header', `Повторить №${this.listNotices.notice[ind].id}`);
      this.$refs.noticeUpdate.show()
    },
    cancel(ind) {
      this.$set(this, "notice", {...this.listNotices.notice[ind]});
      this.$set(this.confirmData, 'header', `Отменить №${this.listNotices.notice[ind].id}`);
      this.notice.send_date = new Date(this.notice.send_date);
      this.$refs.noticeCancel.show()
    },
    loadNotices() {
      this.$store.dispatch(noticesActionTypes.getNotices);
    },
    createNotice() {
      this.$store.dispatch(noticesActionTypes.createNotice, {
        title: this.notice.title,
        text: this.notice.text,
        status: this.notice.date ? 1 : 0,
        send_date: this.notice.date ? new Date(this.notice.send_date): new Date()
      }).then((response) => {
        if (response.success) {
          this.loadNotices();
          this.$refs.noticeCreate._confirm();
        }
      })
    },
    updateNotice() {
      this.$store.dispatch(noticesActionTypes.updateNotice, {
        id: this.notice.id,
        title: this.notice.title,
        text: this.notice.text,
        status: this.notice.date ? 1 : 0,
        send_date: this.notice.date ? new Date(this.notice.send_date): new Date()
      }).then((response) => {
        if (response.success) {
          this.loadNotices();
          this.$refs.noticeUpdate._confirm();
        }
      })
    },
    cancelNotice() {
      this.$store.dispatch(noticesActionTypes.updateNotice, {
        id: this.notice.id,
        title: this.notice.title,
        text: this.notice.text,
        status: 2,
        send_date: this.notice.date ? new Date(this.notice.send_date) : new Date()
      }).then((response) => {
        if (response.success) {
          this.loadNotices();
          this.$refs.noticeUpdate._confirm();
        }
      })
    },
    getStatus(status) {
      if (status === 0) {
        return 'Отправлено'
      } else if (status === 1) {
        return 'Ожидает отправки'
      } else {
        return 'Отмена'
      }
    },
    getColor(status) {
      if (status === 0) {
        return 'color: green'
      } else if (status === 1) {
        return 'color: yellow'
      } else {
        return 'color: red'
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.tariff-page {
  .filter {
    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        &.coll-2 {
          width: calc(50% - 3px);
        }

        &.coll-3 {
          width: calc(33% - 3px);
        }

        &.coll-4 {
          width: calc(25% - 3px);
        }
      }

      .toggle-btn {
        display: flex;
        align-items: center;

        span {
          padding-left: 10px;
        }
      }
    }

    .buttons-group {
      padding-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .input-group {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
  }
}
</style>