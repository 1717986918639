import Vue from 'vue'

/** form */
import Heading from "@/components/Heading.vue";
import ContentBlock from "@/components/ContentBlock.vue";
import Grid from "@/components/Grid.vue";
import Table from "@/components/Table.vue";
import Icon from "@/components/Icons.vue";
import Modal from '@/components/ModalDialog'

const components = { Icon, Modal, Heading, ContentBlock, Grid, Table }

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component)
})