<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
// import Login from "@/views/Login";

export default {
  name: "App",
  components: {
    // Login,
  },
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  mounted() {
  },
  methods: {
    checkClass(page) {
      let res = 'divt'
      if (page == this.$route.fullPath.split('/')[1]) {
        res = 'divt divta'
      }
      return res;
    },
    clearStorage() {
      if (localStorage.getItem('pagination_page')) {
        localStorage.setItem('pagination_page', 0)
      }
    }
  },
  watch: {
    group() {
      this.drawer = false;
    },
    showMenu() {
      let type = localStorage.getItem('type')
      this.showMenu = type
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout"
    },
    isSignIn() {
      console.log(this.$route.path)
      if (this.$route.path === '/login' || this.$route.path === '/')
        return 'loginBackgroundColor'

      return 'otherBackgroundColor';
    }
  }
};
</script>
