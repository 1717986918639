<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row justify-center>
      <v-flex xs3 justify-center>
        <router-link to="/">
          <v-img :src="require('@/assets/logo.svg')" class="logo" alt="logo" contain></v-img>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs4>
        <div class="enter_system color-white">Вход в систему</div>
      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="validationErrors !== null">
      <v-flex xs4 mt-2 justify-center>
        <v-alert color="red" text type="error">{{ validationErrors }}
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs4>
        <v-form @submit.prevent="onSubmit">
          <v-text-field  
            v-model="email" 
            dark 
            outlined 
            class="input color-white"
            label="Email"
          />
          <div class="password-wrapper">
            <v-text-field  
              v-model="password" 
              dark 
              outlined 
              class="input color-white"
              label="password"
              :type="showPassword ? 'text' : 'password'"
            />
            <span @click="showPassword = !showPassword" class="icon-password">
              <EyeShowIcon v-if="showPassword" />
              <EyeHideIcon v-if="!showPassword" />
            </span>
          </div>
          <v-btn type="submit" class="button mt-6" style="height: 48px !important;">
            Войти
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import "moment-duration-format";
import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from "@/mixins/globalMethods";
import { EyeHideIcon, EyeShowIcon } from '@/components/icons/index'
//import store from './store'

export default {
  name: 'McvLogin',
  mixins: [globalMethods],
  components: {EyeHideIcon, EyeShowIcon},
  data() {
    return {
      email: '',
      password: '',
      session_id: null,
      showPassword: false,
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.auth.isSubmitting,
      validationErrors: state => state.auth.validationErrors,
      user: state => state.auth.user
    }),
  },
  mounted() {
    if (this.$cookies.get('token')) {
      this.$router.push({ name: 'Main' });
    }
  },
  methods: {
    onSubmit() {
      this.sendAuth()
    },
    sendAuth() {
      this.$store
        .dispatch(actionTypes.login, {
          email: this.email,
          password: this.password
        })
        .then(() => {
          //store.commit('setEmail', this.email);
          this.$router.push({ name: 'Track' });
        })
    },
    reset() {
      this.$store.state.auth.user = null
    }
  },
  watch: {}

}
</script>

<style scoped lang="scss">
.password-wrapper {
  position: relative;
}

.icon-password {
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
}

.v-application .red--text {
  color: #ffffff !important;
  caret-color: #F32719 !important;
}

.repeat-send-code {
  text-decoration: underline !important;
}

.color-white {
  color: #fff !important;
}
</style>

