<template>
    <div class="orders-page">
      <div class="head-wrap">
        <Heading level="2" class="head" text="Обращения"/>
        <span>Количество обращений: {{ listAppeals?.length }}</span>
      </div>
      <Grid :column="1" :gap="10"> 
        <div class="filters-wrapper">
          <div class="search-wrapper">
            <input 
              type="text" 
              v-model="search" 
              @keyup="makeSearch()" 
              placeholder="Поиск..." 
              class="search-input" 
            />
            <div @click="makeSearch()" class="search">
              <SearchIcon/>
            </div>
          </div> 
        </div>
        <ContentBlock v-if="listAppealsDef?.length">
          <Table>
            <!-- Таблица заголовков -->
            <template v-slot:head>
              <tr>
                <th><input type="checkbox" class="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></th>
                <th>ID</th>
                <th>Тема жалобы</th>
                <th>Дата жалобы</th>
                <th>Автор</th>
                <th>Название</th>
                <th>ID трека</th>
                <th>Текст жалобы</th>
                <th></th>
              </tr>
            </template>
            <!-- Таблица данных -->
            <template v-slot:body>
              <!-- Изменено: Используем отдельный computed свойство для пагинации -->
              <tr v-for="(order, ind) of pagedTracks" :key="`order-${ind}`">
                <td><input type="checkbox" v-model="order.selected" @change="toggleSelection(order)"></td>
                <td>{{ order.id }}</td>
                <td>{{ order.complaint_subject }}</td>
                <td>{{ order.complaint_date }}</td>
                <td>{{ order.user_name }}</td>
                <td>{{ order.track_name }}</td>
                <td>{{ order.track_id }}</td>
                <td>{{ order.text }}</td>
                <td align="right">
                  <router-link :to="`/main/appeals/update/${order.track_id}`">
                    <Icon iconName="edit" width="24" height="24" stroke="#a6e71d"/>
                  </router-link>
                </td>
               <td align="right">
                  <button>
                    <Icon iconName="delete" width="24" height="24" stroke="#a6e71d" @click="removeOne(order.id)"/>
                  </button>
                </td>
              </tr>
            </template>
          </Table> 
          <div class="buttons-group mt-5">
            <v-btn  
              :disabled="selectedTracks.length === 0" 
              large 
              color="#F35821" 
              class="color-white" 
              @click="remove"  
            >
              Удалить
            </v-btn>
          </div> 
          <v-pagination
              v-model="filter.page"
              :length="totalPages"
              total-visible="7"
          ></v-pagination>
        </ContentBlock>
      </Grid>
    </div>
  </template>
  
  <script>
  import {mapState} from "vuex";
  import {globalMethods} from "@/mixins/globalMethods";
  import {appealsActionTypes} from '@/store/modules/appeals';
  import {SearchIcon} from '@/components/icons/index'
  import {toLowerCaseTrim} from '@/helpers/utils'

  
  export default {
    name: 'moderation-page',
    components: {SearchIcon},
    data() {
      return {
        menu2: false,  
        search: '',
        event_date: '',
        selectedAll: false,
        selectedTracks: [],
        listAppeals: [],
        listAppealsDef: [],
        totalPageCount: 10,
        filter: {
          count: 10,
          page: 1,
        },
        waiteLoad: false,
      }
    },
    mixins: [globalMethods],
    computed: {
      ...mapState({
        isSubmitting: state => state.track.isSubmitting,
        validationErrors: state => state.track.validationErrors,
        // listAppeals: state => state.appeals.listAppeals
      }),
      pagedTracks() {
        const startIdx = (this.filter.page - 1) * this.filter.count;
        const endIdx = startIdx + this.filter.count;
        return this.listAppeals.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.listAppeals.length / this.filter.count);
      },
    },
    async mounted() {
      await this.loadLoans()
    },
    methods: {
      makeSearch() {
        this.listAppeals = this.listAppealsDef.filter((item) => {
          const keys = [
            'id', 
            'complaint_subject', 
            'complaint_date',
            'user_name',
            'track_name',
            'track_id',
            'text',
          ]
          if (keys.every(key => item[key] != "null")) {
            const isMatchesSearchFilter = keys.some(key => 
              toLowerCaseTrim(item[key])?.includes(toLowerCaseTrim(this.search))
            )
            return isMatchesSearchFilter
          }
        })
      },
      toggleSelection(track) {
        if (track.selected) {
          this.selectedTracks.push(track);
        } else {
          const index = this.selectedTracks.findIndex(selectedTrack => selectedTrack.id === track.id);
          if (index !== -1) {
            this.selectedTracks.splice(index, 1);
          }
        }
      },
      toggleSelectionAll() {
        if (this.selectedAll) {
          this.pagedTracks.forEach(appeal => {
              this.selectedTracks.push(appeal)
              appeal.selected = true
          })
        } else {
          this.pagedTracks.forEach(track => {
              this.selectedTracks = []
              track.selected = false
          })
        }
      },
      async loadLoans() {
        this.$set(this, "waiteLoad", true);
        await this.$store.dispatch(appealsActionTypes.getAppeals).then(res => {
          this.listAppeals = res.complaints
          const frmDate = (str) => {
            const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
            return new Date(str.replace(pattern,'$3-$2-$1'))
          }
          this.listAppeals.sort((a, b) => +frmDate(b.complaint_date) - +frmDate(a.complaint_date))
          this.listAppeals.forEach(appeals => appeals.selected = false)
          this.listAppealsDef = [...this.listAppeals]

          if(this.$route.query['search']) { 
            this.checkSearchParams()
            this.makeSearch()
          }
        })
      },
      remove() { 
        if (confirm('Вы уверены, что хотите удалить выбранные обращения?')) {
          const selectedIds = this.selectedTracks.map(track => track.id);
          this.$store.dispatch(appealsActionTypes.deleteAppeals, { id: selectedIds }).then(() => {
            this.selectedTracks = []; // Очистить выбранные записи
            this.loadLoans()
          });
        }
      },
      removeOne(id) {
        if (confirm('Вы уверены, что хотите удалить обращение?')) {
          this.$store.dispatch(appealsActionTypes.deleteAppeals, {id}).then(this.loadLoans)
        }
      },
      checkSearchParams() {
        const searchQuery = this.$route.query
        const paramsKeys = ['search']
        if (paramsKeys.some(key => searchQuery[key])) {
          this.search = decodeURIComponent(searchQuery.search)
        }
      },
      setRouteParams() {
        const search = `search=${this.search}`
        this.$router.push({path: `?${search}`})
      }
    },
    watch: {
      search() {
        this.setRouteParams()
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .filters-wrapper {
    display: flex; 
    align-items: center; 
    justify-self: end; 
    gap: 20px;
  }
  .search-wrapper {
    display:flex; 
    justify-self: end;
  }

  .search-input {
    border-radius: 2px;
    height: 32px; 
    width: 200px; 
    padding: 10px;
    border: 1px solid #dfdfdf;
  }

  .search {
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 32px; 
    width: 37px; 
    background-color: #F32719;
  }
  .head-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
  }
  .head {
    width: 400px!important;
  }
  </style>
  