import axios from '@/api/axios'

const getTracks = (data) => {
    return axios
        .post('/admin/track',{ track: data })
        .then(response => response.data)
}
const createTrack = (data) => {
    return axios
        .post('/admin/track/create',{ track: data })
        .then(response => response.data)
}

const updateTrack = (data) => {
    return axios
        .post('/admin/track/update', { track: data })
        .then(response => response.data)
}

const createPoint = (data) => {
    return axios
        .post('/admin/track/create-point', { track: data })
        .then(response => response.data)
        .catch(err => console.log('err: ', err))
}

const deleteTrack = (data) => {
    return axios
        .post('/admin/track/track-remove-id', data)
        .then(response => response.data)
}

const updateTags = (data) => {
    return axios
        .post('/admin/track/update-tags', data)
        .then(response => response.data)
}

const deleteTag = (data) => {
    return axios
        .post('/admin/track/delete-tag', data)
        .then(response => response.data)
}

const deleteMedia = (data) => {
    return axios
        .post('/admin/track/media-delete-preview', data)
        .then(response => response.data)
}

export default {
    getTracks,
    updateTrack,
    deleteTrack,
    createTrack,
    createPoint,
    updateTags,
    deleteTag,
    deleteMedia
}
