import legalApi from '@/api/legal';

const state = {
    listLegal: null,
    legalInfo: null,
    isLoading: false,
    validationErrors: null,
}

export const mutationTypes = {
    getLegalStart: '[legal] Get legal start',
    getLegalSuccess: '[legal] Get legal success',
    getLegalFailure: '[legal] Get legal failure',

    legalInfoStart: '[legal] Get legal info start',
    legalInfoSuccess: '[legal] Get legal info success',
    legalInfoFailure: '[legal] Get legal info failure',

    legalCreateStart: '[legal] Create legal start',
    legalCreateSuccess: '[legal] Create legal success',
    legalCreateFailure: '[legal] Create legal failure',

    legalUpdateStart: '[legal] Update legal start',
    legalUpdateSuccess: '[legal] Update legal success',
    legalUpdateFailure: '[legal] Update legal failure',

    legalRemoveStart: '[legal] Remove legal start',
    legalRemoveSuccess: '[legal] Remove legal success',
    legalRemoveFailure: '[legal] Remove legal failure'
}

export const legalActionTypes = {
    getLegal: '[legal] Get legal',
    legalInfo: '[legal] Get legal info',
    legalUpdate: '[legal] Update legal',
    legalCreate: '[legal] Create legal',
    legalRemove: '[legal] Remove legal',
}

const mutations = {
    [mutationTypes.getLegalStart](state) {
        state.isLoading = true
        state.listLegal = null
    },
    [mutationTypes.getLegalSuccess](state, payload) {
        state.isLoading = false
        state.listLegal = payload
        state.validationErrors = null
    },
    [mutationTypes.getLegalFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.legalInfoStart](state) {
        state.isLoading = true
        state.legalInfo = null
    },
    [mutationTypes.legalInfoSuccess](state, payload) {
        state.isLoading = false
        state.legalInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.legalInfoFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.legalCreateStart](state) {
        state.isLoading = true
        state.legalInfo = null
    },
    [mutationTypes.legalCreateSuccess](state, payload) {
        state.isLoading = false
        state.legalInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.legalCreateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.legalUpdateStart](state) {
        state.isLoading = true
        state.legalInfo = null
    },
    [mutationTypes.legalUpdateSuccess](state, payload) {
        state.isLoading = false
        state.legalInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.legalUpdateFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    },

    [mutationTypes.legalRemoveStart](state) {
        state.isLoading = true
        state.legalInfo = null
    },
    [mutationTypes.legalRemoveSuccess](state, payload) {
        state.isLoading = false
        state.legalInfo = payload
        state.validationErrors = null
    },
    [mutationTypes.legalRemoveFailure](state, payload) {
        state.isLoading = false
        state.validationErrors = payload
    }
}

const actions = {
    [legalActionTypes.getLegal](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getLegalStart)
            legalApi
                .getLegalList(data)
                .then(response => {
                    context.commit(mutationTypes.getLegalSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.getLegalFailure, response)
                })
        })
    },
    [legalActionTypes.legalInfo](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.legalInfoStart)
            legalApi
                .getLegalInfo(data)
                .then(response => {
                    context.commit(mutationTypes.legalInfoSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.legalInfoFailure, response)
                })
        })
    },
    [legalActionTypes.legalCreate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.legalCreateStart)
            legalApi
                .createLegalInfo(data)
                .then(response => {
                    context.commit(mutationTypes.legalCreateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.legalCreateFailure, response)
                })
        })
    },
    [legalActionTypes.legalUpdate](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.legalUpdateStart)
            legalApi
                .updateLegalInfo(data)
                .then(response => {
                    context.commit(mutationTypes.legalUpdateSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.legalUpdateFailure, response)
                })
        })
    },
    [legalActionTypes.legalRemove](context, data) {
        return new Promise(resolve => {
            context.commit(mutationTypes.legalRemoveStart)
            legalApi
                .deleteLegal(data)
                .then(response => {
                    context.commit(mutationTypes.legalRemoveSuccess, response)
                    resolve(response)
                })
                .catch(response => {
                    context.commit(mutationTypes.legalRemoveFailure, response)
                })
        })
    }
}

export default {
    state,
    actions,
    mutations
}
