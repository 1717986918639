import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import tariff from '@/store/modules/tariff'
import notice from '@/store/modules/notice'
import banner from '@/store/modules/banner'
import loan from '@/store/modules/loan'
import admin from '@/store/modules/admin'
import agreement from '@/store/modules/agreement'
import legal from '@/store/modules/legal'
import locations from '@/store/modules/locations'
import privacy from '@/store/modules/privacy'
import ranks from '@/store/modules/ranks'
import tags from '@/store/modules/tags'
import track from '@/store/modules/track'
import moderation from '@/store/modules/moderation'
import appeals from '@/store/modules/appeals'
import subjectsAppeals from '@/store/modules/subjects-appeals'
import reviewsModeration from '@/store/modules/reviews-moderation'
import reviewsApp from '@/store/modules/reviews-app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userType: null,
    email: null,
    userName: null,
    userInterface: null,
    preloader: false,
    error: null,
  },
  mutations: {
    setUserInterface: (state, data) => state.userInterface = data,
    unSetUserInterface: (state) => state.userInterface = null,
    setUserType: (state, data) => state.userType = data,
    setUserName: (state, data) => state.userName = data,
    setEmail: (state, data) => state.email = data,
    showPreloader: (state, data) => state.preloader = data,
    setError: (state, data) => state.error = data,
    clearError: (state) => state.error = null,
  },
  actions: {},
  modules: {
    auth,
    tariff,
    notice,
    banner,
    loan,
    admin,
    agreement,
    legal,
    locations,
    privacy,
    ranks,
    tags,
    track,
    moderation,
    appeals,
    subjectsAppeals,
    reviewsModeration,
    reviewsApp,
  }
})
