<template>
  <div class="orders-page">
    <Heading level="2" text="Новый тег"/>
    <Grid :column="1" :gap="10">
      <ContentBlock >
        <div class="order-info__item">

          <span>Название тега:</span>
          <div> <v-text-field v-model="newTag.tag"  required></v-text-field></div>
        </div>

        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm"
          >
            Сохранить
          </v-btn>
        </div>
      </ContentBlock>

    </Grid>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {globalMethods} from "@/mixins/globalMethods";
import tags, {tagsActionTypes} from '@/store/modules/tags';

export default {
  name: 'CreateTag',
  mixins: [globalMethods],
  computed: {
    ...mapState({
      isSubmitting: state => state.locations.isSubmitting,
      validationErrors: state => state.locations.validationErrors,
      tagInfo: state => state.tags.tagInfo
    })
  },
  data() {
    return {
      itemsBranches: [
        { id: '2', name: 'Теги' },
        { id: '3', name: 'Звания' },
        { id: '4', name: 'Юридическая информация' },
        { id: '5', name: 'Пользовательское соглашение' },
        { id: '6', name: 'Политика конфиденциальности' },
      ],
      newTag: {
        tag: ''
      }
    }
  },

  methods: {
    isFormValid() {
      return this.newTag.tag !== null && this.newTag.tag !== '';
    },
    submitForm() {
      console.log(this.newTag)
      if (this.isFormValid()) {
        this.$store.dispatch(tagsActionTypes.tagCreate, {
          tag:this.newTag.tag
        }).then(()=>{
          this.$router.push('/main/tags');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
  }
}
</script>

<style scoped lang="scss">
/* ... ваши стили ... */
</style>
