<template>
    <div class="orders-page">
      <div class="head-wrap">
        <Heading level="2" class="head" text="Треки на модерацию"/>
        <span>Количество треков на модерацию: {{ listModerationDef?.length }}</span>
      </div>
      <Grid :column="1" :gap="10">
        <div class="filters-wrapper">
          <div class="filters-select"> 
            <v-select 
              v-model="selectedTypeTrack.value"
              item-text="text" 
              item-value="value"
              :items="typesTrack" 
              label="Выберите тип трека"
              @change="makeSearch()"
            />
          </div>
          <div class="filters-select"> 
            <v-select 
              v-model="selectedStatusModeration.value"
              item-text="text" 
              item-value="value"
              :items="statusesModeration" 
              label="Выберите статус модерации"
              @change="makeSearch()"
            />
          </div>
          <div>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="event_date"
                      label="Выберите дату"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="event_date"
                    range
                    :first-day-of-week="1"
                    locale="ru-RU"
                  >
                  <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu2 = false, event_date = '', makeSearch()"
                    >
                      Отмена
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="makeSearch(), menu2 = false"
                    >
                    Ок
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          <div class="search-wrapper">
            <input 
              type="text" 
              v-model="search" 
              @keyup="makeSearch()" 
              placeholder="Поиск..." 
              class="search-input" 
            />
            <div @click="makeSearch()" class="search">
              <SearchIcon/>
            </div>
          </div>
        </div>
        <ContentBlock v-if="listModeration">
          <Table>
            <!-- Таблица заголовков -->
            <template v-slot:head>
              <tr>
                <td><input type="checkbox" class="checkbox" v-model="selectedAll" @change="toggleSelectionAll()" /></td>
                <th>
                  <div class="column-title" @click="makeSort('id')">
                    <SortDefaultIcon v-if="sortedState.id === 'default'"/>
                    <SortAscIcon v-if="sortedState.id === 'asc'"/>
                    <SortDescIcon v-if="sortedState.id === 'desc'"/>
                    <span>ID</span> 
                  </div>
                </th>
                <th>
                  <div class="column-title" @click="makeSort('author')">
                    <SortDefaultIcon v-if="sortedState.author === 'default'"/>
                    <SortAscIcon v-if="sortedState.author === 'asc'"/>
                    <SortDescIcon v-if="sortedState.author === 'desc'"/>
                    <span>Автор</span> 
                  </div>
                </th>
                <th>Статус</th>
                <th>Дата обновления</th>
                <th>
                  <div class="column-title" @click="makeSort('name')">
                    <SortDefaultIcon v-if="sortedState.name === 'default'"/>
                    <SortAscIcon v-if="sortedState.name === 'asc'"/>
                    <SortDescIcon v-if="sortedState.name === 'desc'"/>
                    <span>Название</span> 
                  </div>
                </th>
                <th>
                  <div class="column-title" @click="makeSort('track_distance')">
                    <SortDefaultIcon v-if="sortedState.track_distance === 'default'"/>
                    <SortAscIcon v-if="sortedState.track_distance === 'asc'"/>
                    <SortDescIcon v-if="sortedState.track_distance === 'desc'"/>
                    <span>км</span> 
                  </div>
                </th>
                <th>Тип</th>
                <th>Дата</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </template>
            <!-- Таблица данных -->
            <template v-slot:body>
              <!-- Изменено: Используем отдельный computed свойство для пагинации -->
              <tr v-for="(order, ind) of pagedTracks" :key="`order-${ind}`">
                <td><input type="checkbox" v-model="order.selected" @change="toggleSelection(order)"></td>
                <td>{{ order.id }}</td>
                <td>{{ order?.author }}</td>
                <td>{{ order.moderation_status | statusModeration}}</td>
                <td>{{ order.updated_at | formatDate}}</td>
                <td>{{ order.name }}</td>
                <td>{{ order.track_distance }}</td>
                <td>{{ order.track_type | translate}}</td>
                <td>{{ order.created_at | formatDate }}</td>
                <td align="right">
                  <ClipIcon v-if="order.files" />
                </td>
                <td align="right">
                <button @click="removeOne(order.id)">
                  <Icon iconName="delete" width="24" height="24" stroke="#a6e71d"/>
                  </button>
                </td>
                <td align="right">
                  <router-link :to="`/main/track-moderation/update/${order.id}`">
                    <Icon iconName="edit" width="24" height="24" stroke="#a6e71d"/>
                  </router-link>
                </td>
              </tr>
            </template>
          </Table>  
          <div class="buttons-group mt-5">
            <v-btn  :disabled="selectedTracks.length === 0" large color="#F35821" class="color-white" @click="remove">
              Удалить
            </v-btn>
          </div>
          <v-pagination
              v-model="filter.page"
              :length="totalPages"
              total-visible="7"
          ></v-pagination>
        </ContentBlock>
      </Grid>
    </div>
  </template>
  
  <script>
  import {mapState} from "vuex";
  import {globalMethods} from "@/mixins/globalMethods";
  import {trackActionTypes} from '@/store/modules/track';
  import {moderationActionTypes} from '@/store/modules/moderation';
  import {toLowerCaseTrim} from '@/helpers/utils'
  import {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon } from '@/components/icons/index'
  
  export default {
    name: 'moderation-page',
    components: {SearchIcon, SortDefaultIcon, SortAscIcon, SortDescIcon, ClipIcon },
    data() {
      return {
        selectedAll: false,
        selectedTracks: [],
        totalPageCount: 10,
        filter: {
          count: 10,
          page: 1,
        },
        menu2: false,
        event_date: '',
        search: '',
        waiteLoad: false,
        listModeration: [],
        listModerationDef:[],
        selectedTypeTrack: {text: 'Все', value: 'all'},
        typesTrack: [
          { text: 'Все', value: 'all' },
          { text: 'Бег', value: 'run' },
          { text: 'Ходьба', value: 'walk' },
          { text: 'Велосипед', value: 'bike' },
        ],
        selectedStatusModeration: {text: 'Все', value: 'all'},
        statusesModeration: [
          { text: 'Все', value: 'all' },
          { text: 'На модерации', value: 1},
          { text: 'Отклонен', value: 2 },
        ],
        sortedState: {
          id: 'desc',
          author: 'default',
          name: 'default',
          track_distance: 'default',
        }
      }
    },
    mixins: [globalMethods],
    computed: {
      ...mapState({
        isSubmitting: state => state.track.isSubmitting,
        validationErrors: state => state.track.validationErrors,
      }),
      pagedTracks() {
        const startIdx = (this.filter.page - 1) * this.filter.count;
        const endIdx = startIdx + this.filter.count;
        return this.listModeration.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.listModeration.length / this.filter.count);
      },
    },
    mounted() {
      this.loadLoans()
    },
    methods: {
      isMatchesDateFilter(moderation) {
        const dates = this.event_date && this.event_date.sort((a, b) => +new Date(a) - +new Date(b))
        if (dates.length === 2) {
            const crated_date = +new Date(moderation.created_at.split(' ')[0])
            const startDate = +new Date(dates[0])
            const endDate = +new Date(dates[1])
            return crated_date >= startDate && crated_date <= endDate
        } else if (dates.length === 1) {
            const crated_date = +new Date(moderation.created_at.split(' ')[0])
            const day = +new Date(dates[0])
            return crated_date === day
        }
        return true
      },
      makeSort(key) {
        const sortedAsNumberKey = ['id', 'track_distance']
        const sortedAsStringKey = ['name', 'author']
        Object.keys(this.sortedState).forEach(k => {
          if (key !== k) this.sortedState[k] = 'default'
        })
        if (sortedAsNumberKey.includes(key)) {
          switch(this.sortedState[key]) {
            case 'desc':
              this.listModeration.sort((a, b) => +a[key] - +b[key])
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listModeration.sort((a, b) => b.id - a.id)
              this.sortedState.id = 'desc'
              key === 'track_distance' && (this.sortedState[key] = 'default')
              break
            default:
              this.listModeration.sort((a, b) => +b[key] - +a[key])
              this.sortedState[key] = 'desc'
          }
        } else if (sortedAsStringKey.includes(key)) {
          switch(this.sortedState[key]) {
            case 'desc':
              this.listModeration.sort((a, b) => a[key] > b[key] ? 1 : -1)
              this.sortedState[key] = 'asc'
              break
            case 'asc':
              this.listModeration.sort((a, b) => b.id - a.id)
              this.sortedState.id = 'desc'
              this.sortedState[key] = 'default'
              break
            default:
              this.listModeration.sort((a, b) =>  b[key] > a[key] ? 1 : -1)
              this.sortedState[key] = 'desc'
          }
        }
      },
      makeSearch() {
        this.listModeration = this.listModerationDef.filter((item) => {
          const keys = ['author', 'name']

          if (keys.every(key => item[key] != null)) {
            const isMatchesTypeTrackFilter = (
              this.selectedTypeTrack.value === item.track_type ||
              this.selectedTypeTrack.value === 'all'
            )
            const isMatchesStatusMaoderationFilter = (
              this.selectedStatusModeration.value === item.moderation_status ||
              this.selectedStatusModeration.value === 'all'
            )
            const isMatchesSearchFilter = keys.some(key => 
              toLowerCaseTrim(item[key]).includes(toLowerCaseTrim(this.search))
            )
            return (
              isMatchesSearchFilter && isMatchesTypeTrackFilter && 
              isMatchesStatusMaoderationFilter && this.isMatchesDateFilter(item)
            )
          }
        })
      },
      toggleSelectionAll() {
        if (this.selectedAll) {
          this.pagedTracks.forEach(track => {
              this.selectedTracks.push(track)
              track.selected = true
          })
        } else {
          this.pagedTracks.forEach(track => {
              this.selectedTracks = []
              track.selected = false
          })
        }
      },
      toggleSelection(track) {
        if (track.selected) {
          this.selectedTracks.push(track);
        } else {
          const index = this.selectedTracks.findIndex(selectedTrack => selectedTrack.id === track.id);
          if (index !== -1) {
            this.selectedTracks.splice(index, 1);
          }
        }
      },
      loadLoans() {
        this.$set(this, "waiteLoad", true);
        this.$store.dispatch(moderationActionTypes.getModeration).then(res => {
          this.listModeration = res.track
          this.listModerationDef = res.track
          if(this.$route.query['search']) { 
            this.checkSearchParams()
            this.makeSearch()
          }
        })
      },
      remove() {
        if (confirm('Вы уверены, что хотите удалить выбранные треки?')) {
          const selectedIds = this.selectedTracks.map(track => track.id);
          this.$store.dispatch(trackActionTypes.trackRemove, { id: selectedIds }).then(() => {
            this.selectedTracks = []; // Очистить выбранные записи
            this.loadLoans();
          });
        }
      },
      removeOne(id) {
      if (confirm('Вы уверены, что хотите удалить выбранные трек?')) {
        this.$store.dispatch(trackActionTypes.trackRemove, {id}).then(this.loadLoans)
      }
    },
      checkSearchParams() {
        const searchQuery = this.$route.query
        const paramsKeys = ['search', 'date', 'type', 'status']
        if (paramsKeys.some(key => searchQuery[key])) {
          const findedStatus = this.statusesModeration.find(sm => sm.value == searchQuery.status)
          const findedType = this.typesTrack.find(typeTrack => typeTrack.value === searchQuery.type)
          this.search = decodeURIComponent(searchQuery.search)
          searchQuery.date && (this.event_date = searchQuery.date.split(','))    
          findedStatus && (this.selectedStatusModeration = {...this.selectedStatusModeration, ...findedStatus}) 
          findedType && (this.selectedTypeTrack = {...this.selectedTypeTrack, ...findedType}) 
        }
      },
      setRouteParams() {
        const search = `search=${this.search}`
        const date = `date=${this.event_date}`
        const type = `type=${this.selectedTypeTrack.value}`
        const status = `status=${this.selectedStatusModeration.value}`
        this.$router.push({path: `?${search}&${date}&${type}&${status}`})
      }
    },
    watch: {
      event_date () {
        this.setRouteParams()
      },
      search() {
        this.setRouteParams()
      },
      selectedTypeTrack: {
        handler () {
          this.setRouteParams()
        },
        deep: true,
      },
      selectedStatusModeration: {
        handler () {
          this.setRouteParams()
        },
        deep: true,
      }
    },
  }
  </script>
  
  <style scoped lang="scss">
  .checkbox {
    margin-left: 5px;
  }
  .filters-wrapper {
    display: flex; 
    align-items: center; 
    justify-self: end; 
    gap: 20px;
  }
  .filters-select {
    width: 200px;
  }
  .head-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
  }
  .head {
    width: 400px!important;
  }

  .search-wrapper {
    display:flex; 
    justify-self: end;
  }

  .search-input {
    border-radius: 2px;
    height: 32px; 
    width: 200px; 
    padding: 10px;
    border: 1px solid #dfdfdf;
  }

  .search {
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 32px; 
    width: 37px; 
    background-color: #F32719;
  }

  .column-title {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;

    & svg {
      cursor: pointer;
    }
  }
  </style>
  