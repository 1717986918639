import axios from '@/api/axios'

const getReviewsModeration = (data) => {
    return axios
        .post('/admin/moderation/reviews', data)
        .then(response => response.data)
}

const updateReviewsModeration = (data) => {
    return axios
        .post('/admin/moderation/update-reviews', data)
        .then(response => response.data)
}

const removeReviewsModeration = (data) => {
    return axios
        .post('/admin/moderation/remove-reviews', data)
        .then(response => response.data)
}



export default {
    getReviewsModeration,
    updateReviewsModeration,
    removeReviewsModeration
}