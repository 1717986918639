<template>
    <div class="default-template">
        <Header />
        <Sidebar />
        <section class="page">
            <slot />
        </section>
        <Preloader />
        <Error />
    </div>
</template>

<script>
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Preloader from "../components/Preloader";
import Error from "../components/Errors";
export default {
    name: "default",
    components: { Header, Sidebar, Preloader, Error },
}
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    color: $color-orange;
}

* {
    outline: none;
}

body,
html {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    position: relative;
    font-size: 15px;
    color: $color-black;
}

.default-template {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 50px 1fr;
    height: 100vh;

    .page {
        padding: 10px;

        button.ghost {
            height: 25px;
            width: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .color-white {
            color: $color-white !important;
        }

      .color-red {
        color: $color-red !important;
      }
    }
}
</style>