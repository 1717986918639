<template>
  <div class="orders-page">
    <Heading level="2" :text="`Тег #${$route.params.id}`" :backButton="true"/>
    <Grid :column="1" :gap="10">
      <ContentBlock v-if="subjectAppealInfo">

          <div class="order-info__item">
            <span>Название тега:</span>
            <div> <v-text-field v-model="subjectAppealInfo.complaints.subject"  required></v-text-field></div>
          </div>
        
        <div class="buttons-group mt-5">
          <v-btn large color="#F35821" class="color-white" @click="submitForm">
            Сохранить
          </v-btn>
        </div>
      </ContentBlock>
    </Grid>
  </div>
</template>

<script>
import {globalMethods} from "@/mixins/globalMethods";
import {json} from "body-parser";
import {subjectsAppealsActionTypes} from '@/store/modules/subjects-appeals';
import {mapState} from "vuex";

export default {
  name: 'UpdateTag',
  mixins: [globalMethods],
  data() {
    return {
      orderData: {},
      statusList: {
        '0': 'В обработкe',
        '1': 'Займ выдан',
        '2': 'Отказ',
        '3': 'Архив',
      },
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.loan.isSubmitting,
      validationErrors: state => state.loan.validationErrors,
      subjectAppealInfo: state => state.subjectsAppeals.subjectAppealInfo
    })
  },
  mounted() {
    this.loadOrder()
  },
  watch: {
    // privacyInfo() {
    //   console.log(this.privacyInfo);
    // }
  },
  methods: {
    loadOrder() {
      this.$store.dispatch(subjectsAppealsActionTypes.getSubjectAppealInfo, {
        id: this.$route.params.id
      }).then(() => console.log('this.subjectAppealInfo: ', this.subjectAppealInfo))
    },
    submitForm() {
      if (this.isFormValid()) {
        console.log('this.subjectAppealInfo: ', this.subjectAppealInfo)
        this.$store.dispatch(subjectsAppealsActionTypes.subjectAppealUpdate, {
          id:this.subjectAppealInfo.complaints.id,
          subject:this.subjectAppealInfo.complaints.subject
        }).then(()=>{
          this.$router.push('/main/subjects-appeals');
        });
      }else {
        alert("Не все поля заполнены")
      }

    },
    isFormValid() {
        if (this.subjectAppealInfo.complaints.subject === null || this.subjectAppealInfo.complaints.subject === '') {
          return false;
        }
      return true;
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.order-page {
  .order-info {
    padding-bottom: 15px;

    &__item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        padding-bottom: 3px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }

      span {
        font-weight: bold;
        width: 20%;

      }

      &.column {
        flex-direction: column;

        span {
          width: 100%;
        }
      }
    }
  }
}
</style>