<template>
    <section class="grid-section" :style="{
        'gridTemplateColumns': `repeat(${column}, 1fr)`,
        'gap': gap + 'px'
    }">
        <slot />
    </section>
</template>
<script>
export default {
    name: "grid",
    props: {
        column: {
            typeof: Number,
            default: 1
        },
        gap: {
            typeof: Number,
            default: 1
        },
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.grid-section {
    display: grid;

}
</style>