import axios from '@/api/axios'

const getLoans = (data) => {
    return axios
        .post('/admin/loans', data)
        .then(response => response.data)
}

const loanInfo = (data) => {
    return axios
        .post('/admin/loans/get-info', data)
        .then(response => response.data)
}

export default {
    getLoans,
    loanInfo
}
