import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login"
import Main from '@/views/Main'
import Admins from '@/views/Admins'
import Directory from '@/views/Directory'
import CreateDirectory from '@/views/Directory/create'
import Page404 from '@/views/Page404'
import Notices from '@/views/Notices'
import Tags from "@/views/Tags";
import SubjectsAppeals from "@/views/SubjectsAppeals";
import Ranks from "@/views/Ranks";
import Privacy from "@/views/Privacy";
import Agreement from "@/views/Agreement";
import Legal from "@/views/Legal";
import Track from "@/views/Track";
import TrackReviews from "@/views/TrackReviews";
import TrackCreate from "@/views/Track/create";
import TrackUpdate from "@/views/Track/update";
import TrackModeration from "@/views/TrackModeration";
import TrackModerationUpdate from "@/views/TrackModeration/update";
import ReviewsModeration from "@/views/ReviewsModeration";
import ReviewsModerationUpdate from "@/views/ReviewsModeration/update";
import ReviewsApp from "@/views/ReviewsApp";
import Appeals from "@/views/Appeals";
import AppealsUpdate from "@/views/Appeals/update";
import CreateTag from '@/views/Tags/create'
import UpdateTag from '@/views/Tags/update'
import CreateSubjectAppeal from '@/views/SubjectsAppeals/create'
import UpdateSubjectAppeal from '@/views/SubjectsAppeals/update'
import CreateAgreement from '@/views/Agreement/create'
import UpdateAgreement from '@/views/Agreement/update'
import CreateLegal from '@/views/Legal/create'
import UpdateLegal from '@/views/Legal/update'
import CreatePrivacy from '@/views/Privacy/create'
import UpdatePrivacy from '@/views/Privacy/update'
import CreateRank from '@/views/Ranks/create'
import UpdateRank from '@/views/Ranks/update'
import Clients from '@/views/Clients'
import UpdateDirectory from '@/views/Directory/update'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { layout: "login-layout" }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { logout: true }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/admins',
    name: 'Admins',
    component: Admins,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/clients',
    name: 'Clients',
    component: Clients,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/directory',
    name: 'Directory',
    component: Directory,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/directory/create',
    name: 'CreateDirectory',
    component: CreateDirectory,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/directory/update/:id',
    name: 'UpdateDirectory',
    component: UpdateDirectory,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/tags',
    name: 'Tags',
    component: Tags,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/tags/create',
    name: 'CreateTag',
    component: CreateTag,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/tags/update/:id',
    name: 'UpdateTag',
    component: UpdateTag,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/subjects-appeals',
    name: 'SubjectsAppeals',
    component: SubjectsAppeals,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/subjects-appeals/create',
    name: 'CreateSubjectAppeal',
    component: CreateSubjectAppeal,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/subjects-appeals/update/:id',
    name: 'UpdateSubjectAppeal',
    component: UpdateSubjectAppeal,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/agreement/create',
    name: 'CreateAgreement',
    component: CreateAgreement,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/agreement/update/:id',
    name: 'UpdateAgreement',
    component: UpdateAgreement,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/ranks',
    name: 'Ranks',
    component: Ranks,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/ranks/create',
    name: 'CreateRank',
    component: CreateRank,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/ranks/update/:id',
    name: 'UpdateRank',
    component: UpdateRank,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/privacy/create',
    name: 'CreatePrivacy',
    component: CreatePrivacy,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/privacy/update/:id',
    name: 'UpdatePrivacy',
    component: UpdatePrivacy,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/agreements',
    name: 'Agreements',
    component: Agreement,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/legal',
    name: 'Legal',
    component: Legal,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/legal/create',
    name: 'CreateLegal',
    component: CreateLegal,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/legal/update/:id',
    name: 'UpdateLegal',
    component: UpdateLegal,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track',
    name: 'Track',
    component: Track,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track/create',
    name: 'TrackCreate',
    component: TrackCreate,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track/update/:id',
    name: 'TrackUpdate',
    component: TrackUpdate,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track-moderation',
    name: 'TrackModeration',
    component: TrackModeration,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track-moderation/update/:id',
    name: 'TrackModerationUpdate',
    component: TrackModerationUpdate,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/reviews-moderation',
    name: 'ReviewsModeration',
    component: ReviewsModeration,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/reviews-moderation/update/:id',
    name: 'ReviewsModerationUpdate',
    component: ReviewsModerationUpdate,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/track-reviews/:id',
    name: 'TrackReviews',
    component: TrackReviews,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/appeals',
    name: 'Appeals',
    component: Appeals,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/reviews-app',
    name: 'ReviewsApp',
    component: ReviewsApp,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/appeals/update/:id',
    name: 'AppealsUpdate',
    component: AppealsUpdate,
    meta: { requiresAuth: true, layout: "default-layout" }
  },
  {
    path: '/main/notices',
    name: 'Notices',
    component: Notices,
    meta: { requiresAuth: true, layout: "default-layout" }
  },

  {
    path: '*',
    name: 'Page404',
    component: Page404,
    meta: { requiresAuth: true, layout: "default-layout" }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
